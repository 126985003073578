.banner_box_w{display:inline-block; width: 800px; vertical-align: top;}
.banner_box_w .swiper { margin-left: auto; margin-right: auto; position: relative; overflow: hidden; list-style: none; padding: 0; z-index: 1; }
.banner_box_w .mainSwiper_w{padding-bottom: 48px;}
.banner_box_w .mainSwiper_w.wide .swiper_slide{width: 800px !important; }
.banner_box_w .swiper_slide{width: 390px; height: 350px; display: inline-block; border-radius: 16px; overflow: hidden;}
.banner_box_w .swiper_slide > img{width: 100%;}
.banner_box_w .swiper_btn_wrap{position: absolute; bottom: 0; width: 100%; height: 20px; z-index: 99; text-align: center; font-size: 13px;} 
.banner_box_w .swiper_btn{position: relative; height: 20px; display: inline-block;}
.banner_box_w .swiper_btn .prev{background: url(../../../../public/assets/images/icon_prev.svg) center no-repeat; width: 20px; height: 20px; margin-left: 9px; display: inline-block; position: relative; vertical-align: middle;  }
.banner_box_w .swiper_btn .next{background: url(../../../../public/assets/images/icon_next.svg) center no-repeat; width: 20px; height: 20px; margin-right: 9px; display: inline-block; position: relative; vertical-align: middle;}
.banner_box_w .swiper_btn_wrap .start_stop{width:20px; height:20px; display: inline-block; margin-left: 10px; vertical-align: middle;}
.banner_box_w .swiper_btn_wrap .start_stop > button{width:20px; height:20px; vertical-align: middle; border: 0; padding: 0;}
.banner_box_w .swiper_btn_wrap .start_stop > button.start{background: url(../../../../public/assets/images/icon_play.svg) center no-repeat;}
.banner_box_w .swiper_btn_wrap .start_stop > button.stop{background: url(../../../../public/assets/images/icon_stop.svg) center no-repeat;}
.banner_box_w .swiper_btn_wrap .start_stop > button.on {display: block;}
.banner_box_w .swiper_btn_wrap .start_stop > button.off {display: none;}
.banner_box_w .swiper-pagination { position: absolute; text-align: center; transition: .3s opacity; transform: translate3d(0,0,0); z-index: 10; }
.banner_box_w .swiper_pagination_fraction{bottom: auto; font-weight: 500; font-size: 13px; color: #999999; width: auto; display: inline-block; position: relative; vertical-align: middle;}
.banner_box_w .swiper_pagination_current{ color: #000;  font-weight: 500; display: inline-block; min-width: 8px;}
.banner_box_w .swiper_pagination_total{color: #999;  font-weight: 500;}