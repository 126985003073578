
/* 레이아웃 */
.wrapper{width: 100%;}
.wrapper.off{height: 100vh; overflow: hidden;}
.container {position: relative; width:100%; min-height: calc(100vh - 315px); margin: 0 auto; background-color: #fff; padding-top: 140px;}
.inbanner{padding-top: 190px;}
.linebanner{padding-top: 240px;}
.allbanner{padding-top: 290px !important;}
.sub{background: #f8f8f8;}
.w_inner{width: 1200px; margin: 0 auto; text-align: center;}

