.gnb{width: 100%; margin-top: 22px;}
.gnb:after {display:block; content:"";clear:both;}
.gnb .gnb_wrap{width:100%; padding-top: 16px;}
.gnb .gnb_wrap:after {display:block; content:"";clear:both;}
.gnb .gnb_wrap>ul{width:100%; text-align: right; font-size: 0;}
.gnb .gnb_wrap>ul:after {display:block; content:"";clear:both;}
.gnb .gnb_wrap>ul>li{text-align:left; margin-left: 48px; font-size: 20px; display: inline-block;}
.gnb .gnb_wrap>ul>li:first-child{margin-left: 0;}
.gnb .gnb_wrap>ul>li a.dep01{color:#000; font-size:20px; font-weight:500; }
.gnb .gnb_wrap>ul>li.slc a.dep01{color:#febd17;}
.gnb .gnb_wrap>ul>li.ov a.dep01{color:#000;}
.gnb .gnb_wrap>ul>li.on a.dep01{color:#f93964;}
.gnb .gnb_wrap>ul>li.active a.dep01{color:#f93964;}
.gnb .gnb_wrap>ul>li a > i{padding: 0 3px; color: inherit;}
.gnb .gnb_wrap>ul>li div.dep02{background:#fff;width:100vw; border-top: 1px solid #e5e5e5; position: absolute; left: 0; margin-top: 25px;  z-index: 101; color:#fff; line-height:1.5; text-align:left;display:none; box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);} /*20230222 수정 */
.gnb .gnb_wrap>ul>li div.dep02 .sub_nav{width:1200px;margin:0 auto; box-sizing: border-box; height: 327px;}
.gnb .gnb_wrap>ul>li div.dep02 .sub_nav .sub_inner{padding: 60px 0 15px;}