/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe, input, button, h1, h2, h3, h4, h5, h6, p, 
blockquote, pre, a, abbr, acronym, address, big, cite, code,del, dfn, em, img, ins, 
kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center,dl, dt, dd, ol, ul, li, 
fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, 
canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, 
section, summary, time, mark, audio, video 
{ margin: 0; padding: 0; border: 0;  text-decoration: none; color: #000; font: inherit; font-family: 'AppleSDGothicNeo', 'sans-serif'; line-height: normal; font-weight: 500; }

/* make sure to set some focus styles for accessibility */
:focus { outline: 0;}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {display: block;}


ol, ul {list-style: none;}
blockquote, q {	quotes: none;}
blockquote:before, blockquote:after,q:before, q:after {	content: '';content: none;}
table {	border-collapse: collapse;	border-spacing: 0;}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {-webkit-appearance: none; -moz-appearance: none;}

input[type=search] {
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: content-box;
}

textarea {overflow: auto; vertical-align: top; resize: vertical;}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio, canvas, video {display: inline-block; *display: inline; *zoom: 1; max-width: 100%;}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {display: none; height: 0;}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {display: none;}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {font-size: 100%; /* 1 */-webkit-text-size-adjust: 100%; /* 2 */ -ms-text-size-adjust: 100%; /* 2 */}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {outline: thin dotted;}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,a:hover {outline: 0;}
img { vertical-align: top; border: 0; /* 1 */ -ms-interpolation-mode: bicubic; /* 2 */}
figure {margin: 0;}
form {margin: 0;}
fieldset {border: 1px solid #c0c0c0;margin: 0 2px;padding: 0.35em 0.625em 0.75em;}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend { border: 0; /* 1 */ padding: 0; white-space: normal; /* 2 */ *margin-left: -7px; /* 3 */}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button, input, select, textarea { font-size: 100%; /* 1 */ margin: 0; /* 2 */ *vertical-align: middle; /* 3 */ font-family: 'AppleSDGothicNeo', 'sans-serif';}

button, input {line-height: normal;}
button, select {text-transform: none;}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button, html input[type="button"], /* 1 */ input[type="reset"], input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
    *overflow: visible;  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button{border:none;  background: none;}
button[disabled],
html input[disabled] {
    cursor: default;
}

input[type="checkbox"],
input[type="radio"] {cursor: pointer;
   position: absolute;  left: 0;  right: 0; top: 0; bottom: 0; z-index: 10; opacity: 0;  width: 100%; height: 100%; letter-spacing: 0;border: none; vertical-align: middle; background: none; -webkit-appearance: none; -ms-appearance: none; 
}
input[type="checkbox"] + label, input[type="radio"] + label{ display: inline-block; position: relative; } 


/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
    -webkit-appearance: textfield; /* 1 */ /* 2 */
    box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="text"] {width: 100%; height: 40px; padding:5px 0;}
input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration { -webkit-appearance: none;}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner, input::-moz-focus-inner { border: 0; padding: 0;}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea { overflow: auto; /* 1 */ vertical-align: top; /* 2 */}

/**
 * Remove most spacing between table cells.
 */

table {border-collapse: collapse;border-spacing: 0;}

html, button, input, select, textarea {color: #000;}


::-moz-selection {background: #b3d4fc;text-shadow: none;}

::selection {background: #b3d4fc;text-shadow: none;}


fieldset {border: 0;margin: 0;padding: 0;}
textarea {resize: vertical;}
.chromeframe {margin: 0.2em 0;background: #ccc;color: #000;padding: 0.2em 0;}

.new-line {
    white-space: pre-line;
  }

  /* IE의 경우 */
input::-ms-clear,
input::-ms-reveal{
    display:none;
}
/* 크롬의 경우 */
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration{
    display:none;
}
* { box-sizing:border-box; -webkit-box-sizing:border-box;-moz-box-sizing:border-box;}
html, body  {margin:0; padding: 0; width: 100%;  height: 100%; }

body{font-family: 'AppleSDGothicNeo', 'sans-serif'; color: #000; font-weight: 500; font-size: 14px;
  -webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale; line-height: normal; }
body.off{height: 100vh; overflow: hidden;}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
a{outline: none !important;}
input[type="password"]::-ms-reveal{
  display: none;
}

input.password {
  font-family: 'pass', 'AppleSDGothicNeo', Helvetica, Arial, sans-serif ;
  font-size: 18px;
}  
input.password::-webkit-input-placeholder {
    transform: scale(1);
    transform-origin: 0 100%;
}
input.password::-moz-placeholder {
    font-size: 14px;
    opacity: 1;
}
input.password:-ms-input-placeholder {
    font-size: 14px;
    font-family: 'AppleSDGothicNeo', Helvetica, Arial, sans-serif;
}

/* button:disabled{background: #e5e5e5 !important; color: #c8c8c8 !important;} */
.mgt20{margin-top: 20px !important;}
.mgt00{margin-top: 0 !important;}
.mgl00{margin-left: 0 !important;}
.pdt00{padding-top: 0 !important;}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;  /* 말줄임 적용 */
}

/* 레이아웃 */
#wrapper{width: 100%;}
#container {position: relative; width:100%; min-height: calc(100vh - 315px); margin: 0 auto; background-color: #fff; padding-top: 140px;}
#container.inbanner{padding-top: 190px;}
#container.sub{background: #f8f8f8;}
.w_inner{width: 1200px; margin: 0 auto; text-align: center;}

/* textarea 스크롤바 커스텀 */
textarea::-webkit-scrollbar {width: 4px; padding-right: 3px;}
textarea::-webkit-scrollbar-track {background-color: #fff; border-radius: 100px;}
textarea::-webkit-scrollbar-thumb {background-color: #c8c8c8; border-radius: 100px;}
textarea {scrollbar-color: #c8c8c8 #fff;}
textarea::-webkit-scrollbar-thumb {background-color: #c8c8c8; border-radius: 100px;}



/*header*/
#header{width: 100%; position: fixed; background: #fff; z-index: 100; border-bottom: 1px solid #e5e5e5; box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);}
#header .header_top {width: 100%; height: 140px; background: #fff;}
#header .header_top .h_inner{width: 1280px; margin: 0 auto; font-size: 0; height: auto; padding: 12px 0 0px; }
#header .header_top .h_inner > h1{display: inline-block; width: 386px; vertical-align: bottom; padding-bottom: 31px; padding-top: 48px; position: absolute;} /* 20230222 수정 */
#header .header_top .h_inner > h1 > a{display: inline-block; width: 189px;}
#header .header_top .h_inner > h1 > a > img{width: 100%;}
#header .header_top .h_inner .right_ar{ display: inline-block; width: 100%; padding-left: 386px; text-align: right; vertical-align: bottom;}
#header .header_top .h_inner .right_ar .top_menu{width: 100%; font-size: 0; position: relative;}
#header .header_top .h_inner .right_ar .top_menu .my_cash{display: inline-block; height: 36px; padding: 6px; background: #f8f8f8; border-radius: 18px; vertical-align: middle;}
#header .header_top .h_inner .right_ar .top_menu .my_cash > h5{height: 24px; display: inline-block; padding: 3px; width: 52px; vertical-align: top;}
#header .header_top .h_inner .right_ar .top_menu .my_cash > h5 > i{display: inline-block; vertical-align: top; height: 18px; width: 100%; background: #000;  color: #fff; font-size: 11px; line-height: 19px; text-align: center; border-radius: 9px;}
#header .header_top .h_inner .right_ar .top_menu .my_cash > span{display: inline-block; vertical-align: top; margin-left: 5px; font-size: 14px; color: #000; line-height: 24px;}
#header .header_top .h_inner .right_ar .top_menu .my_cash .lock_toggle{display: inline-block; vertical-align: top; margin-left: 24px; width: 78px; height: 24px; position: relative;}
.mypage_link{width: 130px; border: 1px solid #e5e5e5; padding: 20px 0; position:absolute; background: #fff;  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.1); right: 65px; border-radius: 8px; display: none; z-index: 9990;}
.mypage_link > ul{width: 100%;}
.mypage_link > ul > li{width: 100%; padding: 0 24px; margin-top: 12px; text-align: left;}
.mypage_link > ul > li:first-child{margin-top: 0;}
.mypage_link > ul > li > a{font-size: 14px; line-height: 17px;}


/* 토글스위치 */
.checkbox-switch {cursor: pointer; display: inline-block; overflow: hidden; position: relative; text-align: left; width: 100%; height: 24px; border-radius: 12px; font-size: 14px; vertical-align: top;}
.checkbox-switch input.input-checkbox {position: absolute; left: 0; top: 0; width: 100%; height: 24px; padding: 0; margin: 0; opacity: 0; z-index: 2; cursor: pointer;}
.checkbox-switch .checkbox-animate {position: relative; width: 100%; height: 24px; background-color: #95a5a6; transition: background 0.25s ease-out 0s;}
.checkbox-switch .checkbox-animate:before {content: ""; display: block; position: absolute; width: 20px; height: 20px; border-radius: 10px; -webkit-border-radius: 10px; background-color: #fff; top: 2px; left: 2px; transition: left 0.3s ease-out 0s; z-index: 10;}
.checkbox-switch input.input-checkbox:checked + .checkbox-animate {background: linear-gradient(90deg, rgba(255,168,87,1) 0%, rgba(233,85,118,1) 100%)#2ecc71;}
.checkbox-switch input.input-checkbox:checked + .checkbox-animate:before {left: auto; right: 2px;background: #fff;}
.checkbox-switch .checkbox-off, .checkbox-switch .checkbox-on { float: left; color: #fff; font-weight: 500; padding-top: 5px; transition: all 0.3s ease-out 0s; font-size: 12px; width: 100%;}
.checkbox-switch .checkbox-off {padding-left: 22px; padding-right: 4px; display: block; opacity: 1; text-align: center;}
.checkbox-switch .checkbox-on {display: none; padding-left: 4px; padding-right: 22px; text-align: center; opacity: 0;}
.checkbox-switch input.input-checkbox:checked + .checkbox-animate .checkbox-off {display: none; opacity: 0;}
.checkbox-switch input.input-checkbox:checked + .checkbox-animate .checkbox-on {display: block; opacity: 1;}

#header .header_top .h_inner .right_ar .top_menu > a{display: inline-block; vertical-align: middle; margin-left: 24px; font-size: 14px; color: #666;}
#header .header_top .h_inner .right_ar .top_menu > a.corp_mall{color: #000;}

/* gnb */
#gnb{width: 100%; margin-top: 22px;}
#gnb:after {display:block; content:"";clear:both;}
#gnb .gnb_wrap{width:100%; padding-top: 16px;}
#gnb .gnb_wrap:after {display:block; content:"";clear:both;}
#gnb .gnb_wrap>ul{width:100%; text-align: right; font-size: 0;}
#gnb .gnb_wrap>ul:after {display:block; content:"";clear:both;}
#gnb .gnb_wrap>ul>li{text-align:left; margin-left: 48px; font-size: 20px; display: inline-block;}
#gnb .gnb_wrap>ul>li a.dep01{color:#000; font-size:20px; font-weight:500; }
#gnb .gnb_wrap>ul>li.slc a.dep01{color:#febd17;}
#gnb .gnb_wrap>ul>li.ov a.dep01{color:#000;}
#gnb .gnb_wrap>ul>li.on a.dep01{color:#f93964;}
#gnb .gnb_wrap>ul>li.active a.dep01{color:#f93964;}
#gnb .gnb_wrap>ul>li div.dep02{background:#fff;width:100vw; border-top: 1px solid #e5e5e5; position: absolute; left: 0; margin-top: 25px;  z-index: 101; color:#fff; line-height:1.5; text-align:left;display:none; box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);} /*20230222 수정 */
#gnb .gnb_wrap>ul>li div.dep02 .sub_nav{width:1280px;margin:0 auto; box-sizing: border-box;}
#gnb .gnb_wrap>ul>li div.dep02 .sub_nav .sub_inner{padding: 60px 0 15px;}

/* sub gnb [캐시충전] */
.cash_charge{width: 100%; font-size: 0; padding-bottom: 45px;}
.cash_charge .link{display: inline-block; width: calc(100% - 794px); vertical-align: top;}
.cash_charge .link > ul{font-size: 0;}
.cash_charge .link > ul > li{margin-left: 50px; display: inline-block; vertical-align: top;}
.cash_charge .link > ul > li:first-child{margin-left: 0;}
.cash_charge .link > ul > li > h5{margin-top: 20px; font-size: 15px; color: #000; font-weight: 700; height: 19px; line-height: 19px;}
.cash_charge .link > ul > li > h5:first-child{margin-top: 0;}
.cash_charge .link > ul > li > h5 > a{font-size: 15px; line-height: 19px; font-weight: 700;}
.cash_charge .link > ul > li > a{display: inline-block; height: 19px; margin-top: 10px; line-height: 19px;}
.cash_charge .link > ul > li > a.left{width: 145px;}
.cash_charge .link > ul > li > a > span{font-size: 15px; line-height: 19px; display: inline-block; vertical-align: middle; color: #666;}
.cash_charge .link > ul > li > a > i{line-height: 16px; height: 16px; padding: 0 4px; border: 1px solid #f93964; color: #f93964 !important; border-radius: 2px; text-align: center; font-size: 11px; font-weight: 600; margin-left: 5px; vertical-align: middle;}
.cash_charge .p_sl{display: inline-block; width: 794px; background: #f8f8f8; border-radius: 16px; padding: 26px 28px 30px; text-align: center; vertical-align: top;}
.cash_charge .p_sl > h5{font-size: 15px; font-weight: 700; line-height: 19px; color: #000;}
.cash_charge .p_sl .charge_select{width: 100%; font-size: 0; padding-top: 16px; text-align: center;}
.cash_charge .p_sl .charge_select > a{display: inline-block; width: 134px; background: #fff; border: 1px solid #e8e8e8; border-radius: 8px; overflow: hidden; margin-left: 17px;}
.cash_charge .p_sl .charge_select > a > h5{height: 30px; width: 100%; background: #e8e8e8; line-height: 30px; font-size: 13px; font-weight: 700; color: #000;}
.cash_charge .p_sl .charge_select > a .bar_img{padding: 7px 11px 14px; width: 100%;}
.cash_charge .p_sl .charge_select > a .bar_img > img{width: 100%;}
.cash_charge .p_sl .charge_select > a:first-child{margin-left: 0;}
.cash_charge .p_sl .charge_select > a:first-child .bar_img{width: 100%; padding: 0;}

/* sub gnb [캐시교환] */
.cash_exchange{width: 100%; padding-bottom: 45px;}
.cash_exchange > ul{width: 100%; font-size: 0; }
.cash_exchange > ul > li{width: 190px; display: inline-block; margin-left: 101px; vertical-align: top;}
.cash_exchange > ul > li > h4{font-size: 15px; line-height: 19px; font-weight: 700; padding-bottom: 10px; border-bottom: 1px solid #000;}
.cash_exchange > ul > li > h4 > i{font-size: 15px; color: #f93964;  font-weight: 700;}
.cash_exchange > ul > li > ul{width: 100%; text-align: left; padding-top: 27px;}
.cash_exchange > ul > li > ul > li{width: 100; margin-top: 20px;}
.cash_exchange > ul > li > ul > li:first-child{margin-top: 0;}
.cash_exchange > ul > li > ul > li > h5{font-size: 15px; font-weight: 700;}
.cash_exchange > ul > li > ul > li .sub_link{width: 100%; margin-top: 10px; font-size: 0;}
.cash_exchange > ul > li > ul > li .sub_link > a{font-size: 15px; color: #666;}
.cash_exchange > ul > li > ul > li > h5 > a{font-size: 15px; font-weight: 700;}

.cash_exchange > ul > li.cash_pin{width: 386px; margin-left: 0;}
.cash_exchange > ul > li.cash_pin > ul{width: 100%; padding: 27px 40px 0 40px; text-align: right; font-size: 0;}
.cash_exchange > ul > li.cash_pin > ul > li{display: inline-block; vertical-align: top;}

.cash_exchange > ul > li.cash_pin > ul > li:nth-child(2n){padding-left: 54px;}
.cash_exchange > ul > li.cash_pin > ul > li:nth-child(2){margin-top: 0px;}
.cash_exchange > ul > li.cash_pin > ul > li:nth-child(3){margin-top: 20px;}
.cash_exchange > ul > li.cash_pin > ul > li:nth-child(4){margin-top: 20px;}
.cash_exchange > ul > li.cash_pin > ul > li > a{display: inline-block; padding-left: 72px; font-size: 15px; font-weight: 700; color: #000; text-align: right; line-height: 24px;}
.cash_exchange > ul > li.cash_pin > ul > li > a.inumber{background: url(/_next/static/media/logo-inumber@2x.579dba6b.png) left center no-repeat; background-size: 57px;}
.cash_exchange > ul > li.cash_pin > ul > li > a.kakao{background: url(/_next/static/media/logo-kakako@2x.b133f645.png) left center no-repeat; background-size: 61px;}
.cash_exchange > ul > li.cash_pin > ul > li > a.gifticon{background: url(/_next/static/media/logo-gifticon@2x.fdf28c50.png) 5px center no-repeat; background-size: 46px;}
.cash_exchange > ul > li.cash_pin > ul > li > a.giftishow{background: url(/_next/static/media/logo-giftishow@2x.0d82bfa3.png) 4px center no-repeat;background-size: 54.4px;}

/* sub gnb [상품권.캐시사용] */
.cash_use{width: 100%; font-size: 0; padding-bottom: 45px;}
.cash_use .left_sh{display: inline-block; width: 730px; vertical-align: top;}
.cash_use .left_sh .sh_ar{width: 100%; font-size: 0; border-bottom: 1px solid #000;}
.cash_use .left_sh .sh_ar > h4{display: inline-block; height: 29px; font-size: 15px; font-weight: 700; color: #000; vertical-align: middle; width: 69px;}
.cash_use .left_sh .sh_ar .sh_box{display: inline-block; width: calc(100% - 107px); margin-left: 38px; height: 29px; vertical-align: top; }
.cash_use .left_sh .sh_ar .sh_box > a{width: 100%; display: block;  padding-left: 26px; background: url(/_next/static/media/search.f40e3ee1.svg) left top no-repeat; height: 18px; line-height: 20px; font-size: 14px;  }
.cash_use .left_sh .sl_ar{width: 100%; padding-top: 33px; font-size: 0;}
.cash_use .left_sh .sl_ar > h4{font-size: 15px; color: #666; line-height: 19px; padding-bottom: 10px;}
.cash_use .left_sh .sl_ar > a{width: 166px; height: 116px; margin-left: 22px; display: inline-block; position: relative; }
.cash_use .left_sh .sl_ar > a.cash{background: url(/_next/static/media/cash@2x.d31f7d42.png) no-repeat; margin-left: 0; background-size: 100%;}
.cash_use .left_sh .sl_ar > a.paper{background: url(/_next/static/media/paper@2x.1b85a325.png) no-repeat; background-size: 100%;}
.cash_use .left_sh .sl_ar > a.online{background: url(/_next/static/media/online@2x.b2954c5c.png) no-repeat; background-size: 100%;}
.cash_use .left_sh .sl_ar > a.mobile{background: url(/_next/static/media/mobile@2x.27cff44c.png) no-repeat; background-size: 100%;}
.cash_use .left_sh .sl_ar > a > span{position: absolute; bottom: 10px; left: 16px; line-height: 1.1; font-size: 13px; color: #333;}
.cash_use .left_sh .sl_ar > a > span > b{font-size: 15px; font-weight: 700;}

.cash_use .risht_link{display: inline-block; width: calc(100% - 730px); vertical-align: top; padding-left: 150px;}
.cash_use .risht_link > ul{width: 100%; border: 1px solid #e8e8e8; border-radius: 16px; overflow: hidden;}
.cash_use .risht_link > ul > li{width: 100%; border-top: 1px solid #e8e8e8; }
.cash_use .risht_link > ul > li:first-child{border-top: 0;}
.cash_use .risht_link > ul > li > a{display: table; width: 100%; height: 68px; background: url(/_next/static/media/icon_more.379bec9c.svg) calc(100% - 30px) center no-repeat; }
.cash_use .risht_link > ul > li > a > p{display: table-cell; vertical-align: middle; padding-left: 20px;}
.cash_use .risht_link > ul > li > a > p:first-child{padding-left: 30px; width: 120px;}
.cash_use .risht_link > ul > li > a > p:first-child > img{width: 100%;}
.cash_use .risht_link > ul > li > a > p > em{display: block; font-size: 13px; color: #000; line-height: 16px;}
.cash_use .risht_link > ul > li > a > p > span{display: block; font-size: 14px; font-weight: 700; color: #000; line-height: 17px;}
.cash_use .risht_link > ul > li > a > p > span > i{font-size: 15px; font-weight: 700;}
.cash_use .risht_link > ul > li > a > p > span > i.naver{color: #00a632;}
.cash_use .risht_link > ul > li > a > p > span > i.one{color: #f74c63;}
.cash_use .risht_link > ul > li > a > p > span > i.happy{color: #0d0f85;}

/* sub gnb [구매/선물] */
.purchase{width: 100%; font-size: 0; padding-bottom: 45px;}
.purchase .link{display: inline-block; width: calc(100% - 704px); vertical-align: top;}
.purchase .link > h5{font-size: 15px; line-height: 19px; font-weight: 700; color: #000; margin-top: 20px;}
.purchase .link > h5 > a{font-size: 15px; font-weight: 700;}
.purchase .link > h5:first-child{margin-top: 0;}
.purchase .guide{display: inline-block; width: 704px; background: #f8f8f8; border-radius: 16px; height: 208px; vertical-align: top;}

/* sub gnb [고객센터] */
.cs{width: 100%; font-size: 0;  padding-bottom: 45px;}
.cs .link{display: inline-block; width: calc(100% - 400px); vertical-align: top;}
.cs .link > ul{width: auto; text-align: left;}
.cs .link > ul > li{display: inline-block; margin-left: 80px; vertical-align: top;}
.cs .link > ul > li > h5{margin-top: 20px; font-size: 15px; color: #000; font-weight: 700; height: 19px; line-height: 19px;}
.cs .link > ul > li > h5:first-child{margin-top: 0;}
.cs .link > ul > li > h5 > a{font-size: 15px; line-height: 19px;  font-weight: 700;}
.cs .link > ul > li > p{margin-top: 10px; height: 19px; font-size: 15px; color: #666; line-height: 19px;}
.cs .link > ul > li > p > a{font-size: 15px; color: #666; line-height: 19px;}
.cs .link > ul > li > span{margin-top: 10px; display: block; height: 19px; font-size: 14px; color: #666; line-height: 19px;}
.cs .link > ul > li > span > a{font-size: 14px; color: #666; line-height: 19px;}

.cs .cs_info{display: inline-block; width: 400px; vertical-align: top; border: 1px solid #e8e8e8; border-radius: 16px; padding: 40px 30px;}
.cs .cs_info .info{border-bottom: 1px solid #000; padding-bottom: 12px;}
.cs .cs_info .info > h5{font-size: 14px; font-weight: 700; color: #000; line-height: 17px;}
.cs .cs_info .info > p{font-size: 24px; font-weight: 700; line-height: 29px; margin-top: 3px; color: #000;}
.cs .cs_info .info > span{display: block; font-size: 13px; line-height: 16px; margin-top: 4px; color: #999;}
.cs .cs_info .sub_info{width: 100%; margin-top: 10px;}
.cs .cs_info .sub_info > p{font-size: 13px; line-height: 18px; color: #000;}


/* header banner */
#in_banner {width: 100%; background: linear-gradient(90deg, rgba(233,85,118,1) 0%, rgba(255,168,87,1) 100%)#2ecc71; height: 50px; display: none;}
#in_banner.on{display: block;}
#in_banner .text_inner{width: 1280px; margin: 0 auto; font-size: 0; padding: 13px 0;}
#in_banner .text_inner > i{display: inline-block; width: 40px; height: 24px; border: 1px solid #fff; border-radius: 4px; font-size: 12px; font-weight: 500; line-height: 24px; text-align: center; vertical-align: middle; color: #fff;} 
#in_banner .text_inner > span{display: inline-block; margin-left: 12px; vertical-align: middle; font-size: 16px; font-weight: 700; color: #fff; padding-top: 4px; line-height: 1; }


/* footer */
#footer{width: 100%; height: 315px; background: #fff; border-top: 1px solid #e8e8e8; position: relative;}
.footer_top{width: 100%; border-bottom: 1px solid #e8e8e8; text-align: center;}
.footer_top .f_inner{width: 1280px; display: inline-table; height: 57px;}
.footer_top .f_inner .trems{width: 50%; display: table-cell;  vertical-align: middle; text-align: left; font-size: 0;}
.footer_top .f_inner .trems > a{display: inline-block; font-size: 14px; color: #555; margin-left: 28px;}
.footer_top .f_inner .trems > a:first-child{margin-left: 0;}
.footer_top .f_inner .trems > a > b{font-weight: 700; color: #000;}
.footer_top .f_inner .sns_ar{width: 50%; display: table-cell; vertical-align: middle; text-align: right; font-size: 0;}
.footer_top .f_inner .sns_ar > a{display: inline-block; width: 24px; height: 24px; margin-left: 20px; line-height: 24px; vertical-align: middle;}
.footer_top .f_inner .sns_ar > a.ut{padding-top: 3px;}

.foot_content{width: 100%;}
.foot_content .f_inner{width: 1280px; margin: 0 auto; font-size: 0; padding-top: 40px;}
.foot_content .f_inner > h1{display: inline-block; vertical-align: middle; width: 170px; margin-top: 42px;}
.foot_content .f_inner > h1 > img{width: 100%;}
.foot_content .f_inner .comp_info{display: inline-block; vertical-align: top; margin-left: 43px;}
.foot_content .f_inner .comp_info > h4{font-size: 16px; height: 19px; font-weight: 700; color: #555;}
.foot_content .f_inner .comp_info > p{margin-top: 4px; font-size: 13px; line-height: 22px; color: #7a7a7a;}
.foot_content .f_inner .comp_info > p.copy{margin-top: 13px;}
.foot_content .f_inner .cs_info{display: inline-block; vertical-align: top; margin-left: 70px;}
.foot_content .f_inner .cs_info > h4{font-size: 14px; font-weight: 700; height: 17px;}
.foot_content .f_inner .cs_info .tel{height: 28px; width: 100%; display: block; margin-top: 1; font-size: 0;}
.foot_content .f_inner .cs_info .tel > p{display: inline-block; font-size: 24px; font-weight: bold; line-height: 30px; vertical-align: middle; height: 28px;}
.foot_content .f_inner .cs_info .tel > button{width: 96px; height: 28px; border: 1px solid #e8e8e8; vertical-align: middle;  margin-left: 11px;}
.foot_content .f_inner .cs_info .tel > button > span{display: inline-block; padding-right: 17px; font-size: 13px; line-height: 14px; background: url(/_next/static/media/icon_more.379bec9c.svg) right center no-repeat;}
.foot_content .f_inner .cs_info .info{margin-top: 8px; font-size: 0;}
.foot_content .f_inner .cs_info .info .time{display: inline-block; font-size: 13px; color: #7a7a7a; height: 16px; vertical-align: middle;}
.foot_content .f_inner .cs_info .info .fax{display: inline-block; font-size: 13px; color: #7a7a7a; height: 16px; vertical-align: middle; margin-left: 12px;}
.foot_content .f_inner .cs_info .info .mail{display: block; font-size: 13px; color: #000; height: 16px; margin-top: 8px;}
.foot_content .f_inner .cs_info .info .mail > em{font-size: 13px; color: #000; margin-left: 8px;}
.foot_content .f_inner .cs_info .info .mail > em:first-child {margin-left: 0; font-weight: 700;}

.quick_link{width: auto; margin-top: 15px;}
.quick_link .quick_link_ar{display:inline-block; vertical-align: middle;}
.quick_link .btn_site{height: 35px;width: 271px;display:inline-block;border-bottom:1px solid rgba(0,0,0,0.20);line-height: 35px; font-size: 16px;letter-spacing: -0.05em;/* padding-left: 15px; */ position: relative; overflow: hidden;}
.quick_link .btn_site::before {content: ""; position: absolute; width: 13px; height: 13px; top: 11px; left: auto; right: 0; z-index: 1; background: url(/_next/static/media/icon-open.f7a4f05c.svg) center center no-repeat; transition: all ease 0.1s;}
.quick_link .btn_site.on::before {content: ""; position: absolute;  width: 13px;  height: 13px;  top: 11px;  left: auto;  right: 0;  z-index: 1;  background: url(/_next/static/media/icon-open.f7a4f05c.svg) center center no-repeat;  transform: rotate(-45deg);}
.quick_link .link_box{width: 232px;box-sizing: border-box;position: absolute;background: #fff;border: 1px solid #e8e8e8;padding: 25px;font-size: 14px;letter-spacing: -0.05em;/* margin-top: -180px; */display: none; bottom: 108px;}
.quick_link .link_box > a{display: block; margin-top: 14px;  color: #000;}
.quick_link .link_box > a:first-child{margin-top: 0;}
.quick_link .btn_site2{height: 35px;width: 232px;display:inline-block;border-bottom:1px solid rgba(0,0,0,0.20);line-height: 35px;font-size: 16px;letter-spacing: -0.05em;margin-top: 0;margin-left: 24px;/* padding-left: 15px; */ position: relative;}
.quick_link .btn_site2::before {content: ""; position: absolute; width: 13px; height: 13px; top: 11px; left: auto; right: 0; z-index: 1; background: url(/_next/static/media/icon-open.f7a4f05c.svg) center center no-repeat; transition: all ease 0.1s;}
.quick_link .btn_site2.on::before {content: ""; position: absolute; width: 13px; height: 13px; top: 11px; left: auto; right: 0; z-index: 1; background: url(/_next/static/media/icon-open.f7a4f05c.svg) center center no-repeat; transform: rotate(-45deg);}
.quick_link .link_box2{width: 232px;box-sizing: border-box; position: absolute; background: #fff;border: 1px solid #e8e8e8;padding: 25px;font-size: 14px;letter-spacing: -0.05em; bottom: 108px; display: none; margin-left: 24px;}
.quick_link .link_box2 > a{display: block; margin-top: 14px;  color: #000;}
.quick_link .link_box2 > a:first-child{margin-top: 0;}

/* main_content */
.main_content{width: 100%;}
.main_content > section .inner{width: 1280px; margin: 0 auto; font-size: 0;}

/* main banner_area */
.banner_area{width: 100%; padding-top: 70px;}
.banner_box_w{display:inline-block; width: 860px; vertical-align: top;}
.banner_box_w .mainSwiper_w{padding-bottom: 48px;}
.banner_box_w .swiper-slide{width: 420px; height: 350px; display: inline-block; border-radius: 16px; overflow: hidden;}
.banner_box_w .mainSwiper_w.wide .swiper-slide{width: 860px !important; }
.banner_box_w .swiper-slide > img{width: 100%;}
.banner_box_w .swiper-btn-wrap{position: absolute; bottom: 0; width: 100%; height: 20px; z-index: 99; text-align: center; font-size: 13px;}
.banner_box_w .swiper-btn{position: relative; height: 20px; display: inline-block;}
.banner_box_w .swiper-btn .prev{background: url(/_next/static/media/icon_prev.2cfe275b.svg) center no-repeat; width: 20px; height: 20px; margin-left: 9px; display: inline-block; position: relative; vertical-align: middle;  }
.banner_box_w .swiper-btn .next{background: url(/_next/static/media/icon_next.3e75eb5d.svg) center no-repeat; width: 20px; height: 20px; margin-right: 9px; display: inline-block; position: relative; vertical-align: middle;}
.banner_box_w .swiper-btn-wrap .start-stop{width:20px; height:20px; display: inline-block; margin-left: 10px; vertical-align: middle;}
.banner_box_w .swiper-btn-wrap .start-stop > button{width:20px; height:20px; vertical-align: middle; border: 0; padding: 0;}
.banner_box_w .swiper-btn-wrap .start-stop > button.start{background: url(/_next/static/media/icon_play.5c200d11.svg) center no-repeat;}
.banner_box_w .swiper-btn-wrap .start-stop > button.stop{background: url(/_next/static/media/icon_stop.b568c033.svg) center no-repeat;}
.banner_box_w .swiper-btn-wrap .start-stop > button.on {display: block;}
.banner_box_w .swiper-btn-wrap .start-stop > button.off {display: none;}
.banner_box_w .swiper-pagination-fraction{bottom: auto; font-weight: 500; font-size: 13px; color: #999999; width: auto; display: inline-block; position: relative; vertical-align: middle;}
.banner_box_w .swiper-pagination-current{ color: #000;  font-weight: 500; display: inline-block; min-width: 8px;}
.banner_box_w .swiper-pagination-total{color: #999;  font-weight: 500;}


.banner_box_s{display:inline-block; width: 400px; vertical-align: top; margin-left: 20px; overflow: hidden;}
.banner_box_s .mainSwiper_s{padding-bottom: 48px;}
.banner_box_s .swiper-slide{width: 400px; height: 350px; display: inline-block; border-radius: 16px; overflow: hidden;}
.banner_box_s .swiper-slide .banner_box{width: 100%; position: relative; height: 350px;}
.banner_box_s .swiper-slide .banner_box > img{width: 100%;}
.banner_box_s .swiper-slide .banner_box > h5{font-size: 24px; font-weight: 700; line-height: 28px; text-align: center; width: 100%; position: absolute; bottom: 0; left: 0;}
.banner_box_s .swiper-btn-wrap{position: absolute; bottom: 0; width: 100%; height: 20px; z-index: 99; text-align: center; font-size: 13px;} 
.banner_box_s .swiper-btn{position: relative; height: 20px; display: inline-block;}
.banner_box_s .swiper-btn .prev{background: url(/_next/static/media/icon_prev.2cfe275b.svg) center no-repeat; width: 20px; height: 20px; margin-left: 9px; display: inline-block; position: relative; vertical-align: middle; }
.banner_box_s .swiper-btn .next{background: url(/_next/static/media/icon_next.3e75eb5d.svg) center no-repeat; width: 20px; height: 20px; margin-right: 9px; display: inline-block; position: relative; vertical-align: middle;}
.banner_box_s .swiper-pagination-bullets.swiper-pagination-horizontal{bottom: auto; font-weight: 500; font-size: 13px; color: #999999; width: auto; display: inline-block; position: relative; vertical-align: middle;}
.banner_box_s .swiper-pagination-bullet{width: 6px; height: 6px; background: #e5e5e5; margin: 0 4px; opacity: 1;}
.banner_box_s .swiper-pagination-bullet-active{background: #000;}


/* main guide_area */
.guide_ar{width: 100%; padding: 70px 0 90px;}
.bookmark_menu{width: 860px; height: 190px; background: #f8f8f8; border-radius: 16px; overflow: hidden; display: inline-block; padding: 41px 42px 0 55px;}
.bookmark_menu .circle_type{width: calc(100% - 166px); display: inline-block; vertical-align: middle; font-size: 0;}
.bookmark_menu .circle_type > a{display: inline-block; width: 90px; text-align: center; vertical-align: top; margin-left: 24px;}
.bookmark_menu .circle_type > a:first-child{margin-left: 0;}
.bookmark_menu .circle_type > a .icon_box{width: 60px; margin: 0 auto;}
.bookmark_menu .circle_type > a .menu_title{width: 100%; display: table; margin-top: 16px;}
.bookmark_menu .circle_type > a .menu_title > p{display: table-cell; width: 100%; vertical-align: middle; font-size: 14px; font-weight: 700; line-height: 17px; height: 34px; }
.bookmark_menu .button_type{width: 166px; display: inline-block; vertical-align: middle;}
.bookmark_menu .button_type > a{display: table; width: 100%; background: linear-gradient(90deg, rgba(233,85,118,1) 0%, rgba(255,168,87,1) 100%); height: 42px; border-radius: 21px; padding: 2px; overflow: hidden;  margin-top: 16px; }
.bookmark_menu .button_type > a:first-child{margin-top: 0;}
.bookmark_menu .button_type > a > span{display: table-cell; background: #fff; height: 38px; vertical-align: middle; font-size: 14px; font-weight: 700; text-align: center; border-radius: 21px;}
.use_guide{width: 400px; height: 190px; background: #f8f8f8; border-radius: 16px; overflow: hidden; display: inline-block; margin-left: 20px;}
.use_guide .swiper-slide{background: #f8f8f8;}
.use_guide .swiper-slide > a{display: block; width: 100%; position: relative;}
.use_guide .swiper-slide > a > img{width: 100%;}
.use_guide .swiper-slide > a .title{position: absolute; width: 100%; text-align: center; bottom: 22px;}
.use_guide .swiper-slide > a .title > h5{font-size: 18px; line-height: 27px; color: #000; height: 27px;}
.use_guide .swiper-slide > a .title > h4{font-size: 26px; line-height: 31px; color: #000; font-weight: 700; height: 31px;}

/* main e-coupun_area */
.e-coupun_area{width: 100%;}
.e-coupun_area .inner{border-top: 3px solid #000; padding: 70px 0 90px;}
.coupon_tab_box{width: calc(100% - 400px); display: inline-block; vertical-align: top;}
.coupon_tab_box .left_menu{width: 194px; display: inline-block; vertical-align: top;}
.coupon_tab_box .left_menu .title{width: 100%;}
.coupon_tab_box .left_menu .title > h3{font-size: 42px; font-weight: 700; height: 51px;}
.coupon_tab_box .left_menu .title > p{font-size: 15px; line-height: 22px; margin-top: 3px;}
.coupon_tab_box .left_menu .title > a.more{margin-top: 18px; display: inline-block; padding-right: 25px; font-size: 15px; height: 20px; line-height: 20px; font-weight: 700; background: url(/_next/static/media/icon_more2.6037dfca.svg) right center no-repeat; }
.coupon_tab_box .left_menu .tab_list{margin-top: 40px; width: 100%;}
.coupon_tab_box .left_menu .tab_list > ul{width: 100%; font-size: 0;}
.coupon_tab_box .left_menu .tab_list > ul > li{display: inline-block; width: 94px; height: 34px; margin-top: 6px; border-radius: 21px; background: #eeeeee; border: 1px solid #eeeeee; overflow: hidden; font-size: 13px; line-height: 34px; text-align: center; cursor: pointer;}
.coupon_tab_box .left_menu .tab_list > ul > li:nth-child(2n){margin-left: 6px;}
.coupon_tab_box .left_menu .tab_list > ul > li.active{background: #fff; border: 1px solid #000; font-weight: 700;}
.coupon_tab_box .tab_content{width: calc(100% - 194px); padding:0 62px 0 44px; display: inline-block; vertical-align: top;}
.coupon_tab_box .tab_content > ul{width: 100%; font-size: 0;}
.coupon_tab_box .tab_content > ul > li{width: 80px; display: inline-block; margin-left: 20px; margin-bottom: 30px; vertical-align: top; cursor: pointer;}
.coupon_tab_box .tab_content > ul > li:first-child{margin-left: 0;}
.coupon_tab_box .tab_content > ul > li:nth-child(6n+1){margin-left: 0;}
.coupon_tab_box .tab_content > ul > li .img_inner{width: 80px; height: 80px; border-radius: 40px; border: 1px solid #ededed; padding: 5px; overflow: hidden;}
.coupon_tab_box .tab_content > ul > li .img_inner > img{width: 100%; border-radius: 40px;}
.coupon_tab_box .tab_content > ul > li > p{margin-top: 12px; font-size: 14px; height: 34px; line-height: 17px; overflow: hidden; text-align: center;}

.best_coupon_box{width: 400px; display: inline-block; vertical-align: top;}
.best_coupon_box > ul{width: 100%;}
.best_coupon_box > ul > li{width: 100%; padding: 30px 33px 0px 35px;}
.best_coupon_box > ul > li:first-child{padding-top: 0; padding-bottom: 30px; border-bottom: 1px solid #e8e8e8;}
.best_coupon_box > ul > li > a{display: block; width: 100%; font-size: 0;}
.best_coupon_box > ul > li > a .p_img{width: 130px; display: inline-block; vertical-align: middle;}
.best_coupon_box > ul > li > a .p_img > img{width: 100%;}
.best_coupon_box > ul > li > a .p_info{width: calc(100% - 130px); display: inline-block; vertical-align: middle; padding-left: 22px;}
.best_coupon_box > ul > li > a .p_info > h2{font-size: 18px; font-weight: 700; height: 21px;}
.best_coupon_box > ul > li > a .p_info > p{margin-top: 6px; font-size: 13px; color: #666; height: 16px;}
.best_coupon_box > ul > li > a .p_info > h3{margin-top: 4px; font-size: 14px; font-weight: 600; line-height: 18px; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
.best_coupon_box > ul > li > a .p_info .price{margin-top: 8px; width: 100%;}
.best_coupon_box > ul > li > a .p_info .price > span{display: inline-block; margin-left: 5px; font-size: 15px; color: #999; text-decoration: line-through; vertical-align: middle; line-height: 23px;}
.best_coupon_box > ul > li > a .p_info .price > span:first-child{margin-left: 0; color: #000; font-weight: 700; text-decoration: none;}


/* main 사용처 */
.use_area{width: 100%; background: #f8f8f8; padding: 80px 0;}
.use_tab_box{width: calc(100% - 400px); display: inline-block; vertical-align: top;}
.use_tab_box .left_menu{width: 194px; display: inline-block; vertical-align: top;}
.use_tab_box .left_menu .title{width: 100%;}
.use_tab_box .left_menu .title > h3{font-size: 42px; font-weight: 700; height: 51px;}
.use_tab_box .left_menu .title > a.more{margin-top: 18px; display: inline-block; padding-right: 25px; font-size: 15px; height: 20px; line-height: 20px; font-weight: 700; background: url(/_next/static/media/icon_more2.6037dfca.svg) right center no-repeat; }
.use_tab_box .left_menu .use_tab_list{margin-top: 40px; width: 100%;}


.use_tab_box .left_menu .use_tab_list > ul{width: 100%; font-size: 0;}
.use_tab_box .left_menu .use_tab_list > ul > li{width: 100%; height: 34px; margin-top: 6px; border-radius: 21px; background: #eeeeee; border: 1px solid #eeeeee; overflow: hidden; font-size: 13px; line-height: 34px; text-align: center; cursor: pointer;}
.use_tab_box .left_menu .use_tab_list > ul > li.active{background: #fff; border: 1px solid #000; font-weight: 700;}
.use_tab_box .tab_content{width: calc(100% - 194px); padding:0 62px 0 44px; display: inline-block; vertical-align: top;}
.use_tab_box .tab_content .mainSwiper_use{padding-bottom: 7px;}
.use_tab_box .tab_content .mainSwiper_use .swiper-slide > ul{width: 100%; font-size: 0;}
.use_tab_box .tab_content .mainSwiper_use .swiper-slide > ul > li{width: 84px; display: inline-block; margin-left: 15px; margin-bottom: 40px; vertical-align: top; cursor: pointer;}
.use_tab_box .tab_content .mainSwiper_use .swiper-slide > ul > li:first-child{margin-left: 0;}
.use_tab_box .tab_content .mainSwiper_use .swiper-slide > ul > li:nth-child(6n+1){margin-left: 0;}
.use_tab_box .tab_content .mainSwiper_use .swiper-slide > ul > li .img_inner{width: 84px; height: 84px; border-radius: 8px; overflow: hidden; background: #fff;}
.use_tab_box .tab_content .mainSwiper_use .swiper-slide > ul > li .img_inner > img{width: 100%;}
.use_tab_box .tab_content .mainSwiper_use .swiper-slide > ul > li > p{margin-top: 18px; font-size: 14px; overflow: hidden; width: 100%; height: 17px; text-align: center;}
.mainSwiper_use .swiper-btn-wrap{position: absolute; bottom: 0; width: 100%; height: 20px; z-index: 99; text-align: center; font-size: 13px;} 
.mainSwiper_use .swiper-btn{position: relative; height: 20px; display: inline-block;}
.mainSwiper_use .swiper-btn .prev{background: url(/_next/static/media/icon_prev.2cfe275b.svg) center no-repeat; width: 20px; height: 20px; margin-left: 9px; display: inline-block; position: relative;  vertical-align: middle;}
.mainSwiper_use .swiper-btn .next{background: url(/_next/static/media/icon_next.3e75eb5d.svg) center no-repeat; width: 20px; height: 20px; margin-right: 9px; display: inline-block; position: relative; vertical-align: middle;}
.mainSwiper_use .swiper-pagination-bullets.swiper-pagination-horizontal{bottom: auto; font-weight: 500; font-size: 13px; color: #999999; width: auto; display: inline-block; position: relative; vertical-align: middle;}
.mainSwiper_use .swiper-pagination-bullet{width: 6px; height: 6px; background: #e5e5e5; margin: 0 4px; opacity: 1;}
.mainSwiper_use .swiper-pagination-bullet-active{background: #000;}

.use_info_ar{width: 400px; display: inline-block; vertical-align: top; background: #fff; border-radius: 16px; overflow: hidden;}
.use_info_ar > ul{width: 100%;}
.use_info_ar > ul > li{width: 100%; padding: 32px 24px 20px 24px; border-top: 1px solid #e8e8e8;}
.use_info_ar > ul > li:first-child{border: 0;}
.use_info_ar > ul > li > a{display: block;}
.use_info_ar > ul > li > a .brand_img{height: 28px;}
.use_info_ar > ul > li > a .brand_img > img{height: 100%;}
.use_info_ar > ul > li > a > h4{margin-top: 22px; font-size: 18px; font-weight: 700; height: 21px;}
.use_info_ar > ul > li > a > p{margin-top: 4px; font-size: 14px; line-height: 20px; color: #666;}
.use_info_ar > a{display: block; width: 100%; background: #eee; height: 40px; line-height: 40px; font-size: 13px; font-weight: 700; text-align: center;}

/* main 공지사항 & 기타 */
.etc_area{width: 100%; background: #fff; padding: 42.5px 0 90px;}
.etc_area .notice_area{width: 640px; display: inline-block; padding-top: 47.5px; vertical-align: top;}
.etc_area .notice_area .nt_title{width: 100%; font-size: 0; display: table; padding-bottom: 19px; border-bottom: 3px solid #000;}
.etc_area .notice_area .nt_title > h5{display: table-cell; vertical-align: bottom; font-size: 22px; font-weight: 700;}
.etc_area .notice_area .nt_title > span{display: table-cell; vertical-align: bottom; text-align: right;}
.etc_area .notice_area .nt_title > span > a{display: inline-block; font-size: 14px; font-weight: 700;}
.etc_area .notice_area > ul{width: 100%;}
.etc_area .notice_area > ul > li{padding-top: 24px;}
.etc_area .notice_area > ul > li > a{font-size: 0;}
.etc_area .notice_area > ul > li > a > i{font-size: 14px; color: #f93964; margin-right: 5px; display: inline-block; vertical-align: bottom;}
.etc_area .notice_area > ul > li > a > p{font-size: 14px; color: #000; margin-right: 5px; display: inline-block; vertical-align: bottom; max-width: 400px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}

.etc_area .ad_area{width: 570px; display: inline-block; vertical-align: top; margin-left: 70px;}
.etc_area .ad_area .ad_banner{padding-top: 47.5px; width: 100%; position: relative;}
.etc_area .ad_area .ad_banner .banner_in{width: 100%; height: 180px; background: #f93964; border-radius: 16px; position: relative;}
.etc_area .ad_area .ad_banner .banner_in .title{display: inline-block; position: absolute; left: 40px; bottom: 30px; }
.etc_area .ad_area .ad_banner .banner_in .title > h5{font-size: 24px; font-weight: 700; height: 29px; color: #fff;}
.etc_area .ad_area .ad_banner .banner_in .title > p{margin-top: 3px; font-size: 17px; height: 20px; color: #fff;}
.etc_area .ad_area .ad_banner .banner_in .link_ar{width: 100px; position: absolute; top: 18px; right: 30px; font-size: 0;}
.etc_area .ad_area .ad_banner .banner_in .link_ar .qr_ar{width: 100%; height: 100px; background: #fff; border-radius: 4px; padding: 3px; display: block;}
.etc_area .ad_area .ad_banner .banner_in .link_ar .qr_ar > img{width: 100%;}
.etc_area .ad_area .ad_banner .banner_in .link_ar > a.apple{ margin-top: 4px; width: 48px; height: 40px; padding-top: 8px; text-align: center; display: inline-block;  background: #fff; border-radius: 4px;}
.etc_area .ad_area .ad_banner .banner_in .link_ar > a.apple > img{width: 19px;}
.etc_area .ad_area .ad_banner .banner_in .link_ar > a.g_play{ margin-left: 4px; margin-top: 4px; width: 48px; height: 40px; padding-top: 9px; text-align: center; display: inline-block; background: #fff; border-radius: 4px;}
.etc_area .ad_area .ad_banner .banner_in .link_ar > a.g_play > img{width: 21px;}
.etc_area .ad_area .ad_banner .ad_img{width: 227px; position: absolute; left: 92.3px; top: 0;}
.etc_area .ad_area .ad_banner .ad_img > img{width: 100%;}
.etc_area .ad_area .ad_btn{width: 100%; border: 1px solid #000; border-radius: 16px; text-align: center; margin-top: 16px;}
.etc_area .ad_area .ad_btn > a{display: inline-table; height: 82px; }
.etc_area .ad_area .ad_btn > a > i{font-size: 14px; display: table-cell; vertical-align: middle;}
.etc_area .ad_area .ad_btn > a > span{font-size: 20px; padding-left: 16px; font-weight: 700; display: table-cell; vertical-align: middle;}


/* 캐시충전 */
.cash_refill{width: 100%;}
.cash_refill .refile_top{width: 100%; background: #fff; padding: 56px 0 30px;}
.cash_refill .refile_top h2{font-size: 28px; line-height: 29px; font-weight: 700;}
.cash_refill .refile_top .refile_tab{width: 100%; font-size: 0; text-align: center; padding-top: 40px;}
.cash_refill .refile_top .refile_tab > ul{width: 720px; margin: 0 auto; text-align: center;}
.cash_refill .refile_top .refile_tab > ul > li{display: inline-block; vertical-align: middle; width: auto; height: 40px; border-radius: 20px; background: #fff; border: 1px solid #e8e8e8; font-size: 16px; line-height: 40px; padding: 0 21px; margin: 0 0 0 10px; cursor: pointer;}
.cash_refill .refile_top .refile_tab > ul > li:first-child{margin-left: 0;}
.cash_refill .refile_top .refile_tab > ul > li.active{background: #000; border-color: #000; color: #fff;}

.refile_content{width: 1200px; margin: 0 auto; padding: 60px 0 90px;}
.giftcard_area{width: 720px; margin: 0 auto; position: relative;}
.giftcard_area > h3{font-size: 18px; font-weight: 700; text-align: center; line-height: 21px;}
.giftcard_area > h3 > em{display: inline-block; padding-left: 6px; font-size: 12px; line-height: 21px; color: #f93964; font-weight: 700;}
.giftcard_area > p{text-align: center; font-size: 20px; line-height: 26px; padding-top: 5px;}
.giftcard_area > p > i{color: #f93964; font-size: 20px;}
.giftcard_area .guide_ar{margin-top: 31px; border: 1px solid #e8e8e8; border-radius: 16px; padding: 25px 52px 25px 2px; font-size: 0; background: #fbfbfb;}
.giftcard_area .guide_ar .g_text{display: inline-block; width: calc(100% - 344px); text-align: center; vertical-align: middle;} 
.giftcard_area .guide_ar .g_text > p{font-size: 16px; height: 19px;}
.giftcard_area .guide_ar .g_text > span{display: block; font-size: 16px; height: 19px; margin-top: 4px; color: #f93964; font-weight: 700;}
.giftcard_area .guide_ar .g_img{display: inline-block; width: 344px; vertical-align: middle;} 
.giftcard_area .guide_ar .g_img > img{width: 100%;}

.giftcard_area .refill_giftcard{width: 100%; padding-top: 30px;}
.giftcard_area .refill_giftcard .top_button{width: 100%; text-align: right; font-size: 0;}
.giftcard_area .refill_giftcard .top_button > button{padding: 0 18px; height: 32px; background: #ffa341; border-radius: 8px; font-size: 0; margin-left: 6px;}
.giftcard_area .refill_giftcard .top_button > button > span{display: inline-block; font-size: 14px; color: #fff; font-weight: 700; vertical-align: middle;}
.giftcard_area .refill_giftcard .top_button > button > i{display: inline-block; font-size: 12px; color: #fff; margin-left: 4px; vertical-align: middle;}

.giftcard_area .refill_giftcard .inner_box{width: 100%; padding: 60px 120px; margin-top: 12px; background: #fff; border-radius: 16px;}
.giftcard_area .refill_giftcard .inner_box > h4{font-size: 18px; font-weight: 700; text-align: center;}
.giftcard_area .refill_giftcard .inner_box > p{font-size: 14px; margin-top: 10px; text-align: center;}
.giftcard_area .refill_giftcard .inner_box > p > i{color: #f93964;}
.giftcard_area .refill_giftcard .inner_box .refill_input{width: 100%; padding-top: 50px;}
.giftcard_area .refill_giftcard .inner_box .refill_input .inp_control{width: 100%; display: table;}
.giftcard_area .refill_giftcard .inner_box .refill_input .inp_control > p{display: table-cell; text-align: right;}
.giftcard_area .refill_giftcard .inner_box .refill_input .inp_control > p:first-child{text-align: left; font-size: 14px; color: #f93964;}
.giftcard_area .refill_giftcard .inner_box .refill_input .inp_control > p > button{padding: 0; margin: 0; border: 0; background: 0;}
.giftcard_area .refill_giftcard .inner_box .refill_input .inp_control > p > button > span{font-size: 14px; line-height: 14px; padding-left: 17px; background: url(/_next/static/media/reset.eb4c7085.svg) left center no-repeat; }
.giftcard_area .refill_giftcard .inner_box .refill_input .inp_ar{width: 100%; border: 1px solid #e8e8e8; border-radius: 8px; font-size: 0; margin-top: 12px;}
.giftcard_area .refill_giftcard .inner_box .refill_input .inp_ar.focus{border-color: #f93964;}
.giftcard_area .refill_giftcard .inner_box .refill_input .inp_ar .w_l{display: inline-block; padding: 0 20px 0; width: calc(100% - 198px); vertical-align: middle;}
.giftcard_area .refill_giftcard .inner_box .refill_input .inp_ar > i{display: inline-block; width: 1px; height: 28px; vertical-align: middle; background: #e8e8e8;}
.giftcard_area .refill_giftcard .inner_box .refill_input .inp_ar .w_s{display: inline-block; padding: 0 20px 0; width: 197px; vertical-align: middle;}
.giftcard_area .refill_giftcard .inner_box .refill_input > button{width: 100%; background: #ededed; border-radius: 8px; font-size: 0; margin-top: 12px; height: 64px;}
.giftcard_area .refill_giftcard .inner_box .refill_input > button > span{font-size: 13px; line-height: 18px; display: inline-block; padding-left: 24px; background: url(/_next/static/media/icon-add-plus.b6250e90.svg)left center no-repeat;}
.giftcard_area .refill_giftcard .inner_box .recaptcha_ar{margin-top: 12px; text-align: center;}
.giftcard_area .refill_giftcard .button_area{margin-top: 40px;}
.giftcard_area .refill_giftcard .button_area > button{width: 100%; background: #f93964; border-radius: 8px; font-size: 16px; color: #fff; height: 54px;}





/* 인풋박스 액션 */
.inp_action { position:relative; width:100%; height:62px; padding-top: 16px; } /* 기본세팅 */
.inp_action input { box-sizing:border-box; padding: 16px 0 13px 0; vertical-align: bottom; width:100%; height:100%; border:0 none; color:#000; font-size: 16px; outline:none; font-weight: 700;  }
.inp_action label { position:absolute; left:0%; bottom:0; width:100%; height:100%; text-align:left; pointer-events:none; }
.inp_action label:after { content:""; position:absolute; left:0; bottom:-1px; width:0; height:100%;} /* 파란색 가로줄 */
.inp_action label span { position:absolute; left:0; bottom:20px; transition:all .3s ease; font-size: 14px; color: #c8c8c8; }
.inp_action input:focus + label span, 
.inp_action input:valid + label span { transform:translateY(); bottom: 35px; font-size:11px;  }  /* input에 글을 입력하고 포커스가 지나간 상태에서 제어하려면 valid 선택자를 써야한다. */
/* 포커스 될 때 label span(name)이 위로 올라감 */
.inp_action input:focus + label::after,
.inp_action input:valid + label::after { width:100%; transform:translateX(0); } 


.giftcard_area .refill_giftcard .outcome_box{width: 100%; padding: 60px 120px; margin-top: 12px; background: #fff; border-radius: 16px;}
.giftcard_area .refill_giftcard .outcome_box > h4{font-size: 18px; font-weight: 700; text-align: center;}
.giftcard_area .refill_giftcard .outcome_box .outcome_inner{margin-top: 24px; border-top: 1px solid #000;}
.giftcard_area .refill_giftcard .outcome_box .outcome_inner .total{text-align: center; font-size: 0; padding: 30px 0 40px;}
.giftcard_area .refill_giftcard .outcome_box .outcome_inner .total > span{display: inline-block; font-size: 16px; margin: 0 20px;}
.giftcard_area .refill_giftcard .outcome_box .outcome_inner .total > span > i.fail{color: #f93964; font-weight: 700;}
.giftcard_area .refill_giftcard .outcome_box .outcome_inner > ul{width: 100%; background: #fbfbfb; padding: 12px 30px; border-radius: 8px;}
.giftcard_area .refill_giftcard .outcome_box .outcome_inner > ul > li{padding: 12px 0; width: 100%; display: table; border-top: 1px solid #e8e8e8;}
.giftcard_area .refill_giftcard .outcome_box .outcome_inner > ul > li:first-child{border-top: 0;}
.giftcard_area .refill_giftcard .outcome_box .outcome_inner > ul > li > span{font-size: 14px; text-align: right; color: #f93964 ; display: table-cell;}
.giftcard_area .refill_giftcard .outcome_box .outcome_inner > ul > li > span:first-child{text-align: left; color: #000 ;}



.refill_sub_tab{width: 100%; border-top: 1px solid #e8e8e8; padding-top: 24px; background: #fff;}
.refill_sub_tab > ul{width: 1200px; margin: 0 auto; text-align: center; font-size: 0;}
.refill_sub_tab > ul > li{display: inline-block; padding-bottom: 19px; vertical-align: top; margin: 0 22px; border-bottom: 3px solid #fff; cursor: pointer;}
.refill_sub_tab > ul > li.active{border-bottom: 3px solid #000;}
.refill_sub_tab > ul > li > i{display: inline-block; width: 17px; height: 21px; background: url(/_next/static/media/icon-new.6cf4ec5b.svg) center 1px no-repeat; margin-right: 6px; vertical-align: middle;}
.refill_sub_tab > ul > li > span{display: inline-block; font-size: 18px; line-height: 21px; font-weight: 700; vertical-align: middle;}
.refill_sub_tab > ul > li > em{display: inline-block; margin-left: 6px; font-size: 12px; line-height: 21px; font-weight: 700; color: #f93964; vertical-align: middle;}

.refill_common{width: 720px; margin: 0 auto;}
.refill_common .inner_box{width: 100%; padding: 50px 180px 60px; margin-top: 30px; background: #fff; border-radius: 16px;}
.inner_box .common_type{width: 100%;}
.inner_box .common_type .cash_inp{height: 52px; padding: 0 4px; width: 100%; position: relative;}
.input-group{position: relative; width: 100%; }
.input-group > input{width: 100%; height: 52px; border-bottom: 2px solid #f93964; font-size: 28px; font-weight: 700;}
.input-group > input:focus{border-bottom: 2px solid #f93964 !important;}
.input-group > input:-moz-placeholder-shown{border-bottom: 2px solid #e8e8e8;   }
.input-group > input:-ms-input-placeholder{border-bottom: 2px solid #e8e8e8;   }
.input-group > input:placeholder-shown{border-bottom: 2px solid #e8e8e8;   }
.input-group > input::-moz-placeholder{color: #c8c8c8 !important; font-size: 24px !important; font-weight: normal !important;}
.input-group > input:-ms-input-placeholder{color: #c8c8c8 !important; font-size: 24px !important; font-weight: normal !important;}
.input-group > input::placeholder{color: #c8c8c8 !important; font-size: 24px !important; font-weight: normal !important;}
.input-group > input[type=text]:-ms-clear{display: none;}
.input-group > button{position: absolute; right: 3px; top: 0; bottom: 0; width: 16px; height: 16px; margin: auto; font-size: 12px; cursor: pointer; background: url(/_next/static/media/button-btn-cancel.ced58994.svg) no-repeat;}
.input-group > button.all_cash{width: 47px; height: 28px; border: 1px solid #e8e8e8; background: #fff; font-size: 13px; position: absolute; right: 0; border-radius: 4px;}
.input-group > button.btn_clear{position: absolute; right: 70px; top: 0; bottom: 0; width: 20px; height: 20px; margin: auto; font-size: 12px; cursor: pointer; background: url(/_next/static/media/button-btn-cancel.ced58994.svg) no-repeat; background-size: 100%;}
.inner_box .common_type > p{margin-top: 11px; font-size: 13px; color: #999; height: 16px; padding: 0 4px;}
.inner_box .common_type > span{display: block; margin-top: 6px; font-size: 13px; color: #000; height: 16px; padding: 0 4px;}
.inner_box .common_type .cash_add{width: 100%; font-size: 0; margin-top: 21px; border-top: 1px solid #e8e8e8; border-left: 1px solid #e8e8e8;}
.inner_box .common_type .cash_add > button{width: 33.3%; display: inline-block; border-bottom: 1px solid #e8e8e8; border-right: 1px solid #e8e8e8; height: 50px;}
.inner_box .common_type .cash_add > button:nth-child(2), .refill_common .inner_box .common_type .cash_add > button:nth-child(5){width: 33.4%;} 
.inner_box .common_type .cash_add > button > span{display: inline-block; padding-left: 13px; font-size: 14px; background: url(/_next/static/media/icon-icon-plus.39f512ce.svg) left center no-repeat;}
.giftcard_area .refill_common .button_area{margin-top: 40px;}
.giftcard_area .refill_common .button_area > button{width: 100%; background: #f93964; border-radius: 8px; font-size: 16px; color: #fff; height: 54px;}
.giftcard_area .guide_btn{width: 100%; text-align: right; position: absolute; top: 24px;}
.giftcard_area .guide_btn > button{padding-left: 20px; font-size: 14px; line-height: 17px; background: url(/_next/static/media/icon_i.c8d6fe9e.svg)left center no-repeat; display: inline-block;}
.giftcard_area .refill_common .info_area{padding-top: 24px;}
.giftcard_area .refill_common .info_area > p{margin-top: 6px; font-size: 13px; background: url(/_next/static/media/dot_01.6a71e25e.png) left center no-repeat; background-size: 2px; padding-left: 8px; color: #666; line-height: 16px;}

.hp_area{width: 100%; padding: 20px 16px 16px; display: table; border: 1px solid #ededed; border-radius: 8px; margin: 10px 0 40px;}
.hp_area .phone_number{display: table-cell; vertical-align: middle;}
.hp_area .phone_number > p{font-size: 13px; line-height: 18px; color: #999;}
.hp_area .phone_number > strong{display: block; font-size: 16px; line-height: 24px; margin-top: 2px;}
.hp_area .ch_btn{display: table-cell; text-align: right; vertical-align: middle;}
.hp_area .ch_btn > button{width: 60px; height: 34px; background: #e5e5e5; border-radius: 4px; font-size: 14px;}

.giftcard_area .comp_com{width: 100%; background: #fff; padding: 60px 0; border-radius: 16px;}
.giftcard_area .comp_com > h3{font-size: 20px; font-weight: 500; text-align: center; line-height: 21px;}
.giftcard_area .comp_com > h3 > i{font-weight: 700;}
.giftcard_area .comp_com > p{text-align: center; font-size: 20px; line-height: 26px; padding-top: 5px;}
.giftcard_area .comp_com > p > i{color: #f93964; font-size: 20px;}
.giftcard_area .comp_com .comp_common{width: 360px; margin: 24px auto 0;}
.giftcard_area .comp_com .comp_common > ul{width: 100%; border-top: 1px solid #e8e8e8; padding: 16px;}
.giftcard_area .comp_com .comp_common > ul:first-child{border-top: 1px solid #000;}
.giftcard_area .comp_com .comp_common > ul > li{display: table; width: 100%; padding-top: 12px; }
.giftcard_area .comp_com .comp_common > ul > li:first-child{padding-top: 0;}
.giftcard_area .comp_com .comp_common > ul > li > span{display: table-cell; text-align: right; font-size: 14px; color: #222020;}
.giftcard_area .comp_com .comp_common > ul > li > span:first-child{text-align: left;}
.giftcard_area .comp_com .comp_common > ul > li.last > span{font-weight: 700;}
.giftcard_area .comp_com .comp_common .button_area2{width: 100%; margin-top: 34px; font-size: 0;}
.giftcard_area .comp_com .comp_common .button_area2 > button{width: 176px; height: 54px; border: 1px solid #e8e8e8; border-radius: 8px; font-size: 16px; color: #000;}
.giftcard_area .comp_com .comp_common .button_area2 > button.pink{width: 176px; height: 54px; border: none; border-radius: 8px; font-size: 16px; color: #fff; background: #f93964; margin-left: 8px;}
.giftcard_area .guide_box{width: 100%; background: #fbfbfb; border: 1px solid #e8e8e8; margin-top: 30px; border-radius: 16px; padding: 23px 0 20px; font-size: 0; display: block;}
.giftcard_area .guide_box > div{display: inline-block; padding-left: 72px; vertical-align: top; width: 392px;}
.giftcard_area .guide_box > div > img{width: 100%;}
.giftcard_area .guide_box > div:first-child{display: inline-block; padding-left: 76px; width: auto;}
.giftcard_area .refill_guide {width: 100%; background: #fff; padding: 60px 0; border-radius: 16px;}
.giftcard_area .refill_guide .inner_box > h4{font-size: 18px; font-weight: 700; text-align: center;}
.giftcard_area .refill_guide .inner_box > p{font-size: 14px; margin-top: 10px; text-align: center;}
.giftcard_area .refill_guide .inner_box > p > i{color: #f93964;}
.virtual_account{width: 480px; margin: 50px auto 0; background: #fbfbfb; border-radius: 8px; padding: 30px 60px;}
.virtual_account > h5{font-size: 20px; text-align: center; font-weight: 700;}
.virtual_account > ul{width: 100%; padding: 16px 0 0; border-top: 1px solid #e8e8e8;}
.virtual_account > ul > li{margin-top: 12px; display: table; width: 100%;}
.virtual_account > ul > li:first-child{margin-top: 0;}
.virtual_account > ul > li > span{display: table-cell; text-align: right; font-size: 14px; font-weight: 700;}
.virtual_account > ul > li > span:first-child{text-align: left; font-weight: 500;}
.virtual_account > ul > li > span > strong{display: block; line-height: 20px;}
.virtual_account > ul > li > span > em{display: block; line-height: 20px; font-weight: 500;}
.virtual_account > ul > li > span > i{color: #f93964;}
.virtual_account > ul.first{padding: 24px 0 16px;  border-top: 0;}
.virtual_account > ul.first > li > span{font-weight: 500;}
.giftcard_area .refill_guide .inner_box .button_area{width: 480px; margin: 40px auto 0; font-size: 0;}
.giftcard_area .refill_guide .inner_box .button_area > button{width: calc(50% - 4px); margin-left: 8px; height: 54px; border-radius: 8px; background: #f93964; color: #fff; font-size: 16px;}
.giftcard_area .refill_guide .inner_box .button_area > button:first-child{margin-left: 0; background: #fff; color: #000; font-size: 16px; border: 1px solid #e8e8e8;}



.pop_button{width: 1280px; margin: 30px auto; text-align: center;}
.pop_button > button{margin: 10px 5px; padding: 15px; border: 1px solid #000; font-size: 13px; background: #fff; border-radius: 8px;}



.popup{width: 100%; position: fixed; top: 0; left: 0; z-index: 100000; height: 100vh; display: none;}
.popup.on{display: block;}
.popup .dim{width: 100%;  background: rgba(0, 0, 0, 0.6); text-align: center; height: 100%; display: flex; justify-content: center; align-items: center;}
.popup .dim .pop_inner{background: #fff; border-radius: 16px; position: relative; text-align: left;}
.popup .dim .pop_inner > button{width: 30px; height: 30px; position: absolute; top: 20px; right: 20px; background: url(/_next/static/media/icon-popup-close.95762238.svg) no-repeat; }
.popup .dim .pop_inner .pop_header{width: 100%;}
.popup .dim .pop_inner .pop_header > h2{font-size: 18px; font-weight: bold;}
.popup .dim .pop_inner .pop_header > ul{width: 100%; text-align: center; font-size: 0; padding-top: 30px;}
.popup .dim .pop_inner .pop_header > ul > li{display: inline-block; margin: 0 5px;}
.popup .dim .pop_inner .pop_header > ul > li > button{ height: 40px; display: inline-block; padding: 0 21px; font-size: 16px; border: 1px solid #e8e8e8; border-radius: 20px; font-weight: 500;}
.popup .dim .pop_inner .pop_header > ul > li > button.on{ border: 0; background: #000; color: #fff; border-radius: 20px;}
.popup .dim .pop_inner .pop_content{width: 100%; padding-top: 16px; }
.popup .dim .pop_inner .pop_content > h3{font-size: 14px; font-weight: 500; padding-bottom: 20px;  }
.popup .dim .pop_inner .pop_content > h3 > i{font-weight: 700; vertical-align: baseline;}
.popup .dim .pop_inner .pop_content > p{font-size: 13px; line-height: 19px; padding-left: 10px; text-indent: -10px; color: #666; margin-top: 6px;}
.popup .dim .pop_inner .pop_content > p.mgt0{margin-top: 0;}
.popup .dim .pop_inner .pop_content .inp_cont{margin-top: 24px; width: 100%;}
.popup .dim .pop_inner .pop_content .inp_cont > p{padding-left: 20px; font-size: 13px; line-height: 16px; background: url(/_next/static/media/icon-icon-noti.b0193e70.svg) left center no-repeat; color: #999;}
.popup .dim .pop_inner .pop_content .inp_cont .text_area{margin-top: 6px; width: 100%;  position: relative; border-radius: 8px; border: 1px solid #e8e8e8; padding: 24px 10px}
.popup .dim .pop_inner .pop_content .inp_cont .text_area > textarea{width: 100%; border: 0; outline: none; height: 248px; overflow-y: auto; padding: 0px 10px; font-size: 14px; line-height: 20px; color: #000; resize:none; }

.popup .dim .pop_inner .pop_content .inp_cont .text_area.focus{border-color: #f93964;}
.popup .dim .pop_inner .pop_content .inp_cont .text_area > textarea::-moz-placeholder{color: #c8c8c8;}
.popup .dim .pop_inner .pop_content .inp_cont .text_area > textarea:-ms-input-placeholder{color: #c8c8c8;}
.popup .dim .pop_inner .pop_content .inp_cont .text_area > textarea::placeholder{color: #c8c8c8;}
.popup .dim .pop_inner .pop_content .cap_ar{margin-top: 12px; text-align: center;}
.popup .dim .pop_inner .btn_area{padding: 30px 0; width: 100%;}
.popup .dim .pop_inner .btn_area > button{width: 100%; height: 54px; border-radius: 8px; background: #f93964; color: #fff; font-size: 16px;}
.popup .dim .pop_inner .btn_area > button:disabled{background: #e5e5e5; color: #c8c8c8;}


.popup .dim .pop_inner.refill{width: 412px; padding: 40px 30px 0;}
.popup .dim .pop_inner.refill .pop_content{height: 463px;}
.popup .dim .pop_inner.refill2{width: 412px; padding: 40px 30px 0;}
.popup .dim .pop_inner.refill2 .pop_content{height: auto;}
.popup .dim .pop_inner.refill3{width: 480px; padding: 40px 30px 0;}
.popup .dim .pop_inner.refill3 .pop_content{height: 463px;}
.popup .dim .pop_inner.pop_df{width: 412px; padding: 40px 30px 30px;}
.popup .dim .pop_inner.pop_XL{width: 660px; padding: 80px 30px 30px;}
.popup .dim .pop_inner.pop_XXL{width: 820px; padding: 80px 30px 30px 60px;}
.popup .dim .pop_inner.pop_XXL2{width: 820px; padding: 40px 30px 30px 30px;}

.popup .dim .pop_inner .comp_ar{width: 100%; margin-top: 6px; border-top: 1px solid #000;}
.popup .dim .pop_inner .comp_ar .top_ar{padding: 30px; text-align: center;}
.popup .dim .pop_inner .comp_ar .top_ar > span{padding-left: 30px; display: inline-block; font-size: 16px;}
.popup .dim .pop_inner .comp_ar .top_ar > span:first-child{padding-left: 0;}
.popup .dim .pop_inner .comp_ar .top_ar > span > i{color: #f93964; font-weight: 700;}
.popup .dim .pop_inner .comp_ar .num_list{ width: 100%; max-height: 366px; border-top: 1px solid #e8e8e8; padding: 4px 0;}
.popup .dim .pop_inner .comp_ar .num_list ul{ width: 100%; padding: 0 16px;}
.popup .dim .pop_inner .comp_ar .num_list ul > li{width: 100%; margin-top: 12px; display: table;}
.popup .dim .pop_inner .comp_ar .num_list ul > li > span{display: table-cell; text-align: left; color: #f93964; font-size: 14px; width: 120px;}
.popup .dim .pop_inner .comp_ar .num_list ul > li > span:first-child{text-align: left; color: #000; width: calc(100% - 120px);}

.popup .dim .pop_inner .pop_content .certification{width: 100%; padding-top: 126px;}
.popup .dim .pop_inner .pop_content .certification .hp_num{width: 100%; display: table;}
.popup .dim .pop_inner .pop_content .certification .hp_num > span{display: table-cell; width: 130px; text-align: right; vertical-align: bottom;}
.popup .dim .pop_inner .pop_content .certification .hp_num > span:first-child{width: calc(100% - 130px); text-align: left;}
.popup .dim .pop_inner .pop_content .certification .hp_num > span > input{width: 100%; height: auto; padding: 10px 0; border-bottom: 1px solid #e8e8e8; font-size: 14px; color: #000;}
.popup .dim .pop_inner .pop_content .certification .hp_num > span > input::-moz-placeholder{color: #000;}
.popup .dim .pop_inner .pop_content .certification .hp_num > span > input:-ms-input-placeholder{color: #000;}
.popup .dim .pop_inner .pop_content .certification .hp_num > span > input::placeholder{color: #000;}
.popup .dim .pop_inner .pop_content .certification .hp_num > span > button{width: 110px; height: 34px; background: #666; border-radius: 4px; color: #fff; font-size: 14px;}
.popup .dim .pop_inner .pop_content .certification .in_num{padding-top: 30px;}
.popup .dim .pop_inner .pop_content .certification .in_num > input{width: 100%; height: auto; padding: 10px 0; border-bottom: 1px solid #e8e8e8; font-size: 14px; color: #000;}
.popup .dim .pop_inner .pop_content .certification .in_num > input::-moz-placeholder{color: #000;}
.popup .dim .pop_inner .pop_content .certification .in_num > input:-ms-input-placeholder{color: #000;}
.popup .dim .pop_inner .pop_content .certification .in_num > input::placeholder{color: #000;}

.popup .dim .pop_inner .pop_content .btn_box{width: 100%; margin-top: 20px;}
.popup .dim .pop_inner .pop_content .btn_box .hp_cert{width: 100%; height: 54px; border-radius: 8px; background: #f93964; color: #fff; font-size: 16px;}
.popup .dim .pop_inner .pop_content .btn_box .kko_cert{width: 100%; height: 54px; border-radius: 8px; background: #ffdd02;  margin-top: 12px;; margin-left: 0;}
.popup .dim .pop_inner .pop_content .btn_box .kko_cert > span{display: inline-block; padding-left: 62px; height: 19px; line-height: 19px; background: url(/_next/static/media/logo-kakaopay.e920145d.svg) left center no-repeat; font-size: 15px; font-weight: 700;}
.popup .dim .pop_inner .pop_content .info_txt{width: 100%; margin-top: 110px;}
.popup .dim .pop_inner .pop_content .info_txt > p{font-size: 13px; line-height: 19px; padding-left: 10px; text-indent: -10px; color: #666; margin-top: 6px;}
.popup .dim .pop_inner .pop_content .info_txt > p:first-child{margin-top: 0;}

.popup .dim .pop_inner .pop_header{width: 100%;}
.popup .dim .pop_inner .pop_header > h1{font-size: 24px; font-weight: bold; text-align: center; line-height: 29px;}
.popup .dim .pop_inner .pop_header > h3{font-size: 18px; font-weight: bold; text-align: left; line-height: 34px; }
.popup .dim .pop_inner .pop_header > p{font-size: 14px; margin-top: 10px; text-align: center;}
.popup .dim .pop_inner .pop_content > h4{font-size: 14px; font-weight: 700; padding-top: 52px; padding-bottom: 2px; margin: 14px auto 0; line-height: 21px; background: url(/_next/static/media/icon_cash.9c6fedf9.png) center top no-repeat; text-align: center; border-bottom: 2px solid #000; width: 185px;}
.popup .dim .pop_inner .pop_content .box_area{width: 454px; margin: 20px auto 0; font-size: 0;}
.popup .dim .pop_inner .pop_content .box_area .left_box{width: 172px; height: 160px; display: inline-block; border-radius: 8px; overflow: hidden; border: 1px solid #e8e8e8;}
.popup .dim .pop_inner .pop_content .box_area .left_box > h5{width: 100%; height: 38px; background: #e8e8e8; text-align: center; line-height: 38px; font-size: 13px;}
.popup .dim .pop_inner .pop_content .box_area .left_box .inner{width: 100%; height: calc(160px - 38px); overflow: hidden; display: flex; justify-content: center; align-items: center; flex-direction: column; }
.popup .dim .pop_inner .pop_content .box_area .left_box .inner > p{font-size: 13px; line-height: 18px; height: 36px; text-align: center; width: 100%; color: #999;}
.popup .dim .pop_inner .pop_content .box_area .left_box .inner > strong{font-size: 14px; line-height: 21px; height: 21px; text-align: center; width: 100%; color: #f93964; display: block; margin-top: 5px;}
.popup .dim .pop_inner .pop_content .box_area .left_box .inner > em{font-size: 11px; line-height: 14px; height: 14px; text-align: center; width: 100%; color: #000; display: block; margin-top: 2px;}
.popup .dim .pop_inner .pop_content .box_area .right_box{width: 270px; height: 160px; display: inline-block; border-radius: 8px; overflow: hidden; border: 1px solid #ffa341; margin-left: 12px;}
.popup .dim .pop_inner .pop_content .box_area .right_box > h5{width: 100%; height: 38px; background: #ffa341; text-align: center; line-height: 38px; font-size: 13px;}
.popup .dim .pop_inner .pop_content .box_area .right_box .inner{width: 100%; height: calc(160px - 38px); overflow: hidden; display: flex; justify-content: center; align-items: center;}
.popup .dim .pop_inner .pop_content .box_area .right_box .inner .in_txt{width: 134px;}
.popup .dim .pop_inner .pop_content .box_area .right_box .inner .in_txt > p{font-size: 13px; line-height: 18px; height: 36px; text-align: center; width: 100%; color: #999;}
.popup .dim .pop_inner .pop_content .box_area .right_box .inner .in_txt > strong{font-size: 14px; line-height: 21px; height: 21px; text-align: center; width: 100%; color: #f93964; display: block; margin-top: 5px;}
.popup .dim .pop_inner .pop_content .box_area .right_box .inner .in_txt > em{font-size: 11px; line-height: 14px; height: 14px; text-align: center; width: 100%; color: #000; display: block; margin-top: 2px;}

.popup .dim .pop_inner .pop_content .box_area .right_box .inner span{width: 1px; display: inline-block; height: 70px; background: #e8e8e8;}
.popup .dim .pop_inner .pop_content .box_area .right_box .inner .in_txt2{width: 135px;}
.popup .dim .pop_inner .pop_content .box_area .right_box .inner .in_txt2 > p{font-size: 13px; line-height: 36px; height: 36px; text-align: center; width: 100%; color: #999;}
.popup .dim .pop_inner .pop_content .box_area .right_box .inner .in_txt2 > strong{font-size: 14px; line-height: 21px; height: 21px; text-align: center; width: 100%; color: #f93964; display: block; margin-top: 5px;}
.popup .dim .pop_inner .pop_content .box_area .right_box .inner .in_txt2 > em{font-size: 11px; line-height: 14px; height: 14px; text-align: center; width: 100%; color: #000; display: block; margin-top: 2px;}

.popup .dim .pop_inner .pop_content > h5{font-size: 14px; font-weight: 700; padding-top: 40px; padding-bottom: 2px; margin: 30px auto 0; line-height: 21px; background: url(/_next/static/media/icon_shop.0d39000f.svg) center top no-repeat; text-align: center; border-bottom: 2px solid #000; width: 161px;}
.popup .dim .pop_inner .pop_content .full_box{width: 454px; margin: 20px auto; border: 1px solid #e8e8e8; border-radius: 8px; background: #fff; text-align: center; font-size:14px; height: 53px; line-height: 53px; color: #f93964;}
.popup .dim .pop_inner.pop_XL .scroll_box{width: 100%; height: 287px; margin-top: 16px;}
.popup .dim .pop_inner.pop_XL .scroll_box .pop_content{padding-top: 0;}
.popup .dim .pop_inner.pop_XL .info_txt2{margin-top: 50px; width: 100%;}
.popup .dim .pop_inner.pop_XL .info_txt2 > h2{font-size: 14px; font-weight: 700; text-align: left; height: 28px; border-bottom: 1px solid #282828;}
.popup .dim .pop_inner.pop_XL .info_txt2 > p.mgt16{margin-top: 16px;}
.popup .dim .pop_inner.pop_XL .info_txt2 > p{font-size: 13px; line-height: 19px; padding-left: 10px; text-indent: -10px; color: #666; margin-top: 6px;}
.popup .dim .pop_inner.pop_XL .info_txt2 > p:first-child{margin-top: 0;}

.popup .dim .pop_inner.use{width: 412px; padding: 70px 30px 0px;}
.popup .dim .pop_inner.use .pop_header > h2{font-size: 16px; font-weight: bold; text-align: left; line-height: 34px; }
.popup .dim .pop_inner.use .pop_header > h2 > span{display: inline-block; width: 80px; height: 34px; vertical-align: middle;}
.popup .dim .pop_inner.use .pop_header > h2 > span > img{width: 100%;}
.popup .dim .pop_inner.use .pop_content{height: 540px; margin-top: 12px;}
.popup .dim .pop_inner.use .pop_content .img_box{width: 328px;}
.popup .dim .pop_inner.use .pop_content .img_box > img{width: 100%;}
.popup .dim .pop_inner.use .pop_content .use_info{width: 328px; padding-top: 40px;}
.popup .dim .pop_inner.use .pop_content .use_info > h4{font-size: 14px; line-height: 21px; color: #222020;  padding-bottom: 2px;}
.popup .dim .pop_inner.use .pop_content .use_info > p{font-size: 12px; line-height: 18px; color: #999; margin-top: 4px; padding-left: 8px; background: url(/_next/static/media/dot_999.9597bd7a.png) left 6px no-repeat; background-size: 2px;}


.popup .dim .pop_inner .scroll_box2{width: 100%; height: 600px; margin-top: 40px; overflow: scroll;}
.popup .dim .pop_inner .scroll_box2 .inner{width: 700px; display: block;}
.popup .dim .pop_inner .scroll_box2 .inner > h3{font-size: 16px; height: 19px; font-weight: 700;}
.popup .dim .pop_inner .scroll_box2 .inner > h4{font-size: 16px; height: 19px; font-weight: 700; margin-top: 50px;}
.popup .dim .pop_inner .scroll_box2 .inner .inner_info{width: 100%; margin-top: 20px;}
.popup .dim .pop_inner .scroll_box2 .inner .inner_info > li{margin-top: 16px; width: 100%; font-size: 0;}
.popup .dim .pop_inner .scroll_box2 .inner .inner_info > li:first-child{margin-top: 0;}
.popup .dim .pop_inner .scroll_box2 .inner .inner_info > li .info_img{display: inline-table; width: 360px; vertical-align: top;}
.popup .dim .pop_inner .scroll_box2 .inner .inner_info > li .info_img > span{display: table-cell; height: 220px; width: 100%; vertical-align: middle; background: #fbfbfb; border-radius: 8px; text-align: center;}
.popup .dim .pop_inner .scroll_box2 .inner .inner_info > li .info_img > span > img{height: 200px; margin: 0 3px; display: inline-block;}
.popup .dim .pop_inner .scroll_box2 .inner .inner_info > li .info_text{width: calc(100% - 360px); display: inline-block; vertical-align: top;}
.popup .dim .pop_inner .scroll_box2 .inner .inner_info > li .info_text .in_step{padding: 20px 0 0 40px; text-align: left;}
.popup .dim .pop_inner .scroll_box2 .inner .inner_info > li .info_text .in_step > i{display: inline-block; width: 24px; height: 24px; border-radius: 4px; background: #c8c8c8; text-align: center; line-height: 25px; font-size: 15px; font-weight: 700; color: #fff;}
.popup .dim .pop_inner .scroll_box2 .inner .inner_info > li .info_text .in_step > p{margin-top: 9px; font-size: 14px; line-height: 20px; display: block;}

/* 캐시교환 */
.cash_swap{width: 100%;}
.cash_swap .swap_top{width: 100%; background: #fff; padding: 60px 0 30px;}
.cash_swap .swap_top h2{font-size: 24px; line-height: 29px; font-weight: 700;}
.cash_swap .swap_top .swap_tab{width: 100%; font-size: 0; text-align: center; padding-top: 40px;}
.cash_swap .swap_top .swap_tab > ul{width: 1280px; margin: 0 auto; text-align: center;}
.cash_swap .swap_top .swap_tab > ul > li{display: inline-block; vertical-align: middle; width: auto; height: 40px; border-radius: 20px; background: #fff; border: 1px solid #e8e8e8; font-size: 16px; line-height: 40px; padding: 0 21px; margin: 0 0 0 10px; cursor: pointer;}
.cash_swap .swap_top .swap_tab > ul > li:first-child{margin-left: 0;}
.cash_swap .swap_top .swap_tab > ul > li.active{background: #000; border-color: #000; color: #fff;}

.swap_sub_tab{width: 100%; border-top: 1px solid #e8e8e8; padding-top: 24px; background: #fff;}
.swap_sub_tab > ul{width: 1280px; margin: 0 auto; text-align: center; font-size: 0;}
.swap_sub_tab > ul > li{display: inline-block; padding-bottom: 19px; vertical-align: top; margin: 0 22px; border-bottom: 3px solid #fff; cursor: pointer;}
.swap_sub_tab > ul > li.active{border-bottom: 3px solid #000;}
.swap_sub_tab > ul > li > i{display: inline-block; width: 17px; height: 21px; background: url(/_next/static/media/icon-new.6cf4ec5b.svg) center 1px no-repeat; margin-right: 6px; vertical-align: middle;}
.swap_sub_tab > ul > li > span{display: inline-block; font-size: 18px; line-height: 21px; font-weight: 700; vertical-align: middle;}
.swap_sub_tab > ul > li > em{display: inline-block; margin-left: 6px; font-size: 12px; line-height: 21px; font-weight: 700; color: #f93964; vertical-align: middle;}
.swap_content{width: 1280px; margin: 0 auto; padding: 60px 0 90px;}
.swap_box_area{width: 720px; margin: 0 auto; position: relative;}
.swap_box_area > h3{font-size: 18px; font-weight: 700; text-align: center; line-height: 21px;}
.swap_box_area > h3 > em{display: inline-block; padding-left: 6px; font-size: 12px; line-height: 21px; color: #f93964; font-weight: 700;}
.swap_box_area > p{text-align: center; font-size: 20px; line-height: 26px; padding-top: 5px;}
.swap_box_area > p > i{color: #f93964; font-size: 20px;}
.swap_box_area > span{text-align: center; font-size: 13px; line-height: 16px; padding-top: 3px; display: block; color: #ff911b;}
.swap_box_common{width: 720px; margin: 0 auto;}
.swap_box_common .inner_box{width: 100%; padding: 30px; margin-top: 30px; background: #fff; border-radius: 16px; font-size: 0;}
.swap_box_common .inner_box.common{padding: 50px 180px 60px;}

.swap_box_common .inner_box .img_box{display: inline-table; width: 210px; height: 421px; background: #fbfbfb; vertical-align: middle;}
.swap_box_common .inner_box .img_box .in_img{display: table-cell; text-align: center; vertical-align: middle; padding: 0 23px;}
.swap_box_common .inner_box .img_box .in_img > img{width: 100%;}
.swap_box_common .inner_box .swap_inner{width: 360px; display: inline-block; margin-left: 60px; vertical-align: top; padding-top: 30px;}
.swap_box_common .inner_box .swap_inner > ul{width: 100%; font-size: 0; text-align: center;}
.swap_box_common .inner_box .swap_inner > ul > li{display: inline-block; padding-left: 34px; height: 24px; line-height: 25px; font-size: 14px; background: url(/_next/static/media/btn-radio-off.f7939a0c.svg) left center no-repeat; margin-left: 30px; cursor: pointer;}
.swap_box_common .inner_box .swap_inner > ul > li:first-child{margin-left: 0;}
.swap_box_common .inner_box .swap_inner > ul > li.active{font-weight: 700; background: url(/_next/static/media/btn-radio-on.a269ae23.svg) left center no-repeat;}
.swap_tab_content{padding-top: 30px; width: 100%;}
.swap_tab_content > p{padding-left: 20px; background: url(/_next/static/media/icon-icon-noti.b0193e70.svg)left top no-repeat; font-size: 13px; line-height: 16px; color: #999;}
.swap_tab_content .inp_area{width: 100%; padding-bottom: 27px; position: relative;}
.swap_tab_content .inp_area .inp_box{width: 100%; background: #fff; border: 1px solid #e8e8e8; border-radius: 8px; padding: 0 20px; margin-top: 10px;}
.swap_tab_content .inp_area .retained{position: absolute; font-size: 13px; line-height: 16px; left: 0; bottom: 0;}

.swap_box_common .info_area{padding-top: 24px;}
.swap_box_common .info_area > p{margin-top: 6px; font-size: 13px; background: url(/_next/static/media/dot_01.6a71e25e.png) left center no-repeat; background-size: 2px; padding-left: 8px; color: #666; line-height: 16px;}
.swap_tab_content .recaptcha{padding-top: 20px; text-align: center;}
.swap_tab_content > button{margin-top: 40px; width: 100%; height: 54px; background: #f93964; color: #fff; font-size: 16px; border-radius: 8px;}
.swap_box_common .button_area{margin-top: 40px;}
.swap_box_common .button_area > button{width: 100%; background: #f93964; border-radius: 8px; font-size: 16px; color: #fff; height: 54px;}
.swap_box_common .button_area > button:disabled{background: #e5e5e5; color: #c8c8c8;}

.swap_box_common .inner_box.card{padding: 60px 0px;}
.swap_box_common .inner_box.card .button_area{margin-top: 60px; padding: 0 180px;}
.swap_box_common .inner_box.card .button_area > button{width: 100%; background: #f93964; border-radius: 8px; font-size: 16px; color: #fff; height: 54px;}
.swap_box_common .inner_box.card .button_area > button:disabled{background: #e5e5e5; color: #c8c8c8;}

.HP_area{width: 100%; margin-top: 40px;}
.HP_area > h5{font-size: 14px; font-weight: 700;}
.HP_area .inp_HP{width: 100%; display: table; margin-top: 10px;}
.HP_area .inp_HP > span{display: table-cell; vertical-align: middle; width: calc(100% - 84px); padding-left: 20px;}
.HP_area .inp_HP > span:first-child{width: 84px; padding-left: 0;}
.HP_area .inp_HP > span > input{width: 100%; height: 39px; border-bottom: 1px solid #e8e8e8; font-size: 14px; color: #000;}
.HP_area .inp_HP > span > input::-moz-placeholder{color: #c8c8c8; font-weight: 500;}
.HP_area .inp_HP > span > input:-ms-input-placeholder{color: #c8c8c8; font-weight: 500;}
.HP_area .inp_HP > span > input::placeholder{color: #c8c8c8; font-weight: 500;}
.select {position: relative;}
.select .option-list {position: absolute; top: 100%; left: 0; width: 100%; overflow: hidden; max-height: 0;}
.select.active .option-list {max-height: none;}

/* 테마 적용하기 */
#theme .select {padding: 10px 0; cursor: pointer; border-bottom: 1px solid #e8e8e8; background: url(/_next/static/media/icon-arrow-small.ecafc2cf.svg)65px center no-repeat;}
#theme .select:hover {color: #333; transition: all .3s;}
#theme .select .text {font-size: 14px; display: none; line-height: 18px;}
#theme .select .placeholder {font-size: 14px; color: #c8c8c8; line-height: 18px;}
#theme .select .option-list {list-style: none; padding: 0 0;}

#theme .select.active .option-list {list-style: none; padding: 16px 0; border: 1px solid #e5e5e5; background: #fff;}
#theme .select .option-list .option {padding: 6px 0 6px 24px; font-size: 14px; color: #000;}
#theme .select .option-list .option:hover {background-color: #f2f2f2;}


.swap_content .comp_com{width: 720px; margin: 0 auto; background: #fff; padding: 60px 0; border-radius: 16px;}
.swap_content .comp_com > h3{font-size: 18px; font-weight: 500; text-align: center; line-height: 21px;}
.swap_content .comp_com > h3 > i{font-weight: 700;}
.swap_content .comp_com > p{text-align: center; font-size: 20px; line-height: 26px; padding-top: 5px;}
.swap_content .comp_com > p > i{color: #f93964; font-size: 20px;}
.swap_content .comp_com .comp_common{width: 360px; margin: 50px auto 0; background: #fbfbfb; border-radius: 8px; padding: 14px 16px;}
.swap_content .comp_com .comp_common > ul{width: 100%; border-top: 1px solid #e8e8e8; padding: 16px;}
.swap_content .comp_com .comp_common > ul:first-child{border-top: 0;}
.swap_content .comp_com .comp_common > ul > li{display: table; width: 100%; padding-top: 12px; }
.swap_content .comp_com .comp_common > ul > li:first-child{padding-top: 0;}
.swap_content .comp_com .comp_common > ul > li > span{display: table-cell; text-align: right; font-size: 14px; color: #222020; font-weight: 700;}
.swap_content .comp_com .comp_common > ul > li > span:first-child{text-align: left; font-weight: 500;}
.swap_content .comp_com .comp_common > ul > li.last > span{font-weight: 700;}
.swap_content .comp_com .comp_common > ul:first-child > li > span{font-weight: 500;}
.swap_content .comp_com .button_area2{width:360px; margin: 34px auto 0; font-size: 0;}
.swap_content .comp_com .button_area2 > button{width: 176px; height: 54px; border: 1px solid #e8e8e8; border-radius: 8px; font-size: 16px; color: #000;}
.swap_content .comp_com .button_area2 > button.pink{width: 176px; height: 54px; border: none; border-radius: 8px; font-size: 16px; color: #fff; background: #f93964; margin-left: 8px;}

.swap_box_area .stap_box{width: 720px; margin-top: 30px; background: #fbfbfb; border: 1px solid#e8e8e8; border-radius: 16px;}
.swap_box_area .stap_box > ul{width: 100%; display: table; height: 82px;}
.swap_box_area .stap_box > ul > li{display: table-cell; vertical-align: middle; text-align: center; padding:0 44px 0 55px; background: url(/_next/static/media/icon_step_left.bcc1d642.png) left center no-repeat; background-size: 9px; font-size: 14px; line-height: 17px;}
.swap_box_area .stap_box > ul > li:first-child{padding: 0 44px; background: none;}


.card_point{width: 440px; margin: 0 auto;}
.card_point > h3{font-size: 18px; font-weight: bold; text-align: center;}
.card_point .info_img{width: 100%; padding-top: 30px;}
.card_point .info_img > img{width: 100%;}

.swap_box_area .stap_box.naver > ul > li{display: table-cell; vertical-align: middle; text-align: center; padding:0 20px 0 30px; background: url(/_next/static/media/icon_step_left.bcc1d642.png) left center no-repeat; background-size: 9px; font-size: 14px; line-height: 17px; letter-spacing: -0.5px;}
.swap_box_area .stap_box.naver > ul > li:first-child{padding: 0 20px 0 32px; background: none;}
.swap_box_area .stap_box.naver > ul > li:last-child{padding: 0 32px 0 30px;}

.swap_box_common .inner_box.change{padding: 60px 120px 60px;}
.swap_box_common .inner_box.change2{padding: 60px 120px 60px; margin-top: 12px;}
.swap_box_common .top_btn_box{width: 100%; text-align: right; margin-top: 30px;}
.swap_box_common .top_btn_box > button{width: 149px; height: 32px; border-radius: 8px; background: #ffa341; color: #fff; font-size: 14px; font-weight: 700;}


.npay_box{width: 100%; padding: 30px; background: #fbfbfb; border-radius: 8px;}
.npay_box > h2{width: 68px;}
.npay_box > h2 > img{width: 100%;}
.npay_box > h3{margin-top: 20px; font-size: 14px; padding-bottom: 10px;}
.npay_box > p{font-size: 12px; line-height: 18px; color: #999;}
.ch_area{margin-top: 10px;}
.swap_box_common .inner_box.npay .button_area{margin-top: 30px;}
.check_box_all {width: auto; position: relative; display: inline-block; cursor: pointer;}
.check_box_all input[type="checkbox"] + label {display: inline-block; background: url(/_next/static/media/button-checkbox-btn-checkbox-large.e7d852bc.svg) no-repeat; background-size: 24px auto; background-position:left top; padding: 0px 0 0px 36px; font-size: 13px;line-height: 25px; color: #000; height: 24px;}
.check_box_all input[type="checkbox"]:checked + label{background: url(/_next/static/media/button-checkbox-large-on.36c1af80.svg) no-repeat; background-size: 24px auto; background-position:left top; font-size: 13px; line-height: 25px;}

.npay_chack{width: 100%;}
.npay_chack > h3{font-size: 18px; font-weight: 700; text-align: center;}
.npay_chack .id_select{margin-top: 50px; width: 100%;}
.npay_chack .id_select > li{width: 100%; margin-top: 12px;}
.npay_chack .id_select > li:first-child{margin-top: 0;}
.npay_chack .id_select > li .radio_box {position: relative; width: 100%; display: block;}
.npay_chack .id_select > li .radio_box:last-child{margin-right: 0;}
.npay_chack .id_select > li .radio_box > input[type="radio"] + label {display: table; width: 100%; height: 64px; padding: 0 60px 0 88px; background: url(/_next/static/media/btn-radio-off.f7939a0c.svg) 24px center no-repeat;  background-size: 24px; border: 1px solid #e8e8e8; border-radius: 12px;}
.npay_chack .id_select > li .radio_box > input[type="radio"]:checked + label {background: url(/_next/static/media/btn-radio-on.a269ae23.svg) 24px center no-repeat;  background-size: 24px; border: 1px solid #f93964; border-radius: 12px;}
.npay_chack .id_select > li .radio_box > input[type="radio"] {position: absolute; left: 0; right: 0; top: 0; bottom: 0;z-index: 10; opacity: 0; width: 100%;height: 100%;letter-spacing: 0; border: none;  background: none; vertical-align: middle; -webkit-appearance: none; -ms-appearance: none;}

.npay_chack .id_select > li .radio_box > label > span{display: table-cell; font-weight: 500; vertical-align: middle; font-size: 14px;}
.npay_chack .id_select > li .radio_box > label > span:first-child{width: 40px; }
.npay_chack .id_select > li .radio_box > label > span:nth-child(2){width: auto; padding-left: 20px;}
.npay_chack .id_select > li .radio_box > label > span:nth-child(3){width: 65px; text-align: center;}
.npay_chack .id_select > li .radio_box > label > span:nth-child(4){width: 100px; text-align: right;}

.swap_inp{width: 360px; margin: 40px auto 0;}
.swap_inp.none_tmg{margin-top: 0 !important;}
.swap_inp .input-group > input{padding-right: 78px;}
.swap_inp .input-group #searchclear{right: 60px;}
.swap_inp .input-group .all_cash{width: 47px; height: 28px; border: 1px solid #e8e8e8; background: #fff; font-size: 13px; position: absolute; right: 0; border-radius: 4px;}
.swap_inp .comp_common{width: 100%; background: #fbfbfb; border-radius: 8px; padding: 14px 16px; margin-top: 20px;}
.swap_inp .comp_common > ul{width: 100%; border-top: 1px solid #e8e8e8; padding: 16px;}
.swap_inp .comp_common > ul:first-child{border-top: 0;}
.swap_inp .comp_common > ul > li{display: table; width: 100%; padding-top: 12px; }
.swap_inp .comp_common > ul > li:first-child{padding-top: 0;}
.swap_inp .comp_common > ul > li > span{display: table-cell; text-align: right; font-size: 16px; color: #222020; font-weight: 700;}
.swap_inp .comp_common > ul > li > span:first-child{text-align: left;}
.swap_inp .comp_common > ul > li > span > i{display: inline-block; padding-left: 4px; font-size: 12px; vertical-align: middle; color: #f93964;}
.swap_inp .comp_common > ul > li.last > span{font-weight: 700;}
.swap_inp .comp_common > ul:first-child > li > span{font-weight: 500; font-size: 14px;}
.swap_inp .ch_area{margin-top: 10px;}

.swap_box_area .stap_box.onestore > ul > li{display: table-cell; vertical-align: middle; text-align: center; padding:0 23px 0 34px; background: url(/_next/static/media/icon_step_left.bcc1d642.png) left center no-repeat; font-size: 14px;  background-size: 9px; line-height: 17px; letter-spacing: -0.5px;}
.swap_box_area .stap_box.onestore > ul > li:first-child{padding: 0 23px 0 60px; background: none;}
.swap_box_area .stap_box.onestore > ul > li:last-child{padding: 0 60px 0 34px;}
.onestore_box{width: 100%; padding: 30px; background: #fbfbfb; border-radius: 8px;}
.onestore_box > h2{width: 121px;}
.onestore_box > h2 > img{width: 100%;}
.onestore_box > h3{margin-top: 20px; font-size: 14px; padding-bottom: 10px;}
.onestore_box > p{font-size: 12px; line-height: 18px; color: #999;}

.swap_box_area .stap_box.happypoint > ul > li{display: table-cell; vertical-align: middle; text-align: center; padding:0 23px 0 34px; background: url(/_next/static/media/icon_step_left.bcc1d642.png) left center no-repeat; font-size: 14px; background-size: 9px;  line-height: 17px; letter-spacing: -0.5px;}
.swap_box_area .stap_box.happypoint > ul > li:first-child{padding: 0 23px 0 60px; background: none;}
.swap_box_area .stap_box.happypoint > ul > li:last-child{padding: 0 60px 0 34px;}

.happypoint_box{width: 100%; padding: 30px; background: #fbfbfb; border-radius: 8px;}
.happypoint_box > h2{width: 66px;}
.happypoint_box > h2 > img{width: 100%;}
.happypoint_box > h3{margin-top: 20px; font-size: 14px; padding-bottom: 10px;}
.happypoint_box > p{font-size: 12px; line-height: 18px; color: #999;}

.ok_tab{width: 100%; display: table;}
.ok_tab > li{display: table-cell; width: 50%; height: 35px; vertical-align: top; font-size: 14px; text-align: center; border-bottom: 3px solid #e8e8e8; cursor: pointer;}
.ok_tab > li.active{border-color: #f93964; color: #f93964; font-weight: bold;}

.ok_content{margin-top: 50px;}
.ok_content > p{margin-top: 40px; font-size: 16px; color: #666; text-align: center;}
.ok_content .button_area{margin-top: 20px; font-size: 0;}
.ok_content .button_area .btn_hp{width: calc(50% - 4px); height: 54px; background: #f93964; color: #fff; font-size: 16px;}
.ok_content .button_area .btn_kko{width: calc(50% - 4px); margin-left: 8px; height: 54px; background: #ffdd02; color: #fff; font-size: 16px;}
.ok_content .button_area .btn_kko > span{padding-left: 62px; background: url(/_next/static/media/logo-kakaopay.e920145d.svg) left center no-repeat; background-size: auto 19px; display: inline-block; line-height: 20px; height: 19px; font-size: 15px; font-weight: 700; color: #000;}
.ok_box{width: 100%; padding: 30px; background: #fbfbfb; border-radius: 8px;}
.ok_box > h3{font-size: 14px; padding-bottom: 10px;}
.ok_box > h3 > i{color: #f93964; margin-right: 5px;}
.ok_box > p{font-size: 12px; line-height: 18px; color: #999;}

.OK_chack{width: 480px; margin: 0 auto; padding: 30px 60px 40px; border: 1px solid #e8e8e8; border-radius: 8px;}
.OK_chack > ul{width: 100%;}
.OK_chack > ul > li{width: 100%; font-size: 0;}
.OK_chack > ul > li.name > span{width: 311px; display: inline-block; font-size: 14px; line-height: 39px; font-weight: 700;}
.OK_chack > ul > li.name > span:first-child{width: calc(100% - 311px);}
.OK_chack > ul > li input{width: 100%; height: 39px; border-bottom: 1px solid #e8e8e8; line-height: 39px; font-size: 14px; color: #000;}
.OK_chack > ul > li input::-moz-placeholder{color: #c8c8c8; font-size: 14px;}
.OK_chack > ul > li input:-ms-input-placeholder{color: #c8c8c8; font-size: 14px;}
.OK_chack > ul > li input::placeholder{color: #c8c8c8; font-size: 14px;}
.OK_chack > ul > li input:disabled{color: #666; background: none; font-size: 14px;}
.OK_chack > ul > li.cash{margin-top: 30px;}
.OK_chack > ul > li.cash > h5{font-size: 14px; font-weight: 700; width: 100%; }
.OK_chack > ul > li.cash .in_inp{margin-top: 7px; font-size: 0;}
.OK_chack > ul > li.cash .in_inp > span{width: 60px; display: inline-block; vertical-align: bottom;}
.OK_chack > ul > li.cash .in_inp > span:first-child{width: calc(100% - 60px); padding-right: 20px; display: inline-block;}
.OK_chack > ul > li.cash .in_inp > span > button{width: 60px; height: 34px; background: #666; font-size: 14px; color: #fff; border-radius: 4px;}
.OK_chack > ul > li.cash .in_inp > span > button:disabled{background: #e5e5e5;}

.OK_chack > ul > li.password{margin-top: 30px;}
.OK_chack > ul > li.password > h5{font-size: 14px; font-weight: 700; width: 100%; }
.OK_chack > ul > li.password > span{display: block; margin-top: 7px;}
.OK_chack > p{padding-left: 20px; margin-top: 12px; background: url(/_next/static/media/bul_i@2x.a4212f67.png) left bottom no-repeat; background-size: 16px; line-height: 16px; height: 17px; font-size: 14px; color: #333;}
.OK_chack > p > a{text-decoration: underline;}
.comp_sum{width: 360px; margin: 24px auto 0; background: #fbfbfb;}
.comp_sum > ul{width: 100%; padding: 30px 20px;}
.comp_sum > ul > li{display: table; width: 100%;}
.comp_sum > ul > li > span{display: table-cell; text-align: right; font-size: 16px; color: #000; font-weight: 700;}
.comp_sum > ul > li > span:first-child{text-align: left;}



.use_header{width: 100%; background: #fff; position: fixed; top: 140px; z-index: 99;}
.use_header .search_top{width: 1280px; margin: 0 auto; }
.use_header .search_top > h2{font-size: 28px; padding-top: 60px; line-height: 29px; font-weight: 700; text-align: center;}
.use_header .search_top > h2 > i{font-size: 28px; padding: 0 5px; font-weight: 700;}
.use_header .search_top .search_ar{width: 360px; margin: 20px auto 0;}
.use_header .search_top .search_ar .input-sh{height: 39px; position: relative; border-bottom: 1px solid #e8e8e8; font-size: 0;}
.use_header .search_top .search_ar .input-sh > input{width: calc(100% - 46px); height: 37px; font-size: 14px; vertical-align: middle; border: 0; padding-left: 14px; padding-right: 30px; box-sizing: border-box;}
.use_header .search_top .search_ar .input-sh > input::-moz-placeholder{color: #c8c8c8;}
.use_header .search_top .search_ar .input-sh > input:-ms-input-placeholder{color: #c8c8c8;}
.use_header .search_top .search_ar .input-sh > input::placeholder{color: #c8c8c8;}
.use_header .search_top .search_ar .input-sh .btn_sh{width: 18px; height: 18px; vertical-align: middle; margin-left: 14px; background: url(/_next/static/media/search.f40e3ee1.svg) center center no-repeat;}
.use_header .search_top .search_ar .input-sh .btn_clear{position: absolute; right: 50px; top: 0; bottom: 0; width: 16px; height: 16px; margin: auto; font-size: 12px; cursor: pointer; background: url(/_next/static/media/button-btn-cancel.ced58994.svg) no-repeat;}

.use_header .search_top .use_tab{width: 100%; font-size: 0; text-align: center; padding: 60px 0 30px;}
.use_header .search_top .use_tab > ul{width: 1280px; margin: 0 auto; text-align: center;}
.use_header .search_top .use_tab > ul > li{display: inline-block; vertical-align: middle; width: auto; height: 40px; border-radius: 20px; background: #fff; border: 1px solid #e8e8e8; font-size: 16px; line-height: 40px; padding: 0 21px; margin: 0 0 0 10px; cursor: pointer;}
.use_header .search_top .use_tab > ul > li:first-child{margin-left: 0;}
.use_header .search_top .use_tab > ul > li.active{background: #000; border-color: #000; color: #fff;}
.use_header .banner_ar{height: 180px; text-align: center; background: #f8f8f8;}
.use_header .banner_ar > img{height: 100%;}
.use_header .cate_ar{width: 100%; border-bottom: 1px solid #e8e8e8; }
.use_header .cate_ar .w_inner{width: 1058px; margin: 0 auto;}
.use_header .cate_ar .w_inner > ul{padding: 20px 0 21px; width: 100%; border-bottom: 1px dashed #c8c8c8; font-size: 0; text-align: left;}
.use_header .cate_ar .w_inner > ul > li{padding: 0 12px; height: 34px; line-height: 34px; margin-left: 6px; cursor: pointer; font-size: 14px; display: inline-block; border-radius: 4px;}
.use_header .cate_ar .w_inner > ul > li:first-child{margin-left: 0;}
.use_header .cate_ar .w_inner > ul > li.active{background: #f93964; color: #fff;}
.use_header .cate_ar .w_inner .radio_ar{padding: 20px 0; width: 100%; text-align: left; font-size: 0;}
.use_header .cate_ar .w_inner .radio_ar .radio_s{position: relative; display: inline-block; margin-right: 20px;}
.use_header .cate_ar .w_inner .radio_ar .radio_s > input[type="checkbox"] + label {display: table; width: 100%; height: 16px; padding: 0 0 0 22px; background: url(/_next/static/media/checkbox-s_off.4bc0d714.svg) left center no-repeat;  background-size: 16px;  font-size: 13px; line-height: 16px;}
.use_header .cate_ar .w_inner .radio_ar .radio_s > input[type="checkbox"]:checked + label {background: url(/_next/static/media/checkbox-s_on.1d487b69.svg) left center no-repeat;}
.use_header .cate_ar .w_inner .radio_ar .radio_s > input[type="checkbox"] {position: absolute; left: 0; right: 0; top: 0; bottom: 0;z-index: 10; opacity: 0; width: 100%;height: 100%;letter-spacing: 0; border: none;  background: none; vertical-align: middle; -webkit-appearance: none; -ms-appearance: none;}


.Use_wrap{width: 100%; padding-top: 591px; background: #fff; }
.Use_wrap.down{padding-top: 264px;}
.Use_wrap .inner_w{width: 1058px; margin: 0 auto; padding: 16px 0 90px;}
.Use_wrap .inner_w > ul{width: 100%; font-size: 0;}
.Use_wrap .inner_w > ul > li{width: 92px; margin:44px 0 0 46px; text-align: center; display: inline-block; vertical-align: top; cursor: pointer;}
.Use_wrap .inner_w > ul > li:first-child{margin-left: 0;}
.Use_wrap .inner_w > ul > li:nth-child(8n+1){margin-left: 0;}
.Use_wrap .inner_w > ul > li .logo_box{width: 100%; height: 74px; display: table; position: relative;}
.Use_wrap .inner_w > ul > li .logo_box .img_mid{width: 100%; display: table-cell; vertical-align: middle; text-align: center; }
.Use_wrap .inner_w > ul > li .logo_box .img_mid > img{width: 100%;}
.Use_wrap .inner_w > ul > li .logo_box > i{width: 9px; height: 9px; background: url(/_next/static/media/icon-new-window@2x.a4484bf7.png) no-repeat; background-size: 9px; display: inline-block; position: absolute; top: 0; right: 0;}
.Use_wrap .inner_w > ul > li p{font-size: 13px; color: #999; text-align: center; margin-top: 8px; height: 32px; line-height: 16px;}



.search_header{width: 100%; background: #fff; position: fixed; top: 140px; z-index: 99;}
.search_header .search_top{width: 1280px; margin: 0 auto; padding-bottom: 60px; }
.search_header .search_top > h2{font-size: 28px; padding-top: 60px; line-height: 29px;  font-weight: 700; text-align: center;}
.search_header .search_top .search_ar{width: 360px; margin: 20px auto 0;}
.search_header .search_top .search_ar .input-sh{height: 39px; position: relative; border-bottom: 1px solid #e8e8e8; font-size: 0;}
.search_header .search_top .search_ar .input-sh > input{width: calc(100% - 46px); height: 37px; font-size: 14px; vertical-align: middle; border: 0; padding-left: 14px;}
.search_header .search_top .search_ar .input-sh > input::-moz-placeholder{color: #c8c8c8;}
.search_header .search_top .search_ar .input-sh > input:-ms-input-placeholder{color: #c8c8c8;}
.search_header .search_top .search_ar .input-sh > input::placeholder{color: #c8c8c8;}
.search_header .search_top .search_ar .input-sh .btn_sh{width: 18px; height: 18px; vertical-align: middle; margin-left: 14px; background: url(/_next/static/media/search.f40e3ee1.svg) center center no-repeat;}
.search_header .search_top .search_ar .input-sh .btn_clear{position: absolute; right: 51px; top: 0; bottom: 0; width: 16px; height: 16px; margin: auto; font-size: 12px; cursor: pointer; background: url(/_next/static/media/button-btn-cancel.ced58994.svg) no-repeat;}
.search_header .cate_ar{width: 100%; border-bottom: 1px solid #e8e8e8; border-top: 1px solid #e8e8e8; }
.search_header .cate_ar .w_inner{width: 1058px; margin: 0 auto;}
.search_header .cate_ar .w_inner .radio_ar{padding: 20px 0; width: 100%; text-align: left; font-size: 0;}
.search_header .cate_ar .w_inner .radio_ar .radio_s{position: relative; display: inline-block; margin-right: 20px;}
.search_header .cate_ar .w_inner .radio_ar .radio_s > input[type="radio"] + label {display: table; width: 100%; height: 16px; padding: 0 0 0 22px; background: url(/_next/static/media/checkbox-s_off.4bc0d714.svg) left center no-repeat;  background-size: 16px;  font-size: 13px; line-height: 16px;}
.search_header .cate_ar .w_inner .radio_ar .radio_s > input[type="radio"]:checked + label {background: url(/_next/static/media/checkbox-s_on.1d487b69.svg) left center no-repeat;}
.search_header .cate_ar .w_inner .radio_ar .radio_s > input[type="radio"] {position: absolute; left: 0; right: 0; top: 0; bottom: 0;z-index: 10; opacity: 0; width: 100%;height: 100%;letter-spacing: 0; border: none;  background: none; vertical-align: middle; -webkit-appearance: none; -ms-appearance: none;}
.search_wrap{width: 100%; padding-top: 266px; background: #fff; }
.search_wrap .inner_w{width: 1058px; margin: 0 auto; padding: 0 0 90px;}
.search_wrap .inner_w .sub_deb{width: 100%; padding-top: 60px;}
.search_wrap .inner_w .sub_deb > h5{display: inline-block; padding-bottom: 23px; border-bottom: 3px solid #000; font-size: 18px; font-weight: 700;}
.search_wrap .inner_w .sub_deb > ul{width: 100%; font-size: 0; padding-top: 6px;}
.search_wrap .inner_w .sub_deb > ul > li{width: 92px; margin:44px 0 0 46px; text-align: center; display: inline-block; vertical-align: top; cursor: pointer;}
.search_wrap .inner_w .sub_deb > ul > li:first-child{margin-left: 0;}
.search_wrap .inner_w .sub_deb > ul > li:nth-child(8n+1){margin-left: 0;}
.search_wrap .inner_w .sub_deb > ul > li .logo_box{width: 100%; height: 74px; display: table; position: relative;}
.search_wrap .inner_w .sub_deb > ul > li .logo_box .img_mid{width: 100%; display: table-cell; vertical-align: middle; text-align: center; }
.search_wrap .inner_w .sub_deb > ul > li .logo_box .img_mid > img{width: 100%;}
.search_wrap .inner_w .sub_deb > ul > li .logo_box > i{width: 9px; height: 9px; background: url(/_next/static/media/icon-new-window@2x.a4484bf7.png) no-repeat; background-size: 9px; display: inline-block; position: absolute; top: 0; right: 0;}
.search_wrap .inner_w .sub_deb > ul > li p{font-size: 13px; color: #999; text-align: center; margin-top: 8px; height: 32px; line-height: 16px;}
.none_data{width: 100%; padding: 210px;}
.none_data > p{padding-top: 109px; background: url(/_next/static/media/empty.b741d0b8.svg) center top no-repeat; font-size: 13px; text-align: center;}



.Purchase_Present{width: 100%;}
.Purchase_Present .PurchasePresent_top{width: 100%; background: #fff; padding: 60px 0 30px;}
.Purchase_Present .PurchasePresent_top .w_inner{width: 1280px; margin: 0 auto;}
.Purchase_Present .PurchasePresent_top h2{font-size: 28px; line-height: 29px; font-weight: 700;}
.Purchase_Present .PurchasePresent_top h2 > i{font-size: 28px; font-weight: 700; padding: 0 5px;}
.Purchase_Present .PurchasePresent_top .common_tab{width: 100%; font-size: 0; text-align: center; padding-top: 40px;}
.Purchase_Present .PurchasePresent_top .common_tab > ul{width: 1280px; margin: 0 auto; text-align: center;}
.Purchase_Present .PurchasePresent_top .common_tab > ul > li{display: inline-block; vertical-align: middle; width: auto; height: 40px; border-radius: 20px; background: #fff; border: 1px solid #e8e8e8; font-size: 16px; line-height: 40px; padding: 0 21px; margin: 0 0 0 10px; cursor: pointer;}
.Purchase_Present .PurchasePresent_top .common_tab > ul > li > i{font-size: 16px; padding: 0 4px;}
.Purchase_Present .PurchasePresent_top .common_tab > ul > li:first-child{margin-left: 0;}
.Purchase_Present .PurchasePresent_top .common_tab > ul > li.active{background: #000; border-color: #000; color: #fff;}
.Purchase_Present .PurchasePresent_top .common_tab > ul > li.active > i{font-size: 16px; padding: 0 4px; color: #fff;}
.Purchase_Present_content{width: 1280px; margin: 0 auto; padding: 60px 0 90px;}

.Purchase_Present_section{width: 720px; margin: 0 auto; position: relative;}
.Purchase_Present_section > h3{font-size: 18px; font-weight: 700; text-align: center; line-height: 21px;}
.Purchase_Present_section > h3 > em{display: inline-block; padding-left: 6px; font-size: 12px; line-height: 21px; color: #f93964; font-weight: 700;}
.Purchase_Present_section > h3 > i{font-size: 18px; padding: 0 4px; font-weight: 700;}
.Purchase_Present_section > span{display: block; text-align: center; font-size: 13px; line-height: 16px; padding-top: 3px; color: #ff911b;}
.Purchase_Present_section > p{text-align: center; font-size: 14px; line-height: 17px; padding-top: 10px;}
.Purchase_Present_section > p > i{color: #f93964; font-size: 14px; }


.pap_area{width: 720px; background: #fff; margin: 30px auto 0; border-radius: 16px; padding: 60px 120px; }
.pap_area2{width: 720px; background: #fff; margin: 30px auto 0; border-radius: 16px; padding: 60px 180px; }
.pnp_tab{width: 100%; background: #fbfbfb; border-radius: 16px; border: 1px solid #e8e8e8; font-size: 0; padding: 40px 50px;}
.pnp_tab > p{display: inline-block; vertical-align: middle; text-align: center; font-size: 16px; line-height: 19px;}
.pnp_tab > ul{display: inline-block; vertical-align: middle; width: 224px; margin-left: 35px;}
.pnp_tab > ul > li{width: 116px; margin-left: 8px; height: 34px; background: #fff; border-radius: 4px; border: 1px solid #e5e5e5; text-align: center; display: inline-block; cursor: pointer;}
.pnp_tab > ul > li > span{ padding-left: 19px; line-height: 32px; font-size: 14px; color: #333; background: url(/_next/static/media/icons_check_off.01c34297.svg) left center no-repeat;}
.pnp_tab > ul > li.active{background: #f93964; border: 1px solid #f93964;}
.pnp_tab > ul > li.active > span{color: #fff; background: url(/_next/static/media/icons_check_on.545ef9f6.svg) left center no-repeat;}
.pnp_tab > ul > li:first-child{width: 100px; margin-left: 0;}

.pnp_content{width: 100%; margin-top: 40px;}
.pnp_content .stap{width: 100%; margin-top: 50px; position: relative;}
.pnp_content .stap.first{margin-top: 0;}
.pnp_content .stap .title{width: 100%; font-size: 0;}
.pnp_content .stap .title > i{width: 22px; height: 22px; background: #000; border-radius: 4px; display: inline-block; font-size: 14px; line-height: 23px; text-align: center; color: #fff; font-weight: 700; vertical-align: middle;}
.pnp_content .stap .title > h4{display: inline-block; margin-left: 8px; font-size: 14px; font-weight: 700; vertical-align: middle; line-height: 23px; height: 22px;}
.pnp_content .stap > ul.cash_sel{margin-top: 20px; width: 100%; position: relative; height: 50px;}
.pnp_content .stap > ul.cash_sel > li{display: inline-block; position: absolute; width: calc(20% + 1px); border: 1px solid #e8e8e8; top: 0; text-align: center; line-height: 48px; height: 50px; font-size: 14px; z-index: 1; cursor: pointer;}
.pnp_content .stap > ul.cash_sel > li.active{z-index: 2; border: 1px solid #f93964; color: #f93964; font-weight: 700;}
.pnp_content .stap > ul.cash_sel > li:first-child{border-bottom-left-radius: 8px; border-top-left-radius: 8px; left: 0;}
.pnp_content .stap > ul.cash_sel > li:nth-child(2){left: 20%;}
.pnp_content .stap > ul.cash_sel > li:nth-child(3){left: 40%;}
.pnp_content .stap > ul.cash_sel > li:nth-child(4){left: 60%;}
.pnp_content .stap > ul.cash_sel > li:nth-child(5){left: 80%; border-bottom-right-radius: 8px; border-top-right-radius: 8px;}

.pnp_content .stap > ul.cash_sel2{margin-top: 20px; width: 100%; position: relative; height: 50px;}
.pnp_content .stap > ul.cash_sel2 > li{display: inline-block; position: absolute; width: calc(16.6% + 1px); border: 1px solid #e8e8e8; top: 0; text-align: center; line-height: 48px; height: 50px; font-size: 14px; z-index: 1; cursor: pointer;}
.pnp_content .stap > ul.cash_sel2 > li.active{z-index: 2; border: 1px solid #f93964; color: #f93964; font-weight: 700;}
.pnp_content .stap > ul.cash_sel2 > li:first-child{width: calc(16.8% + 1px); border-bottom-left-radius: 8px; border-top-left-radius: 8px; left: 0;}
.pnp_content .stap > ul.cash_sel2 > li:nth-child(2){left: 16.8%;}
.pnp_content .stap > ul.cash_sel2 > li:nth-child(3){left: 33.4%;}
.pnp_content .stap > ul.cash_sel2 > li:nth-child(4){left: 50%;}
.pnp_content .stap > ul.cash_sel2 > li:nth-child(5){left: 66.6%;}
.pnp_content .stap > ul.cash_sel2 > li:nth-child(6){width: calc(16.8% + 1px); left: 83.2%; border-bottom-right-radius: 8px; border-top-right-radius: 8px;}



.pnp_content .stap .count_ar{display: inline-block; position: absolute; right: 0; top: 0; font-size: 0;}
.pnp_content .stap .count_ar > button{width: 28px; height: 28px; vertical-align: middle;}
.pnp_content .stap .count_ar > input{display: inline-block; vertical-align: middle; width: 47px; height: 28px; font-size: 14px; font-weight: 700; text-align: center; }
.pnp_content .stap .receiver_input{width: 100%; padding-top: 30px;}
.pnp_content .stap .receiver_input .inp_control{width: 100%; display: table;}
.pnp_content .stap .receiver_input .inp_control > p{display: table-cell; text-align: right;}
.pnp_content .stap .receiver_input .inp_control > p:first-child{text-align: left; font-size: 14px; color: #f93964;}
.pnp_content .stap .receiver_input .inp_control > p > button{padding: 0; margin: 0; border: 0; background: 0;}
.pnp_content .stap .receiver_input .inp_control > p > button > span{font-size: 14px; line-height: 14px; padding-left: 17px; background: url(/_next/static/media/reset.eb4c7085.svg) left center no-repeat; }
.pnp_content .stap .receiver_input .inp_ar{width: 100%; border: 1px solid #e8e8e8; border-radius: 8px; font-size: 0; margin-top: 12px;}
.pnp_content .stap .receiver_input .inp_ar.focus{border-color: #f93964;}
.pnp_content .stap .receiver_input .inp_ar .w_lg{display: inline-block; padding: 0 20px 0; width: calc(100% - 141px); vertical-align: middle;}
.pnp_content .stap .receiver_input .inp_ar > i{display: inline-block; width: 1px; height: 28px; vertical-align: middle; background: #e8e8e8;}
.pnp_content .stap .receiver_input .inp_ar .w_sm{display: inline-block; padding: 0 20px 0; width: 140px; vertical-align: middle;}
.pnp_content .stap .receiver_input > button{width: 100%; background: #ededed; border-radius: 8px; font-size: 0; margin-top: 12px; height: 64px;}
.pnp_content .stap .receiver_input > button > span{font-size: 13px; line-height: 18px; display: inline-block; padding-left: 24px; background: url(/_next/static/media/icon-add-plus.b6250e90.svg)left center no-repeat;}


.pnp_content .stap .massge_box{border: 1px solid #e8e8e8; border-radius: 8px; padding: 10px 10px 10px 0; overflow: hidden; margin-top: 20px;}
.pnp_content .stap .massge_box > textarea{width: 100%; border: 0;  outline: none; height: 44px; overflow-y: auto; padding: 12px 20px; font-size: 14px; line-height: 20px; color: #000; resize:none; }
.pnp_content .stap .massge_box > textarea::-moz-placeholder{color: #c8c8c8;}
.pnp_content .stap .massge_box > textarea:-ms-input-placeholder{color: #c8c8c8;}
.pnp_content .stap .massge_box > textarea::placeholder{color: #c8c8c8;}
.pnp_content .stap .byte{text-align: right; margin-top: 8px; color: #999; font-size: 13px;}
.pnp_content .stap .byte > b{color: #000;}


.PaymentAmount{width: 100%; height: 80px; display: table; padding: 0 20px; background: #fbfbfb; border-radius: 8px; margin-top: 50px;}
.PaymentAmount > p{display: table-cell; text-align: right; vertical-align: middle; font-size: 16px; font-weight: 700;}
.PaymentAmount > p:first-child{text-align: left;}

.PaymentMethod{width: 100%; padding: 30px 20px; background: #fbfbfb; border-radius: 8px; margin-top: 12px;}
.PaymentMethod > h5{font-size: 16px; font-weight: 700;}
.PaymentMethod > ul.Method{width: 100%; height: 50px; position: relative; font-size: 0; margin-top: 16px;}
.PaymentMethod > ul.Method > li{display: inline-block; height: 50px; line-height: 50px; width: calc(33.3% + 1px); border: 1px solid #e8e8e8; font-size: 14px; text-align: center; z-index: 1; position: absolute; cursor: pointer; background: #fff;}
.PaymentMethod > ul.Method > li:first-child{width: calc(33.4% + 1px); left: 0; border-top-left-radius: 8px; border-bottom-left-radius: 8px;}
.PaymentMethod > ul.Method > li:nth-child(2){left: 33.4%;}
.PaymentMethod > ul.Method > li:nth-child(3){left: 66.7%; border-top-right-radius: 8px; border-bottom-right-radius: 8px;}
.PaymentMethod > ul.Method > li.active{border-color: #f93964; color: #f93964; font-weight: 700; z-index: 2;}
.PaymentMethod > p{margin-top: 10px; line-height: 18px; font-size: 12px;}
.PaymentMethod .retained{display: none;}
.PaymentMethod .retained.on{display: block;}
.PaymentMethod > p > i{color: #f93964;}

.PaymentMethod2{width: 100%; padding: 30px 20px; background: #fbfbfb; border-radius: 8px; margin-top: 12px;}
.PaymentMethod2 > h5{font-size: 16px; font-weight: 700;}
.PaymentMethod2 > ul.Method2{width: 100%; height: 50px; position: relative; font-size: 0; margin-top: 16px;}
.PaymentMethod2 > ul.Method2 > li{display: inline-block; height: 50px; line-height: 50px; width: calc(33.3% + 1px); border: 1px solid #e8e8e8; font-size: 14px; text-align: center; z-index: 1; position: absolute; cursor: pointer; background: #fff;}
.PaymentMethod2 > ul.Method2 > li:first-child{width: calc(33.4% + 1px); left: 0; border-top-left-radius: 8px; border-bottom-left-radius: 8px;}
.PaymentMethod2 > ul.Method2 > li:nth-child(2){left: 33.4%;}
.PaymentMethod2 > ul.Method2 > li:nth-child(3){left: 66.7%; border-top-right-radius: 8px; border-bottom-right-radius: 8px;}
.PaymentMethod2 > ul.Method2 > li.active{border-color: #f93964; color: #f93964; font-weight: 700; z-index: 2;}
.PaymentMethod2 > p{margin-top: 10px; line-height: 18px; font-size: 12px;}
.PaymentMethod2 .retained2{display: none;}
.PaymentMethod2 .retained2.on{display: block;}
.PaymentMethod2 > p > i{color: #f93964;}

.PurchaseConfirmation{width: 100%; padding: 30px 20px; background: #fbfbfb; border-radius: 8px; margin-top: 12px;}
.PurchaseConfirmation > h5{font-size: 14px; }
.PurchaseConfirmation > h5 > i{color: #f93964; }
.PurchaseConfirmation > p{margin-top: 10px; line-height: 18px; font-size: 12px; color: #999;}

.agreement_check{margin-top: 10px;}

.pnp_content .button_area{margin-top: 50px;}
.pnp_content .button_area > button{width: 100%; background: #f93964; border-radius: 8px; font-size: 16px; color: #fff; height: 54px;}
.pnp_content .button_area > button:disabled{background: #e5e5e5; color: #c8c8c8;}

.Purchase_Present_section .info_area{padding-top: 24px;}
.Purchase_Present_section .info_area > p{margin-top: 6px; font-size: 13px; background: url(/_next/static/media/dot_01.6a71e25e.png) left center no-repeat; background-size: 2px; padding-left: 8px; color: #666; line-height: 16px;}

.Purchase_Present_section .banner_btm{width: 100%; border: 1px solid #f2d9df; background: #ffe9ee; border-radius: 16px; overflow: hidden; margin-top: 40px; font-size: 0; padding: 22px 60px 22px 77px;}
.Purchase_Present_section .banner_btm > div{display: inline-block; vertical-align: middle;}
.Purchase_Present_section .banner_btm > div > img{width: 100%;}
.Purchase_Present_section .banner_btm > div.banner_text{width: 190px;}
.Purchase_Present_section .banner_btm > div.banner_img{width: 320px; margin-left: 71px;}



.pap_area > h2{font-size: 20px; font-weight: 700; line-height: 26px; text-align: center;}
.pap_area > h2 > i{font-size: 20px; padding: 0 4px; font-weight: 700;}
.pap_area > ul.list{width: 100%; border-top: 1px solid #000; margin-top: 24px; padding: 16px 0;}
.pap_area > ul.list > li{width: 100%; display: table; margin-top: 12px; padding: 0 60px;}
.pap_area > ul.list > li > span{ display: table-cell; text-align: right; font-size: 14px; vertical-align: top;}
.pap_area > ul.list > li > span:first-child{text-align: left;}
.pap_area > ul.list > li:first-child{margin-top: 0;}
.pap_area > ul.list > li.sum{margin-top: 16px; padding-top: 16px; border-top: 1px solid #e8e8e8;}
.pap_area > ul.list > li.sum > span{font-weight: 700;}
.pap_area > ul.list > li.sum > span > em{font-weight: 500; padding-right: 8px; vertical-align: middle; display: inline-block;}
.pap_area > ul.list > li.sum > span > strong{vertical-align: middle; font-weight: 700;}
.pap_area > ul.list > li.sum > span > i{display: block; text-align: right; font-size: 12px; line-height: 18px; margin-top: 4px; color: #f93964;}
.pap_area .list_box{margin-top: 24px; width: 100%; background: #fbfbfb; border-radius: 8px; max-height: 455px; padding: 30px 10px 30px 30px;}
.pap_area .list_box ul{width: 100%; padding-right: 20px;}
.pap_area .list_box ul > li{width: 100%; padding: 12px 0 13px; border-top: 1px solid #e8e8e8; font-size: 0;}
.pap_area .list_box ul > li:first-child{padding-top: 0; border: 0;}
.pap_area .list_box ul > li > span{font-size: 13px; display: inline-block;}
.pap_area .list_box ul > li > span:first-child{width: 46px; text-align: center; color: #999;}
.pap_area .list_box ul > li > span:nth-child(2){width: 96px; padding: 0 19px; text-align: left;}
.pap_area .list_box ul > li > span:nth-child(3){width: calc(100% - 253px); text-align: left;}
.pap_area .list_box ul > li > span:nth-child(4){width: 60px; padding: 0 10px 0 0; text-align: right;}
.pap_area .list_box ul > li > span:nth-child(5){width: 51px; text-align: center;}
.pap_area .button_area2{width: 100%; margin-top: 50px; font-size: 0;}
.pap_area .button_area2 > button{width: calc(50% - 4px); height: 54px; border: 1px solid #e8e8e8; border-radius: 8px; font-size: 16px; color: #000;}
.pap_area .button_area2 > button.pink{border: none; border-radius: 8px; font-size: 16px; color: #fff; background: #f93964; margin-left: 8px;}

.pnp_content .common_type{width: 100%;}
.pnp_content .common_type .cash_inp{height: 52px; padding: 0 4px; width: 100%; position: relative;}
.pnp_content .common_type > p{margin-top: 11px; font-size: 13px; color: #999; height: 16px; padding: 0 4px;}
.pnp_content .common_type > span{display: block; margin-top: 6px; font-size: 13px; color: #000; height: 16px; padding: 0 4px;}
.pnp_content .common_type .cash_add{width: 100%; font-size: 0; margin-top: 21px; border-top: 1px solid #e8e8e8; border-left: 1px solid #e8e8e8;}
.pnp_content .common_type .cash_add > button{width: 33.3%; display: inline-block; border-bottom: 1px solid #e8e8e8; border-right: 1px solid #e8e8e8; height: 50px;}
.pnp_content .common_type .cash_add > button:nth-child(2), .refill_common .inner_box .common_type .cash_add > button:nth-child(5){width: 33.4%;} 
.pnp_content .common_type .cash_add > button > span{display: inline-block; padding-left: 13px; font-size: 14px; background: url(/_next/static/media/icon-icon-plus.39f512ce.svg) left center no-repeat;}

.pnp_content .common_type .input-group > input{padding-right: 78px;}
.pnp_content .common_type .input-group #searchclear{right: 60px;}
.pnp_content .common_type .input-group .all_cash{width: 47px; height: 28px; border: 1px solid #e8e8e8; background: #fff; font-size: 13px; position: absolute; right: 0; border-radius: 4px;}


.pnp_content .id_check{margin-top: 50px; padding-bottom: 8px;}
.pnp_content .id_check > h3{font-size: 14px; font-weight: 700;}
.pnp_content .id_check .inp_ar{width: 100%; border: 1px solid #e8e8e8; border-radius: 8px; font-size: 0; margin-top: 20px;}
.pnp_content .id_check .inp_ar.focus{border-color: #f93964;}
.pnp_content .id_check .inp_ar .w_lg{display: inline-block; padding: 0 20px 0; width: calc(100% - 215px); vertical-align: middle;}
.pnp_content .id_check .inp_ar > i{display: inline-block; width: 1px; height: 28px; vertical-align: middle; background: #e8e8e8;}
.pnp_content .id_check .inp_ar .w_sm{display: inline-block; padding: 0 20px 0; width: 140px; vertical-align: middle;}
.pnp_content .id_check .inp_ar > button{width: 60px; height: 34px; background: #e5e5e5; border-radius: 4px; color: #fff; font-size: 14px; vertical-align: middle;}


.pap_area2 .PurchaseConfirmation{padding: 30px;}

.pap_area2 .pnp_content .button_area{margin-top: 40px;}




.popup .dim .pop_inner.Mypage_common{width: 412px; padding: 40px 30px 30px;}
.popup .dim .pop_inner.Mypage_common .pop_header > p{margin-top: 16px; padding: 0; font-size: 14px; text-align: left;}
.popup .dim .pop_inner.Mypage_common .pop_header > p > b{font-size: 14px; font-weight: 700;}

.popup .dim .pop_inner.Mypage_common .pop_content{padding-top: 40px;}
.popup .dim .pop_inner.Mypage_common .pop_content .hp_certify{width: 100%; height: 54px; border-radius: 8px; background: #f93964; color: #fff; font-size: 16px;}
.popup .dim .pop_inner.Mypage_common .pop_content .kko_certify{width: 100%; height: 54px; border-radius: 8px; background: #ffdd02;  margin-top: 12px;}
.popup .dim .pop_inner.Mypage_common .pop_content .kko_certify > span{display: inline-block; padding-left: 62px; height: 19px; line-height: 19px; background: url(/_next/static/media/logo-kakaopay.e920145d.svg) left center no-repeat; font-size: 15px; font-weight: 700;}

.popup .dim .pop_inner.Mypage_common .pop_content .note_ar{margin-top: 110px;}
.popup .dim .pop_inner.Mypage_common .pop_content .note_ar > p{font-size: 13px; line-height: 19px; padding-left: 10px; color: #666; margin-top: 6px; background: url(/_next/static/media/dot_01.6a71e25e.png) left 7px no-repeat; background-size: 2px;}
.popup .dim .pop_inner.Mypage_common .pop_content .note_ar > p:first-child{margin-top: 0;}


.popup .dim .pop_inner.Mypage_lock{width: 480px; padding: 40px 30px 30px;}
.popup .dim .pop_inner.Mypage_lock .pop_header > p{margin-top: 16px; padding: 0; font-size: 14px; text-align: left;}
.popup .dim .pop_inner.Mypage_lock .pop_header > p > b{font-size: 14px; font-weight: 700;}

.popup .dim .pop_inner.Mypage_lock .cash_lock{width: 100%; padding-top: 20px;}
.popup .dim .pop_inner.Mypage_lock .cash_lock > h2{padding-top: 73px; background: url(/_next/static/media/icon-90.f235c8ed.svg) top center no-repeat; font-size: 18px; line-height: 24px; font-weight: 700; text-align: center;}
.popup .dim .pop_inner.Mypage_lock .cash_lock > p{margin-top: 16px; text-align: center; font-size: 13px; line-height: 18px; color: #666;}
.popup .dim .pop_inner.Mypage_lock .bgbox_info{margin-top: 40px; padding: 30px; background: #fbfbfb; border-radius: 8px;}
.popup .dim .pop_inner.Mypage_lock .bgbox_info > h3{font-size: 14px;}
.popup .dim .pop_inner.Mypage_lock .bgbox_info > h3 > i{font-size: 14px; color: #f93964; padding-right: 5px;}
.popup .dim .pop_inner.Mypage_lock .bgbox_info > p{font-size: 12px; line-height: 18px; color: #999; margin-top: 10px;}
.popup .dim .pop_inner.Mypage_lock .btn_box{width: 100%; margin-top: 30px; font-size: 0;}
.popup .dim .pop_inner.Mypage_lock .btn_box > button{width: calc(50% - 4px); height: 54px; margin-left: 8px; background: #f93964; color: #fff; font-size: 16px; border-radius: 8px; }
.popup .dim .pop_inner.Mypage_lock .btn_box > button:first-child{width: calc(50% - 4px); height: 54px; margin-left: 0px; background: #fff; border: 1px solid #e8e8e8; color: #000; font-size: 16px; }
.popup .dim .pop_inner.Mypage_lock .btn_box > button.full{width: 100%; height: 54px; margin-left: 0px; background: #f93964; color: #fff; font-size: 16px; border-radius: 8px; }
.popup .dim .pop_inner.Mypage_lock .pop_input{width: 100%; margin-top: 228px;}
.popup .dim .pop_inner.Mypage_lock .pop_input > li{height: 37px; font-size: 0; width: 100%; position: relative; margin-top: 30px;}
.popup .dim .pop_inner.Mypage_lock .pop_input > li:first-child{margin-top: 0;}
.popup .dim .pop_inner.Mypage_lock .pop_input > li > input{width: 290px; display: inline-block; border-bottom: 1px solid #e8e8e8; height: 37px; vertical-align: bottom; font-size: 14px;}
.popup .dim .pop_inner.Mypage_lock .pop_input > li > button{width: 110px; margin-left: 20px; height: 34px; background: #666; color: #fff; font-size: 14px; display: inline-block; vertical-align: bottom; border-radius: 4px;}
.popup .dim .pop_inner.Mypage_lock .pop_input > li > input.full{width: 100%;}
.popup .dim .pop_inner.Mypage_lock .pop_input > li > span{position: absolute; right: 0; font-size: 14px; color: #f93964; bottom: 10px;}
.popup .dim .pop_inner.Mypage_lock .pop_content{padding-top: 0;}



.MyPage{width: 100%;}
.MyPage .MyPage_top{width: 100%; background: #fff; padding: 60px 0 30px;}
.MyPage .MyPage_top h2{font-size: 24px; line-height: 29px; font-weight: 700;}
.MyPage .MyPage_top p{margin-top: 20px; font-size: 14px;}
.MyPage .MyPage_top p > b{color: #f93964; font-size: 14px;}
.MyPage .MyPage_top span{margin-top: 6px; font-size: 14px; display: block; }
.MyPage .MyPage_top span > b{color: #f93964; font-size: 14px;}
.MyPage_content{width: 1200px; margin: 0 auto; padding: 60px 0 90px;}
.MyPage_content > section{width: 720px; margin: 0 auto; position: relative;}
.MyPage_content > section > h3{font-size: 18px; font-weight: 700; text-align: center; line-height: 21px;}
.MyPage_content > section > h3 > em{display: inline-block; padding-left: 6px; font-size: 12px; line-height: 21px; color: #f93964; font-weight: 700;}
.MyPage_content > section > span{display: block; text-align: center; font-size: 13px; line-height: 16px; padding-top: 3px; color: #ff911b;}
.MyPage_content > section > p{text-align: center; font-size: 14px; line-height: 17px; padding-top: 10px;}
.MyPage_content > section > p > i{color: #f93964; font-size: 14px; }
.MyPage_content > section .MyPage_common_box{width: 100%; background: #fff; padding: 60px 0; border-radius: 16px; margin-top: 30px;}
.MyPage_content .info_box{width: 720px; margin: 30px auto 0;}
.MyPage_content .info_box > p{margin-top: 5px; font-size: 13px; background: url(/_next/static/media/dot_01.6a71e25e.png) left 7px no-repeat; background-size: 2px; padding-left: 8px; color: #666; line-height: 19px;}
.MyPage_content .info_box > p:first-child{margin-top: 0;}

.password_check{width: 360px; margin: 0 auto;}
.password_check > ul{width: 100%;}
.password_check > ul > li{width: 100%; font-size: 0; margin-top: 30px;}
.password_check > ul > li:first-child{margin-top: 0;}
.password_check > ul > li > span{vertical-align: top; display: inline-block; width: calc(100% - 299px); font-size: 14px; font-weight: 700; }
.password_check > ul > li > input{width: 299px; display: inline-block; height: 28px; padding-bottom: 11px; font-size: 14px; vertical-align: top; border-bottom: 1px solid #e8e8e8; padding-top: 0;}
.password_check > ul > li > input:disabled{background: none; color: #666;}
.password_check > ul > li > input:focus{border-color: #f93964;}
.password_check > ul > li > input.pw{width: 100%; height: 31px; padding-top: 0; padding-bottom: 7px; font-size: 18px;}
.password_check > ul > li > h5{font-size: 14px; font-weight: 700; padding-bottom: 12px;}
.password_check > ul > li > p{font-size: 13px; color: #999; margin-top: 10px; line-height: 16px;}
.password_check .btn_area{width: 100%; margin-top: 40px;}
.password_check .btn_area > button{width: 100%; height: 54px; margin-left: 0px; background: #f93964; color: #fff; font-size: 16px; border-radius: 8px; }


.Chang_info{width: 480px; margin: 0 auto;}
.Chang_info > h4{font-size: 18px; text-align: center; font-weight: 700;}
.Chang_info > ul{margin-top: 24px; border-top: 1px solid #000;}
.Chang_info > ul > li{width: 100%; border-bottom: 1px solid #e8e8e8;}
.Chang_info > ul > li > a{padding: 30px 40px 30px 20px; width: 100%; background: url(/_next/static/media/bg_right_L.501341d1.svg) right center no-repeat; display: table;}
.Chang_info > ul > li > a > h5{display: table-cell; vertical-align: middle; min-width: 70px; font-size: 0;}
.Chang_info > ul > li > a > h5 > span{font-size: 16px; display: inline-block; vertical-align: middle; width: 70px;}
.Chang_info > ul > li > a > h5 > i{display: inline-block; padding-left:19px; font-size: 12px; color: #f93964; vertical-align: middle;}
.Chang_info > ul > li > a > p{display: table-cell; text-align: right; font-size: 16px;}
.Chang_info .bg_link{width: 100%; padding-top: 38px;}
.Chang_info .bg_link > a{display: block; width: 100%; padding: 30px 40px 30px 20px; border-radius: 8px; margin-top: 12px; background: #fbfbfb url(/_next/static/media/bg_right_S.379bec9c.svg) calc(100% - 20px) center no-repeat;}
.Chang_info .bg_link > a > h5{font-size: 16px; font-weight: 700; line-height: 19px;}
.Chang_info .bg_link > a > h5 > i{font-size: 16px; display: inline-block; padding: 0 3px; font-weight: 700;}
.Chang_info .bg_link > a > p{font-size: 12px; color: #666; line-height: 15px; margin-top: 3px;}
.Chang_info .bg_link > a > p > i{color: #000; line-height: 15px;}


.chang_email{width: 420px; margin: 0 auto;}
.chang_email > h5{font-size: 14px; font-weight: 700;}
.chang_email .inp_email{margin-top: 10px; font-size: 0;}
.chang_email .inp_email .id{display: inline-block; width: 142px; border-bottom: 1px solid #e8e8e8;}
.chang_email .inp_email .id > input{width: 100%; height: 37px; font-size: 14px; vertical-align: middle;}
.chang_email .inp_email > span{display: inline-block; vertical-align: middle; width: 21px; padding: 0 4px; text-align: center; font-size: 14px;}
.chang_email .inp_email .in_change{width: 155px; display: inline-block; position: relative; border-bottom: 1px solid #e8e8e8; height: 38px; vertical-align: middle;}
.chang_email .inp_email .in_change .nice-select{border: 0; height: 37px; border-radius: 0; width: 100%; padding-left: 0; line-height: 37px; font-size: 14px; color: #000; }
.chang_email .inp_email .in_change .nice-select .list{width: 100%; border-radius: 0; padding: 14px 0;}
.chang_email .inp_email .in_change .nice-select .option.selected.focus{background: none; color: #f93964;}
.chang_email .inp_email .in_change .nice-select .nice-select .option:hover{background: none;}
.chang_email .inp_email .in_change .nice-select .option{min-height: auto; line-height: 17px; padding: 12px 24px;}
.chang_email .inp_email .in_change > input{border: 0; height: 37px; width: calc(100% - 29px); font-size: 14px; color: #000; top: 0; left: 0; padding: 0 0; position: absolute; }
.chang_email .inp_email .in_change .nice-select:after{background: url(/_next/static/media/icon-arrow-small.ecafc2cf.svg) center center no-repeat; border: 0; transform-origin: 50% 50%; transform: rotate(0); height: 6px; width: 9px; margin-top: -3px; right: 10px;}
.chang_email .inp_email .in_change .nice-select.open:after{ transform: rotate(180deg); margin-top: -3px;}
.chang_email .inp_email > button{width: 82px; height: 34px; background: #666; border-radius: 4px; color: #fff; font-size: 14px; vertical-align: bottom; margin-left: 20px;}
.chang_email .btn_box{width: 100%; margin-top: 44px;}
.chang_email .btn_box > button{width: 100%; border-radius: 8px; height: 54px; background: #f93964; color: #fff; font-size: 16px;}
.chang_email .inp_email .error_text{display: block; font-size: 13px; line-height: 18px; margin-top: 7px; color: #f93964;}



.chang_password{width: 360px; margin: 0 auto;}
.chang_password .new_password{width: 100%; }
.chang_password .new_password.identify{margin-top: 48px;}
.chang_password .new_password > P{font-size: 13px; margin-top: 10px; color: #999;}
.chang_password .btn_box{width: 100%; margin-top: 44px;}
.chang_password .btn_box > button{width: 100%; border-radius: 8px; height: 54px; background: #f93964; color: #fff; font-size: 16px;}

.inp_action2 { position:relative; width:100%; height:28px;} /* 기본세팅 */
.inp_action2 input { box-sizing:border-box; padding: 0; vertical-align: bottom; width:100%; height:100%; border:0 none; color:#000; font-size: 28px; outline:none; font-weight: 700;   border-bottom: 1px solid #e8e8e8;}
.inp_action2 label { position:absolute; left:0%; bottom:0; width:100%; height:100%; text-align:left; pointer-events:none; }
.inp_action2 label:after { content:""; position:absolute; left:0; bottom:-1px; width:0; height:100%;} /* 파란색 가로줄 */
.inp_action2 label span { position:absolute; left:0; bottom:10px; transition:all .3s ease; font-size: 14px; color: #000; }
.inp_action2 input:focus{border-color: #f93964;}
.inp_action2 input:focus + label span, 
.inp_action2 input:valid + label span { transform:translateY(); bottom: 30px; font-size:11px; color: #999; }  /* input에 글을 입력하고 포커스가 지나간 상태에서 제어하려면 valid 선택자를 써야한다. */
/* 포커스 될 때 label span(name)이 위로 올라감 */
.inp_action2 input:focus + label::after,
.inp_action2 input:valid + label::after { width:100%; transform:translateX(0); } 


.g_line_box{width: 720px; margin: 0 auto; border: 1px solid #e8e8e8; background: #fbfbfb; border-radius: 16px;padding: 40px 0; text-align: center;}
.g_line_box > h5{font-size: 18px; text-align: center; font-weight: 700;} 
.g_line_box > h5 > b{color: #f93964; font-size: 18px; font-weight: 700;}
.g_line_box > p{margin-top: 16px; font-size: 12px; line-height: 16px; color: #999;}

.withdrawal{width: 420px; margin: 0 auto;}
.withdrawal > h4{font-size: 14px; font-weight: 700; text-align: center;}
.withdrawal .in_reason{width: 100%; margin-top: 20px; border-bottom: 1px solid #e8e8e8; height: 38px;}

.withdrawal .in_reason .nice-select{border: 0; height: 37px; border-radius: 0; width: 100%; padding-left: 0; line-height: 37px; font-size: 14px; color: #000; }
.withdrawal .in_reason .nice-select .list{width: 100%; border-radius: 0; padding: 14px 0;}
.withdrawal .in_reason .nice-select .option.selected.focus{background: none; color: #f93964;}
.withdrawal .in_reason .nice-select .option:hover{background: none;}
.withdrawal .in_reason .nice-select .option{min-height: auto; line-height: 17px; padding: 12px 24px;}

.withdrawal .in_reason .nice-select:after{background: url(/_next/static/media/icon-arrow-small.ecafc2cf.svg) center center no-repeat; border: 0; transform-origin: 50% 50%; transform: rotate(0); height: 6px; width: 9px; margin-top: -3px; right: 10px;}
.withdrawal .in_reason .nice-select.open:after{ transform: rotate(180deg); margin-top: -3px;}

.withdrawal .reason_input{border: 1px solid #e8e8e8; border-radius: 8px; padding: 20px 10px 20px 20px; overflow: hidden; margin-top: 20px;}
.withdrawal .reason_input > textarea{width: 100%; border: 0;  outline: none; height: 120px; overflow-y: auto; padding-right: 10px; font-size: 14px; line-height: 20px; color: #000; resize:none; }
.withdrawal .reason_input > textarea::-moz-placeholder{color: #c8c8c8;}
.withdrawal .reason_input > textarea:-ms-input-placeholder{color: #c8c8c8;}
.withdrawal .reason_input > textarea::placeholder{color: #c8c8c8;}
.withdrawal .btn_box{width: 100%; margin-top: 44px;}
.withdrawal .btn_box > button{width: 100%; border-radius: 8px; height: 54px; background: #f93964; color: #fff; font-size: 16px;}

.consent{width: 480px; margin: 0 auto; background: #fbfbfb; border-radius: 8px; padding: 30px;}
.consent > p{font-size: 12px; line-height: 18px; margin-top: 18px; color: #333;}
.consent > p:first-child{margin-top: 0;}
.MyPage_common_box .btn_btm{width: 360px; margin: 20px auto 0;}
.MyPage_common_box .btn_btm > p{font-size: 14px; text-align: center;}
.MyPage_common_box .btn_btm > button{width: 100%; border-radius: 8px; height: 54px; background: #f93964; color: #fff; font-size: 16px; margin-top: 30px;}


.info_cn_box{width: 720px; background: #fff; border-radius: 16px; padding: 30px 150px;}
.info_cn_box .inline_outer{width: 100%; font-size: 0;}
.info_cn_box .inline_outer .info{display: inline-block; width: calc(100% - 60px); vertical-align: middle;}
.info_cn_box .inline_outer .info > p{display: table; width: 100%; margin-top: 12px;}
.info_cn_box .inline_outer .info > p:first-child{margin-top: 0;}
.info_cn_box .inline_outer .info > p > span{display: table-cell; font-size: 14px; vertical-align: middle;}
.info_cn_box .inline_outer .info > p > span:first-child{width: 77px; color: #999; font-weight: 700;}
.info_cn_box .inline_outer .cn_btn{width: 60px; display: inline-block; vertical-align: middle;}
.info_cn_box .inline_outer .cn_btn > button{width: 100%; height: 34px; background: #666; color: #fff; font-size: 14px; border-radius: 4px;}
.ConsentReceive{width: 100%;}
.ConsentReceive > h5{font-size: 14px; font-weight: 700; text-align: center;}
.ConsentReceive .gr_box{width: 420px; margin: 30px auto 0; background: #fbfbfb; border-radius: 8px; padding: 10px 30px;}
.ConsentReceive .gr_box > li{width: 100%; padding: 20px 0; font-size: 0;}
.ConsentReceive .gr_box > li:first-child{border-bottom: 1px solid #e8e8e8;}
.ConsentReceive .gr_box > li > span{display: inline-block; width: 116px; vertical-align: middle;}
.ConsentReceive .btn_box{width: 420px; margin: 10px auto 0;}
.ConsentReceive .btn_box > p{padding-left: 20px; background: url(/_next/static/media/icon-icon-noti.b0193e70.svg) left center no-repeat; height: 16px; line-height: 16px; font-size: 13px; color: #999;}
.ConsentReceive .btn_box > p > b{color: #666;}
.ConsentReceive .btn_box > button{width: 100%; border-radius: 8px; height: 54px; background: #f93964; color: #fff; font-size: 16px; margin-top: 40px;}

.usage_details{width: 100%;}
.usage_details .usege_top{width: 100%; background: #fff; padding: 60px 0 30px;}
.usage_details .usege_top h2{font-size: 28px; line-height: 29px; font-weight: 700;}
.usage_details .usege_top .use_tab{width: 100%; font-size: 0; text-align: center; padding-top: 40px;}
.usage_details .usege_top .use_tab > ul{width: 720px; margin: 0 auto; text-align: center;}
.usage_details .usege_top .use_tab > ul > li{display: inline-block; vertical-align: middle; width: auto; height: 40px; border-radius: 20px; background: #fff; border: 1px solid #e8e8e8; font-size: 16px; line-height: 40px; padding: 0 21px; margin: 0 0 0 10px; cursor: pointer;}
.usage_details .usege_top .use_tab > ul > li:first-child{margin-left: 0;}
.usage_details .usege_top .use_tab > ul > li.active{background: #000; border-color: #000; color: #fff;}
.usage_details .usege_top .use_tab > ul > li > span{display: inline-block;}
.usage_details .usege_top .use_tab > ul > li > i{font-size: 16px; padding: 0 4px;}
.usage_details .usege_top .use_tab > ul > li.active > i{font-size: 16px; padding: 0 4px; color: #fff;}
.usage_details .use_sub_top{padding: 40px 0; width: 100%; min-width: 1280px; background: #fff; text-align: center; margin: 0 auto; border-top: 1px solid #e8e8e8;}
.usage_details .use_sub_top .inner_inline{display: inline-block; text-align: left; width: auto;}
.usage_details .use_sub_top .inner_inline .date_choice{width: 100%;}
.usage_details .use_sub_top .inner_inline .date_choice > h5{font-size: 16px; font-weight: 700;}
.usage_details .use_sub_top .inner_inline .date_choice .choice_box{margin-top: 12px; font-size: 0; position: relative;}
.usage_details .use_sub_top .inner_inline .date_choice .choice_box > span{display: inline-block; width: 70px; height: 36px; border: 1px solid #e8e8e8; position: absolute; top: 0; font-size: 13px; line-height: 36px; text-align: center; cursor: pointer;;}  
.usage_details .use_sub_top .inner_inline .date_choice .choice_box > span.on{border-color: #f93964; color: #f93964; font-weight: 700; z-index: 2;}
.usage_details .use_sub_top .inner_inline .date_choice .choice_box > span:first-child{left: 0; border-top-left-radius: 4px; border-bottom-left-radius: 4px;}
.usage_details .use_sub_top .inner_inline .date_choice .choice_box > span:nth-child(2){left: 69px;}
.usage_details .use_sub_top .inner_inline .date_choice .choice_box > span:nth-child(3){left: 138px;}
.usage_details .use_sub_top .inner_inline .date_choice .choice_box > span:nth-child(4){left: 207px;}
.usage_details .use_sub_top .inner_inline .date_choice .choice_box > span:nth-child(5){left: 276px; border-top-right-radius: 4px; border-bottom-right-radius: 4px;}
.usage_details .use_sub_top .inner_inline .date_choice .choice_box > span:nth-child(6){left: 355px; border-radius: 4px;}
.usage_details .use_sub_top .inner_inline .date_choice .choice_box > em{font-size: 13px; color: #f93964; display: block; padding-left: 440px; line-height: 36px;}
.usage_details .use_sub_top .inner_inline .date_choice .dir_box{font-size: 0; margin-top: 8px;}
.usage_details .use_sub_top .inner_inline .date_choice .datepicker_box{width: 118px; display: inline-block; vertical-align: middle; position: relative;}
.usage_details .use_sub_top .inner_inline .date_choice .datepicker_box > input{width: 100%; height: 36px; font-size: 13px; padding: 0 33px 0 14px; border: 1px solid #000; border-radius: 4px;}
.usage_details .use_sub_top .inner_inline .date_choice .datepicker_box img {position: absolute;	top: 10px; right: 14px; width: 16px;}
.usage_details .use_sub_top .inner_inline .date_choice .date_ch{display: inline-block; padding: 0 8px; vertical-align: middle;}
.usage_details .use_sub_top .inner_inline .date_choice .date_ch > span{font-size: 13px;}
.division_box{width: 100%; margin-top: 30px;}
.division_box > h5{font-size: 16px; font-weight: 700;}
.sel_ar{margin-top: 12px;}
.radio_sm{position: relative; display: inline-block; margin-right: 20px;}
.radio_sm > input[type="radio"] + label {display: inline-block; height: 16px; padding: 0 0px 0 22px; background: url(/_next/static/media/button-radio-small-off.90fa0508.svg) left center no-repeat;  background-size: 16px; font-size: 13px; line-height: 18px; overflow: hidden;}
.radio_sm > input[type="radio"]:checked + label {background: url(/_next/static/media/button-radio-small-on.fe815521.svg) left center no-repeat;}
.radio_sm > input[type="radio"] {position: absolute; left: 0; right: 0; top: 0; bottom: 0;z-index: 10; opacity: 0; width: 100%;height: 100%;letter-spacing: 0; border: none;  background: none; vertical-align: middle; -webkit-appearance: none; -ms-appearance: none;}

.usage_details .sub_btn{margin-top: 40px; text-align: center;}
.usage_details .sub_btn > button{width: 160px; height: 40px; background: #666; color: #fff; border-radius: 4px; font-size: 14px;}

.ui-datepicker{padding: 15px 15px 10px !important;}
.ui-datepicker table{font-size: 12px !important; margin-bottom: 0;}
.ui-datepicker .ui-datepicker-title select {
	border:none !important;
	width: auto;
	height: auto;
}
.ui-datepicker .ui-datepicker-title{margin: 0 20px !important;}
.ui-datepicker select.ui-datepicker-year{width: 44% !important; text-align: right; padding-right: 10px;}
.ui-datepicker select.ui-datepicker-month{width: 30% !important; text-align: right; padding-right: 10px; margin-left: 10px !important;}
.ui-datepicker th > span{font-size: 12px !important;}
.ui-datepicker th:first-child > span{color: #f93964;}
.ui-datepicker th:last-child > span{color: #0d0f85;}
.ui-datepicker td{width: 30px;}
.ui-datepicker td > a{line-height: 20px;}
.ui-datepicker td:first-child > a{color: #f93964;}
.ui-datepicker td:last-child > a{color: #0d0f85;}
.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active { border: 1px solid #ff911b !important; background: #fff !important; font-weight: 500/*{fwDefault}*/; color: #000 !important;border-radius: 3px; }
.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover { border: 1px solid #ff911b !important; background: #fff !important; font-weight: 500/*{fwDefault}*/; color: #000 !important; border-radius: 3px;}


.usegedetails_content{width: 100%; padding: 60px 0 90px;}
.usegedetails_content .suege_list{width: 1024px; margin: 0 auto;}
.usegedetails_content .suege_list .top_btn{width: 100%; text-align: right;}
.usegedetails_content .suege_list .top_btn > button{height: 32px; background: #fff; border-radius: 4px; border: 1px solid #e8e8e8; font-size: 14px; padding: 0 18px;}
.usegedetails_content .suege_list .top_btn > span{display: inline-block; padding-right: 16px;}
.usegedetails_content .suege_list .list_common_box{margin-top: 12px; background: #fff; border-radius: 16px; padding: 60px 110px;}
.usegedetails_content .suege_list .list_common_box > p{font-size: 14px; color: #333;}
ul.list_type01{border-top: 1px solid #000; width: 100%; margin-top: 10px;}
ul.list_type01 > li{width: 100%; display: table; padding: 20px 40px; border-bottom: 1px solid #e8e8e8;}
ul.list_type01 > li .division{display: table-cell; width: 125px; text-align: center; vertical-align: middle; color: #666666; font-size: 14px; padding-right: 50px;}
ul.list_type01 > li .breakdown{display: table-cell; width: 450px; text-align: left; vertical-align: middle;}
ul.list_type01 > li .breakdown .date{font-size: 12px; color: #999; line-height: 15px;}
ul.list_type01 > li .breakdown .substance{font-size: 14px; line-height: 17px; margin-top: 3px;}
ul.list_type01 > li .breakdown .substance2{font-size: 0;}
ul.list_type01 > li .breakdown .substance2 > span{display: inline-block; line-height: 1; vertical-align: middle; font-size: 14px; font-weight: 700;}
ul.list_type01 > li .breakdown .substance2 > i{display: inline-block; vertical-align: middle; height: 22px; line-height: 22px; border: 1px solid #e8e8e8; background: #f8f8f8; text-align: center; width: 66px; border-radius: 14px; font-size: 12px; margin-left: 6px;}
ul.list_type01 > li .breakdown .info{font-size: 12px; line-height: 15px; margin-top: 10px;}
ul.list_type01 > li .cash_info{display: table-cell; text-align: right; vertical-align: middle;}
ul.list_type01 > li .cash_info > p{font-size: 16px; line-height: 19px; font-weight: 700;} 
ul.list_type01 > li .cash_info > p.plus{color: #f93964;}
ul.list_type01 > li .cash_info > p.minus{color: #000000;}
ul.list_type01 > li .cash_info > span{display: inline-block; font-size: 12px; cursor: pointer; text-decoration: underline; margin-top: 6px;}
ul.list_type01 > li .cash_info > span.send_cancel{margin-left: 12px;}
ul.list_type01 > li .cash_info > span.charge_cancel{margin-left: 12px;}
ul.list_type01 > li .cash_info > em{display: inline-block; margin-top: 3px; color: #f93964; font-size: 12px; }
ul.list_type01 > li .cash_info > em.use{color: #999999;}
ul.list_type01 > li.none_data {height: 380px;}
ul.list_type01 > li.none_data > span{display: table-cell; vertical-align: middle; width: 100%; text-align: center; font-size: 14px;}
.usegedetails_content .suege_list .list_common_box .button_ar{margin-top: 40px; text-align: center;}
.usegedetails_content .suege_list .list_common_box .button_ar .more{width: 140px; height: 40px; background: #f8f8f8; border-radius: 8px; font-size: 14px;}
.pop_inner2{background: #fff; border-radius: 16px; position: relative; text-align: left; overflow: hidden;}
.pop_inner2.w480{width: 480px; padding: 40px 0px 0px; background: #f8f8f8;}
.pop_inner2 > button{width: 30px; height: 30px; position: absolute; top: 20px; right: 20px; background: url(/_next/static/media/icon-popup-close.95762238.svg) no-repeat; }
.pop_inner2 .pop_header{width: 100%; padding: 0 30px;}
.pop_inner2 .pop_header > h2{font-size: 18px; font-weight: bold;}

.pop_inner2 .pop_content{width: 100%; margin-top: 30px; text-align: center;}
.pop_inner2 .pop_content .product_box{display: inline-table; width: calc(100% - 60px); margin-top: 0; background: #fff; border-radius: 16px; height: 79px; padding: 0 30px;}
.pop_inner2 .pop_content .product_box > span{display: table-cell; text-align: right; font-size: 16px; vertical-align: middle;}
.pop_inner2 .pop_content .product_box > span:first-child{text-align: left;}
.pop_inner2 .pop_content ul.payment{width: 100%; padding: 10.5px 30px;}
.pop_inner2 .pop_content ul.cancel{width: 100%; padding: 10.5px 30px 20.5px; background: #fff;}
.pop_inner2 .pop_content ul > li{width: 100%; display: table; padding: 19.5px 0; border-top: 1px dashed #c8c8c8;}
.pop_inner2 .pop_content ul > li:first-child{border: 0;}
.pop_inner2 .pop_content ul > li > p{display: table-cell; text-align: right; vertical-align: top; font-size: 16px; font-weight: 700;}
.pop_inner2 .pop_content ul > li > p:first-child{text-align: left;}
.pop_inner2 .pop_content ul > li > p > span{font-size: 16px; font-weight: 700; display: block;}
.pop_inner2 .pop_content ul > li > p > i{font-size: 12px; margin-top: 5px; font-weight: 400; color: #999; display: block;}
.pop_inner2 .pop_content ul > li > p > em{font-size: 13px; font-weight: 400; margin-top: 9px; display: block;}


.pop_inner.w820{width: 820px; padding: 40px 30px 0; background: #fff;}
.pop_inner.w360{width: 360px; padding: 40px 30px 0; background: #fff;}
.pop_inner.w480{width: 480px; padding: 40px 30px 30px; background: #fff;}
.pop_inner.w4802{width: 480px; padding: 40px 60px 30px; background: #fff;}

.list_type_table{width: 100%; border-top: 1px solid #000; height: 443px; border-bottom: 1px solid #e8e8e8; overflow: hidden;}
.list_type_table.line_5{height: 243px !important;}
.list_type_table table{width: 100%;}
.list_type_table thead{width: 100%;}
.list_type_table thead th{padding: 15px 0 10px; text-align: center; font-size: 13px; line-height: 16px; color: #666; border-bottom: 1px solid #e8e8e8;}
.list_type_table td{padding: 0 13px; height: 40px; font-size: 13px; text-align: center; border-bottom: 1px solid #e8e8e8;}
.list_type_table td:first-child{color: #999;}
.list_type_table td.tar{text-align: right;}
.list_type_table td.none_data{height: 400px; color: #000;}
.list_type_table td.standby{color: #f93964;}
.list_type_table td .resend{width: 58px; height: 24px; background: #666; border-radius: 4px; color: #fff; font-size: 13px; line-height: 24px;}

/* paging_area */
.paging_area {margin-top: 30px; padding-bottom: 60px; text-align: center; vertical-align: baseline;}
.paging_area a {display: inline-block; margin:0 3px; font-size: 13px; color:#888; width: 36px; height: 36px; line-height: 34px; vertical-align: middle; border: 1px solid #e8e8e8; border-radius: 4px;}
.paging_area a.on {background:#000 ; color:#fff; border-radius: 4px; border-color: #000;}

.paging_area .page {width: 36px; height: 36px; padding:0; text-indent: -9999px; display: inline-block; vertical-align: middle; border: 1px solid #e8e8e8; border-radius: 4px;}
.paging_area .page.prev {background: url(/_next/static/media/pageing_prev.7596f2f0.svg) center center no-repeat;}
.paging_area .page.next {background: url(/_next/static/media/paging_next.e6caf5a5.svg) center center no-repeat;}


.paging_area .page:disabled {opacity: 0.5; border: none !important; background-color: #fff !important;}


.pop_header > p.left_dot{margin-top: 6px !important; font-size: 13px !important; background: url(/_next/static/media/dot_01.6a71e25e.png) left center no-repeat; background-size: 2px; padding-left: 8px; color: #666; line-height: 16px; text-align: left !important;}
.pop_header > p.first{margin-top: 16px !important;}

.pop_content .gr_box{width: 100%; margin-top: 24px; background: #fbfbfb; border-radius: 8px; padding: 24px 30px;}
.pop_content .gr_box > ul{width: 100%;}
.pop_content .gr_box > ul > li{width: 100%; display: table; margin-top: 12px;}
.pop_content .gr_box > ul > li:first-child{margin-top: 0;}
.pop_content .gr_box > ul > li > span{display: table-cell; text-align: left; font-size: 14px; font-weight: 700; width: calc(50% - 100px);}
.pop_content .gr_box > ul > li > span:first-child{font-weight: 400; width: 100px;}
.pop_content .gr_box > ul > li > span:nth-child(3){font-weight: 400; width: 100px;}

.pop_content .list_top{margin-top: 24px; width: 100%; font-size: 0; position: relative; padding-bottom: 10px;}
.pop_content .list_top > span{font-size: 14px; display: inline-block;}
.pop_content .list_top > span.cash{padding-left: 8px; font-weight: 700;}
.pop_content .list_top > span.info{text-align: right; color: #ff911b; position: absolute; right: 0;}
.pop_content > p.left_nomal{font-size: 14px !important; color: #000 !important;}
.pop_content .inp_box{margin-top: 40px; padding-top: 12px;}
.pop_content .inp_box.sen{margin-top: 22px; padding-top: 12px;}
.pop_content .inp_box .inp_action2 > input{font-size: 14px; font-weight: 400;}
.pop_content > p.text_y_center{padding-left: 0 !important; text-indent: 0 !important; color: #ff911b !important; font-size: 14px !important; text-align: center !important; margin-top: 30px !important; line-height: 17px;}

.inner_inline .div_box{width: 100%; display: table;}
.inner_inline .div_box .helf_box{width: 50%; display: table-cell;}

.security_content{width: 100%; background: #fff; padding: 30px 0 90px;}
.security_content .seeting_inner{width: 840px; margin: 0 auto;}
.security_content .seeting_inner .cash_setting{width: 100%;}
.cash_setting > h4{font-size: 20px; line-height: 24px; font-weight: 700;}
.cash_setting > h4 > em{color: #f93964; font-weight: 700;}
.cash_setting > h4 > i{color: #f93964; font-size: 12px; line-height: 24px; font-weight: 400; display: inline-block; margin-left: 12px;}

.security_content .border-box{width: 100%; padding: 10px 135px 10px 125px; border: 1px solid #e8e8e8; background: #fff; border-radius: 16px; margin-top: 10px;}
.security_content .border-box > ul{width: 100%;}
.security_content .border-box > ul > li{width: 100%; display: table; padding: 30px 0; border-top: 1px solid #e8e8e8;}
.security_content .border-box > ul > li:first-child{border-top: 0;}
.security_content .border-box > ul > li .content_text{display: table-cell; width: 295px; vertical-align: middle;}
.security_content .border-box > ul > li .content_text > p.time{padding-left: 48px; background: url(/_next/static/media/icon-clock.125121e0.svg) left center no-repeat;}
.security_content .border-box > ul > li .content_text > p.day{padding-left: 48px; background: url(/_next/static/media/icon-calendar.ceff41c6.svg) left center no-repeat;}
.security_content .border-box > ul > li .content_text > p > strong{font-size: 16px; font-weight: 700; line-height: 19px; display: block;}
.security_content .border-box > ul > li .content_text > p > span{font-size: 12px; margin-top: 4px; line-height: 16px; display: block; color: #666;}
.security_content .border-box > ul > li .r_button{display: table-cell; vertical-align: middle; text-align: right;}
.security_content .border-box > ul > li .r_button > button{width: 210px; height: 65px; background: #f93964; border-radius: 8px;}
.security_content .border-box > ul > li .r_button > button > p{font-size: 15px; font-weight: 700; color: #fff; line-height: 19px;}
.security_content .border-box > ul > li .r_button > button > span{font-size: 13px; color: #fff; line-height: 16px;}
.cash_setting > p{font-size: 13px; background: url(/_next/static/media/dot_01.6a71e25e.png) left center no-repeat; background-size: 2px; padding-left: 8px; color: #999; line-height: 19px; margin-top: 10px;}


.security_content .seeting_inner .use_setting{width: 100%; margin-top: 60px;}
.use_setting > h4{font-size: 20px; line-height: 24px; font-weight: 700;}
.use_setting > h4 > em{color: #f93964; font-weight: 700;}


.security_content .seeting_inner .limit_setting{width: 100%; margin-top: 60px;}
.limit_setting > h4{font-size: 20px; line-height: 24px; font-weight: 700;}
.limit_setting > h4 > em{color: #f93964; font-weight: 700;}
.limit_setting .border-box{width: 100%; padding: 10px 60px 10px 125px; border: 1px solid #e8e8e8; background: #fff; border-radius: 16px; margin-top: 10px;}
.limit_setting .border-box > ul{width: 100%;}
.limit_setting .border-box > ul > li{width: 100%; display: table; padding: 30px 0; border-top: 1px solid #e8e8e8;}
.limit_setting .border-box > ul > li:first-child{border-top: 0;}
.limit_setting .border-box > ul > li .content_text{display: table-cell; width: 295px; vertical-align: middle;}
.limit_setting .border-box > ul > li .content_text > p.time{padding-left: 48px; background: url(/_next/static/media/icon-clock.125121e0.svg) left center no-repeat;}
.limit_setting .border-box > ul > li .content_text > p.day{padding-left: 48px; background: url(/_next/static/media/icon-calendar.ceff41c6.svg) left center no-repeat;}
.limit_setting .border-box > ul > li .content_text > p > strong{font-size: 16px; font-weight: 700; line-height: 19px; display: block;}
.limit_setting .border-box > ul > li .content_text > p > span{font-size: 12px; margin-top: 4px; line-height: 16px; display: block; color: #666;}
.limit_setting .border-box > ul > li .r_button{display: table-cell; vertical-align: middle; text-align: center;}
.limit_setting .border-box > ul > li .r_button > button{width: 210px; height: 65px; background: #f93964; border-radius: 8px; margin-top: 10px;}
.limit_setting .border-box > ul > li .r_button > button > p{font-size: 15px; font-weight: 700; color: #fff; line-height: 19px;}
.limit_setting .border-box > ul > li .r_button > button > span{font-size: 13px; color: #fff; line-height: 16px;}





.limit_select{width: 100%; text-align: center; position: relative; height: 50px;}
.limit_select > span.radio_box > input[type="radio"] + label {display: block; width: 100%; border: 1px solid #e8e8e8; box-sizing: border-box; line-height: 48px;}
.limit_select > span.radio_box > input[type="radio"]:checked + label {border: 1px solid #f93964; z-index: 10; color: #f93964; font-weight: 700;}
.limit_select > span.radio_box > input[type="radio"] {position: absolute; left: 0; right: 0; top: 0; bottom: 0;z-index: 10; opacity: 0; width: 100%;height: 100%;letter-spacing: 0; border: none;  background: none; vertical-align: middle; -webkit-appearance: none; -ms-appearance: none; margin: 0;}
.limit_select > span.radio_box > label > span{display: table-cell; font-weight: 500; font-size: 13px; text-align: right; line-height: 21px; vertical-align: middle;}
.limit_select > span.radio_box > label > span:first-child{font-size: 14px; text-align: left;}
.limit_select > span.radio_box > label > span > em{display: inline-block; font-style: normal;}
.limit_select > span.radio_box > label > span > strong{display: inline-block; width: 80px; text-align: right; color: #f93964; font-style: normal; font-weight: unset;}
.limit_select > span.radio_box > label > span > i{color: #999; font-size: 12px; font-style: normal;}
.limit_select > span{display: inline-block; width: 72px; height: 50px; position: absolute;}
.limit_select > span:first-child{left: 1px; top: 0;} 
.limit_select > span:first-child > input[type="radio"] + label {border-top-left-radius: 8px; border-bottom-left-radius: 8px;}
.limit_select > span:nth-child(2){left: 72px; top: 0;}
.limit_select > span:nth-child(3){left: 143px; top: 0;}
.limit_select > span:nth-child(4){left: 214px; top: 0;}
.limit_select > span:nth-child(5){left: 285px; top: 0;}
.limit_select > span:nth-child(5) > input[type="radio"] + label {border-top-right-radius: 8px; border-bottom-right-radius: 8px;}


.inquirydetails_content{width: 100%; padding: 60px 0 90px;}
.inquirydetails_content .inquiry_list{width: 1024px; margin:  0 auto;}
.inquirydetails_content .inquiry_list .list_common_box{background: #fff; border-radius: 16px; padding: 60px 110px; position: relative;}
.inquirydetails_content .inquiry_list .list_common_box > p{padding-top: 12px;}
.inquirydetails_content .inquiry_list .list_common_box > button{ width: 85px; height: 32px; background: #f93964; color: #fff; font-size: 14px; border-radius: 4px; position: absolute; right: 110px; top: 60px;}
.inquirydetails_content .inquiry_list .list_common_box .list_type01 > li{padding: 30px 40px !important;}
.inquirydetails_content .inquiry_list .list_common_box .list_type01 > li .details{display: table-cell; width: calc(100% - 130px); padding-right: 44px; vertical-align: middle;}
.inquirydetails_content .inquiry_list .list_common_box .list_type01 > li .details > h5{font-size: 13px; font-weight: 700; line-height: 16px;}
.inquirydetails_content .inquiry_list .list_common_box .list_type01 > li .details > p{font-size: 14px; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; margin-top: 6px; line-height: 20px;}
.inquirydetails_content .inquiry_list .list_common_box .list_type01 > li .details > p > i.pic{ width: 12px; height: 12px; background: url(/_next/static/media/text_img.9ab4ec91.svg) no-repeat; display: inline-block; background-size: 100%; margin-left: 5px; vertical-align: unset;}
.inquirydetails_content .inquiry_list .list_common_box .list_type01 > li .inquiry_info{display: table-cell; width: 130px; text-align: right; vertical-align: middle;}
.inquirydetails_content .inquiry_list .list_common_box .list_type01 > li .inquiry_info > span{display: block; font-size: 13px; color: #999;}
.inquirydetails_content .inquiry_list .list_common_box .list_type01 > li .inquiry_info > em{margin-top: 3px; display: block; font-size: 12px;}
.inquirydetails_content .inquiry_list .list_common_box .list_type01 > li .inquiry_info > em.complete{color: #f93964;}
.inquirydetails_content .inquiry_list .list_common_box .button_ar{margin-top: 40px; text-align: center;}
.inquirydetails_content .inquiry_list .list_common_box .button_ar .more{width: 140px; height: 40px; background: #f8f8f8; border-radius: 8px; font-size: 14px;}


.usage_details .usege_top .w_inner > p{font-size: 14px; margin-top: 20px; line-height: 20px;}
.usage_details .usege_top .w_inner > p > i{color: #f93964;}

.list_view_area{width: 100%; border-top: 1px solid #000; margin-top: 10px; }
.list_view_area .question_ar{margin-top: 30px;}
.list_view_area .question_ar > h5{font-size: 0; text-align: left;}
.list_view_area .question_ar > h5 > i{font-size: 20px; font-weight: bold; width: 19px; display: inline-block;}
.list_view_area .question_ar > h5 > em{font-size: 14px; margin-left: 11px;}
.list_view_area .question_ar > h5 > em.comp{color: #f93964;}
.list_view_area .question_ar > h5 > span{font-size: 14px; margin-left: 10px; color: #999;}
.list_view_area .question_ar .in_text{width: 100%; padding: 0 30px; margin-top: 17px;}
.list_view_area .question_ar .in_text > p{font-size: 14px; line-height: 24px;}
.list_view_area .question_ar .img_area{width: 100%; padding: 20px 30px 0;}
.list_view_area .question_ar .img_area > img{max-width: 100%;}
.list_view_area .answer_ar{margin-top: 30px; padding: 30px; background: #fbfbfb; border-radius: 8px;}
.list_view_area .answer_ar > p{font-size: 14px; line-height: 24px;}
.list_view_area .answer_ar .img_ar{width: 100%; padding: 20px 0 0;}
.list_view_area .answer_ar .img_ar > img{max-width: 100%;}
.button_ar_right{margin-top: 30px; text-align: right;}
.button_ar_right .GoList{width: 86px; height: 34px; border-radius: 4px; border: 1px solid #e8e8e8; font-size: 14px;}
.button_ar_right .Requestion{width: 86px; height: 34px; border-radius: 4px; background: #666666; font-size: 14px; color: #fff; margin-left: 8px;}


.list_common_box2{margin-top: 12px; background: #fff; border-radius: 16px; padding: 60px 152px;}
.list_common_box2 > h5{font-size: 18px; font-weight: 700; text-align: center;}
.list_common_box2 > p{font-size: 14px; margin-top: 10px; text-align: center;}

.write_step01{width: 100%; margin-top: 50px;}
.write_step01 .division{width: 100%; font-size: 0;}
.write_step01 .division > h4{display: inline-block; font-size: 16px; font-weight: 700; vertical-align: middle; line-height: 16px; margin-top: 2px;}
.write_step01 .division .sel_ar{display: inline-block; padding-left: 20px; vertical-align: middle; margin-top: 0;}
.write_step01 .cate{margin-top: 30px; width: 100%; font-size: 0;}
.write_step01 .cate > h4{font-size: 16px; font-weight: 700; vertical-align: middle; line-height: 16px; }
.write_step01 .cate > span{display: inline-block; width: 350px; margin-left: 20px; border-bottom: 1px solid #e8e8e8; margin-top: 10px;}
.write_step01 .cate > span.first_c{margin-left: 0;}
.cate > span .nice-select{border: 0; height: 37px; border-radius: 0; width: 100%; padding-left: 0; line-height: 37px; font-size: 14px; color: #000; }
.cate > span .nice-select .list{width: 100%; border-radius: 0; padding: 14px 0;}
.cate > span .nice-select .option.selected.focus{background: none; color: #f93964;}
.cate > span .nice-select .nice-select .option:hover{background: none;}
.cate > span .nice-select .option{min-height: auto; line-height: 17px; padding: 12px 24px;}

.cate > span .nice-select:after{background: url(/_next/static/media/icon-arrow-small.ecafc2cf.svg) center center no-repeat; border: 0; transform-origin: 50% 50%; transform: rotate(0); height: 6px; width: 9px; margin-top: -3px; right: 10px;}
.cate > span .nice-select.open:after{ transform: rotate(180deg); margin-top: -3px;}

.write_step02{width: 100%; margin-top: 30px; display: none; position: relative;}
.write_step02.active{display: block;}
.write_step02 .q_list{background: #fbfbfb; border-radius: 16px; border: 1px solid #e8e8e8; padding: 30px; width: 100%;}
.write_step02 .q_list > h4{font-size: 16px; color: #666;}
.write_step02 .q_list > a{font-size: 14px; text-decoration: underline; position: absolute; right: 30px; top: 30px;}
.write_step02 .q_list > ul{width: 100%; margin-top: 22px; font-size: 0; display: table;}
.write_step02 .q_list > ul > li{width: 234px; border-left: 1px solid #e8e8e8; display: table-cell; vertical-align: top; font-size: 14px; line-height: 20px; padding: 0 22px;}
.write_step02 .q_list > ul > li:first-child{padding-left: 0; border-left: 0; width: 212px;}
.write_step02 .q_list > ul > li:last-child{padding-right: 0; width: 212px;}

.write_step02 .btn_box_left{margin-top: 50px; text-align: left; display: block;}
.write_step02 .btn_box_left.off{display: none;}
.write_step02 .btn_box_left > h3{font-size: 16px; font-weight: 700;}
.write_step02 .btn_box_left > button.next_step{margin-top: 12px; width: 110px; height: 34px; background: #666; font-size: 14px; color: #fff; border-radius: 4px;}

.write_step03{margin-top: 40px; width: 100%;  display: none;}
.write_step03.active{display: block;}

.write_step03 .write_box {width: 100%; position: relative;}
.write_step03 .write_box > h4{font-size: 16px; font-weight: 700;}
.write_step03 .write_box > span{font-size: 13px; color: #ff911b; position: absolute; top: 0; right: 0;}
.write_step03 .write_box .text_area{margin-top: 12px; width: 100%;  position: relative; border-radius: 8px; border: 1px solid #e8e8e8; padding: 24px 10px}
.write_step03 .write_box .text_area > textarea{width: 100%; border: 0; outline: none; height: 100px; overflow-y: auto; padding: 0 10px; font-size: 14px; line-height: 20px; color: #000; resize:none; }
.write_step03 .write_box .text_area > textarea::-moz-placeholder{color: #c8c8c8;}
.write_step03 .write_box .text_area > textarea:-ms-input-placeholder{color: #c8c8c8;}
.write_step03 .write_box .text_area > textarea::placeholder{color: #c8c8c8;}
.write_step03 .write_box > p{text-align: right; font-size: 13px; line-height: 18px; margin-top: 8px; color: #999;}
.write_step03 .write_box > p > b{color: #222020;}
.write_step03 .file_add_ar{width: 100%; margin-top: 50px;}
.write_step03 .file_add_ar > h4{font-size: 16px; font-weight: 700;}
.write_step03 .file_add_ar > p{margin-top: 6px; font-size: 12px; line-height: 18px;}
.filebox {width: 100%; margin-top: 12px;}
.filebox input[type="file"] {position: absolute; width: 0; height: 0; padding: 0; overflow: hidden; border: 0;}
.filebox label {display: inline-block; text-align: center; font-size: 14px; color: #fff; width: 81px; line-height: 34px; vertical-align: middle; background-color: #666; cursor: pointer; height: 34px; border-radius: 4px;}
.filebox > span {display: inline-block; padding: 0 10px; vertical-align: middle; color: #666; font-size: 12px; text-decoration: underline;}

.btn_area_center{margin-top: 60px; text-align: center; font-size: 0;}
.btn_area_center .btn_register{width: 200px; height: 54px; background: #f93964; color: #fff; border-radius: 8px; font-size: 16px; margin: 0 4px;}
.btn_area_center .btn_cancel{width: 200px; height: 54px; background: #fff; color: #000; border: 1px solid #e8e8e8; border-radius: 8px; font-size: 16px; margin: 0 4px;}


.write_step03.edit{display: block;}
.write_step03.edit .write_box .text_area > textarea{height: 345px;}
.write_step03.edit  .write_box > p{font-size: 0; display: table; width: 100%;}
.write_step03.edit  .write_box > p > span{display: table-cell; text-align: right; vertical-align: middle; font-size: 13px; color: #999;}
.write_step03.edit  .write_box > p > span > b{color: #222020;}
.write_step03.edit  .write_box > p > span:first-child{text-align: left;}
.write_step03.edit  .write_box > p > span > i{display: inline-block; padding-left: 12px; background: url(/_next/static/media/icon-attached.5df325a1.svg) left center no-repeat; font-size: 12px; color: #000;}
.write_step03.edit  .write_box > p > span > a{display: inline-block; margin-left: 8px; font-size: 12px; color: #666; text-decoration: underline; cursor: pointer;}


.pop_content .img_view{width: 100%; height: 217px; border: 1px solid #e8e8e8;}
.pop_content .img_view img{width: 100%;}

.list_type01 > li .q_ar{width: 100%; display: table; }
.list_type01 > li .q_ar > div{display: table-cell; text-align: right; vertical-align: middle;}
.list_type01 > li .q_ar > div:first-child{text-align: left;}
.list_type01 > li .q_ar > div > p{width: 100%; font-size: 0; margin-top: 10px;}
.list_type01 > li .q_ar > div > p:first-child{margin-top: 0;}
.list_type01 > li .q_ar > div > p > em{display: inline-block; font-size: 14px; color: #666; vertical-align: middle;}
.list_type01 > li .q_ar > div > p > b{display: inline-block; font-size: 14px; color: #000; margin-left: 10px; vertical-align: middle; padding-right: 20px;}
.list_type01 > li .q_ar > div > p > b > i.pic{ width: 12px; height: 12px; background: url(/_next/static/media/text_img.9ab4ec91.svg) no-repeat; display: inline-block; background-size: 100%; margin-left: 5px; vertical-align: unset;}
.list_type01 > li .q_ar > div > p > span{padding-left: 20px; border-left: 1px solid #e8e8e8; font-size: 14px; color: #666; vertical-align: middle;}
.list_type01 > li .q_ar > div > p > span > b{margin-left: 10px;}
.list_type01 > li .q_ar > div > i{display: block; font-size: 13px; color: #999;}
.list_type01 > li .q_ar > div > strong{display: block; font-size: 12px; }
.list_type01 > li .q_ar > div > strong.comp{color: #f93964;}
.list_type01 > li .q_ar > div > strong.impar{color: #999;}
.list_type01 > li .a_ar{width: 100%; padding-top: 29px; display: table; position: relative;}
.list_type01 > li .a_ar > span{display: table-cell; height: 50px; background: #fff3e6; vertical-align: middle; font-size: 14px; padding-left: 20px; border-top-left-radius: 0; border-top-right-radius: 8px; border-bottom-left-radius: 0; border-bottom-right-radius: 8px; }
.list_type01 > li .a_ar > span:first-child{width: 64px; background: #ffe5ca; text-align: center; padding-left: 0; border-top-left-radius: 8px; border-top-right-radius: 0; border-bottom-left-radius: 8px; border-bottom-right-radius: 0; }
.list_type01 > li .a_ar:after{content: ' '; height: 0; width: 0; left: 24px; top: 17px; position: absolute; border-top: 5px solid transparent; border-right: 14px solid #ffe5ca; border-bottom: 5px solid transparent; z-index: 2; rotate: 90deg;}


.list_type01 > li .left_area{display: table-cell; text-align: left; vertical-align: middle;}
.list_type01 > li .left_area > p{width: 100%; display: table; margin-top: 16px;}
.list_type01 > li .left_area > p:first-child{margin-top: 0;}
.list_type01 > li .left_area > p > span{display: table-cell; vertical-align: top; font-size: 14px;}
.list_type01 > li .left_area > p > span:first-child{width: 93px; color: #666;}
.list_type01 > li .left_area > p > span > strong{display: block; font-weight: 700; font-size: 14px; color: #f93964;}
.list_type01 > li .left_area > p > span > em{display: block; font-size: 14px; margin-top: 4px;}
.list_type01 > li .btn_ar{display: table-cell; width: 82px; text-align: center; vertical-align: middle;}
.list_type01 > li .btn_ar > button{width: 100%; height: 34px; border: 1px solid #e8e8e8; background: #fff; border-radius: 4px; font-size: 14px;}
.list_type01 > li .right_area{display: table-cell; width: 156px; text-align: right; vertical-align: middle;}
.list_type01 > li .right_area > span{display: block; font-size: 13px; color: #999;}
.list_type01 > li .right_area > strong{display: block; font-size: 12px;}
.list_type01 > li .right_area > strong.comp{color: #f93964;}
.list_type01 > li .right_area > strong.cancel{color: #999;}


.inquiry_add{width: 1024px; margin: 0 auto;}
.inquiry_add .ex_box{width: 720px; margin: 0 auto; border: 1px solid #e8e8e8; background: #fbfbfb; padding: 22px 100px; border-radius: 16px;}
.inquiry_add .ex_box > img{width: 100%;}

.add_inp_box{width: 720px; margin: 30px auto 0; padding: 60px 120px; background: #fff; border-radius: 16px;}
.add_inp_box > ul{width: 100%;}
.add_inp_box > ul > li{width: 100%; margin-top: 50px; position: relative;}
.add_inp_box > ul > li:first-child{margin-top: 0;}
.add_inp_box > ul > li > h5{font-size: 16px; font-weight: 700;}
.add_inp_box > ul > li > em{font-size: 13px; color: #ff911b; position: absolute; top: 0; right: 0;}
.add_inp_box > ul > li > input{width: 100%; height: 48px; margin-top: 3px; border-bottom: 1px solid #e8e8e8; font-size: 20px; font-weight: 900;}
.add_inp_box > ul > li > input::-moz-placeholder{font-weight: 400; color: #c8c8c8;}
.add_inp_box > ul > li > input:-ms-input-placeholder{font-weight: 400; color: #c8c8c8;}
.add_inp_box > ul > li > input::placeholder{font-weight: 400; color: #c8c8c8;}
.add_inp_box > ul > li .inp_ar{width: 100%; border: 1px solid #e8e8e8; border-radius: 8px; font-size: 0; margin-top: 12px;}
.add_inp_box > ul > li .inp_ar.focus{border-color: #f93964;}
.add_inp_box > ul > li .inp_ar .w_l{display: inline-block; padding: 0 20px 0; width: calc(100% - 198px); vertical-align: middle;}
.add_inp_box > ul > li .inp_ar > i{display: inline-block; width: 1px; height: 28px; vertical-align: middle; background: #e8e8e8;}
.add_inp_box > ul > li .inp_ar .w_s{display: inline-block; padding: 0 20px 0; width: 197px; vertical-align: middle;}
.add_inp_box > ul > li .inp_ar .w_xs{display: inline-block; padding: 0 20px 0; width: 150px; vertical-align: middle;}
.add_inp_box > ul > li .inp_ar .w_xl{display: inline-block; padding: 0 20px 0; width: calc(100% - 151px); vertical-align: middle;}

.add_inp_box > ul > li > p{margin-top: 10px; font-size: 12px; line-height: 18px;}
.add_inp_box > ul > li > p > b{color: #f93964;}
.add_inp_box > ul > li > p > span{display: inline-block; margin-left: 16px; font-size: 14px; color: #666;}
.add_inp_box > ul > li > p > span:first-child{margin-left: 0; color: #000; font-weight: 700;}
.add_inp_box > ul > li .btn_box_xs{width: 100%; margin-top: 30px; text-align: center;}
.add_inp_box > ul > li .btn_box_xs > button{width: 82px; height: 34px; border-radius: 4px; background: #666666; color: #fff; font-size: 14px;}
.add_inp_box > ul > li .btn_box_xs > button:disabled{background: #e5e5e5;}



.add_top_box{width: 720px; margin: 0 auto; padding: 60px 120px; background: #fff; border-radius: 16px;}
.add_top_box .common_type{position: relative;}
.add_top_box .common_type > h5{font-size: 16px; font-weight: 700;}
.add_top_box .common_type > i{font-size: 13px; position: absolute; top: 0; right: 0; color: #ff911b;}
.add_top_box .common_type .input-group > input{font-size: 20px; height: 48px; padding: 0;}
.add_top_box .common_type .input-group > input::-moz-placeholder{font-size: 20px !important;}
.add_top_box .common_type .input-group > input:-ms-input-placeholder{font-size: 20px !important;}
.add_top_box .common_type .input-group > input::placeholder{font-size: 20px !important;}
.add_top_box .common_type .input-group > button.all_cash{width: 60px; height: 34px; border: 1px solid #e8e8e8; background: #fff; font-size: 13px; position: absolute; right: 0; border-radius: 4px; margin: 0; bottom: 9px; top: auto;}
.add_top_box .common_type > p{margin-top: 11px; font-size: 13px; color: #999; height: 16px; padding: 0 4px;}
.add_top_box .common_type > span{display: block; margin-top: 6px; font-size: 13px; color: #000; height: 16px; padding: 0 4px;}
.add_top_box .gr_bd_box{width: 100%; height: 78px; margin-top: 40px; border: 1px solid #e8e8e8; background: #fbfbfb; border-radius: 8px; display: table; padding: 0 30px;}
.add_top_box .gr_bd_box > div{display: table-cell; vertical-align: middle; font-size: 16px; text-align: right; color: #c8c8c8; font-weight: 700;}
.add_top_box .gr_bd_box > span{text-align: left; color: #000; display: table-cell; vertical-align: middle; font-size: 16px; font-weight: 700;}
.add_top_box .gr_bd_box > div.disable_cash{display: none;}
.add_top_box .gr_bd_box > div.disable_cash.on{display: table-cell;}
.add_top_box .gr_bd_box > div.active_cash{display: none;}
.add_top_box .gr_bd_box > div.active_cash.on{display: table-cell;}
.add_top_box .gr_bd_box > div.active_cash > strong{display: block; text-align: right; font-size: 16px; color: #f93964; line-height: 19px; font-weight: 700;}
.add_top_box .gr_bd_box > div.active_cash > em{display: block; text-align: right; font-size: 12px; color: #000; line-height: 15px; margin-top: 2px;}


.add_inp_box > ul > li .gr_box{width: 100%; padding: 30px; background: #fbfbfb; border-radius: 8px;}
.add_inp_box > ul > li .gr_box > h3{font-size: 14px; padding-bottom: 10px;}
.add_inp_box > ul > li .gr_box > h3 > i{color: #f93964; margin-right: 5px;}
.add_inp_box > ul > li .gr_box > p{font-size: 12px; line-height: 18px; color: #999; }
.add_inp_box > ul > li .gr_box > p.num_f{ padding-left: 12px; text-indent: -12px;}

.pop_content .bank_select{width: 100%; height: 570px; font-size: 0;}
.pop_content .bank_select button{width: 136px; height: 90px; background: #fbfbfb; margin: 0 0 6px 6px;}
.pop_content .bank_select button:first-child{margin-left: 0;}
.pop_content .bank_select button:nth-child(3n+1){margin-left: 0;}
.pop_content .bank_select button > span{display: block; width: 26px; height: 26px; margin: 0 auto;}
.pop_content .bank_select button > span > img{width: 100%;}
.pop_content .bank_select button > p{margin-top: 11px; font-size: 13px; text-align: center;}

/* 회원정보 */

#mamber_header{width: 100%; background: #f8f8f8;}
#mamber_header .header_inner{width: 1280px; margin: 0 auto; padding: 60px 0 30px;}
#mamber_header .header_inner > a{width: 189px; display: inline-block;}
#mamber_header .header_inner > a > img{width: 100%;}

#member_container{width: 100%; background: #f8f8f8; padding: 30px 0 90px;}
#member_container .container_inner{width: 1280px; margin: 0 auto;}
#member_container .container_inner > h2{font-size: 24px; font-weight: 700; text-align: center;}
#member_container .container_inner .welcome_box{width: 720px; margin: 40px auto 0; background: #fff; border-radius: 16px; padding: 100px 120px 60px;}
#member_container .container_inner .welcome_box .top_title{width: 350px; margin: 0 auto;}
#member_container .container_inner .welcome_box .top_title > img{width: 100%;}
#member_container .container_inner .welcome_box .top_title > span{display: block; font-size: 20px; font-weight: bold; text-align: center;}
#member_container .container_inner .welcome_box > p{text-align: center; font-size: 16px; margin-top: 16px; line-height: 23px; color: #666;}
#member_container .container_inner .welcome_box > p > b{color: #000;}
#member_container .container_inner .welcome_box .btn_outer{text-align: center; margin-top: 44px;}
#member_container .container_inner .welcome_box .btn_outer > button{width: 200px; height: 54px; border-radius: 8px; background: #f93964; color: #fff; font-size: 16px;}
#member_container .container_inner .welcome_box .b_line_box{width: 100%; margin-top: 110px; border: 1px solid #000; border-radius: 8px; background: #fbfbfb; height: 80px; display: table; padding: 0 40px;}
#member_container .container_inner .welcome_box .b_line_box > span{display: table-cell; vertical-align: middle; text-align: right; font-size: 14px; font-weight: 700; }
#member_container .container_inner .welcome_box .b_line_box > span:first-child{text-align: left;}
#member_container .container_inner .welcome_box .b_line_box > span > button{width: 120px; height: 34px; border-radius: 4px; background: #333; color: #fff; font-size: 14px;}


.login_section{width: 100%;}
.login_section .login_top{width: 100%; background: #fff; padding: 60px 0 30px;}
.login_section .login_top .w_inner{width: 1200px; margin: 0 auto;}
.login_section .login_top h2{font-size: 28px; line-height: 29px; font-weight: 700;}
.login_section .login_top h2 > i{padding:0 5px; font-size: 28px; font-weight: 700;}
.login_section .login_top p{font-size: 14px; line-height: 20px; text-align: center; margin-top: 20px;}

.login_section .login_top p > i{color: #f93964;}
.login_section .login_top .find_tab{width: 100%; font-size: 0; text-align: center; padding-top: 40px;}
.login_section .login_top .find_tab > ul{width: 720px; margin: 0 auto; text-align: center;}
.login_section .login_top .find_tab > ul > li{display: inline-block; vertical-align: middle; width: auto; height: 40px; border-radius: 20px; background: #fff; border: 1px solid #e8e8e8; font-size: 16px; line-height: 40px; padding: 0 21px; margin: 0 0 0 10px; cursor: pointer;}
.login_section .login_top .find_tab > ul > li:first-child{margin-left: 0;}
.login_section .login_top .find_tab > ul > li.active{background: #000; border-color: #000; color: #fff;}
.login_section .login_top .find_tab > ul > li > span{display: inline-block;}

.login_content{width: 100%; padding: 60px 0 90px;}
.login_content .certify_area{width: 720px; margin: 0 auto; border-radius: 16px; background: #fff; padding: 60px 120px;}
.login_content .certify_area.in360{padding: 60px 180px;}
.login_content .certify_area > h3{font-size: 18px; font-weight: 700; text-align: center;}
.login_content .certify_area > span{font-size: 14px; text-align: center; display: block; margin-top: 6px;}
.login_content .certify_area > span > i{color: #f93964;}
.login_content .certify_area > p{font-size: 16px; text-align: center; color: #666; line-height: 23px; margin-top: 40px;}
.login_content .certify_area > p > b{color: #000;}
.login_content .certify_area .select_g_box{width: 100%; padding: 30px; background: #fbfbfb; border-radius: 8px; display: table; font-size: 0; margin-top: 30px;}
.login_content .certify_area .select_g_box > span{display: inline-block; padding-left: 40px; margin-right: 0 !important;}
.login_content .certify_area .select_g_box > span:first-child{padding-left: 0;}
.login_content .certify_area .info_text{width: 100%; margin-top: 20px;}
.login_content .certify_area .info_text > p{padding-left: 8px; margin-top: 6px; background: url(/_next/static/media/dot_01.6a71e25e.png) left center no-repeat; background-size: 2px; color: #666; font-size: 13px; line-height: 16px;}
.login_content .certify_area .info_text > p:first-child{margin-top: 0;}
.login_content .certify_area .info_text > p > b{color: #333;}
.login_content .certify_area .btn_2ad{width: 100%; font-size: 0; margin-top: 50px; text-align: center;}
.login_content .certify_area .btn_2ad > button{display: inline-block; width: calc(50% - 4px); margin-left: 8px;}
.login_content .certify_area .btn_2ad > button:first-child{margin-left: 0;}
.login_content .certify_area .btn_2ad > button.hp_certify{height: 54px; border-radius: 8px; background: #f93964; color: #fff; font-size: 16px;}
.login_content .certify_area .btn_2ad > button.kko_certify{height: 54px; border-radius: 8px; background: #ffdd02;}
.login_content .certify_area .btn_2ad > button.kko_certify > span{display: inline-block; padding-left: 62px; height: 19px; line-height: 19px; background: url(/_next/static/media/logo-kakaopay.e920145d.svg) left center no-repeat; font-size: 15px; font-weight: 700;}
.login_content .phone_info{width: 100%; margin-top: 40px;}
.login_content .phone_info .inp_name{width: 100%; font-size: 0;}
.login_content .phone_info .inp_name > span{display: inline-block; width: calc(100% - 49px); vertical-align: middle;}
.login_content .phone_info .inp_name > span:first-child{width: 49px; font-size: 14px; font-weight: bold;}
.login_content .phone_info .inp_name > span > input{width: 100%; border: 0; border-bottom: 1px solid #e8e8e8; font-size: 14px; height: 37px;}
.login_content .phone_info .inp_name > span > input:focus{border-color: #f93964;}
.login_content .phone_info .inp_hp{width: 100%; margin-top: 50px;}
.login_content .phone_info .inp_hp > h4{font-size: 14px; font-weight: 700;}
.login_content .phone_info .inp_hp .in_hp_num{width: 100%; font-size: 0; margin-top: 21px;}
.login_content .phone_info .inp_hp .in_hp_num > span{display: inline-block; width: 130px; vertical-align: bottom; text-align: right;}
.login_content .phone_info .inp_hp .in_hp_num > span:first-child{width: calc(100% - 130px); text-align: left;}
.login_content .phone_info .inp_hp .in_hp_num > span > input{width: 100%; border: 0; border-bottom: 1px solid #e8e8e8; font-size: 14px; height: 37px;}
.login_content .phone_info .inp_hp .in_hp_num > span > input:focus{border-color: #f93964;}
.login_content .phone_info .inp_hp .in_hp_num > span > button{width: 110px; height: 34px; background: #666; border-radius: 4px; color: #fff; font-size: 14px;}
.login_content .phone_info .inp_hp .in_hp_num > span > button:disabled{background: #e5e5e5;}
.login_content .phone_info .inp_hp .certify_num{width: 100%; margin-top: 31px; position: relative;}
.login_content .phone_info .inp_hp .certify_num > input{width: 100%; border: 0; border-bottom: 1px solid #e8e8e8; font-size: 14px; height: 37px;}
.login_content .phone_info .inp_hp .certify_num > input:focus{border-color: #f93964;}
.login_content .phone_info .inp_hp .certify_num .timer{font-size: 16px; color: #f93964; position: absolute; right: 0; top: 7px;}

.login_content .mail_info{width: 100%; margin-top: 40px;}
.login_content .mail_info .inp_name{width: 100%; font-size: 0;}
.login_content .mail_info .inp_name > span{display: inline-block; width: calc(100% - 49px); vertical-align: middle;}
.login_content .mail_info .inp_name > span:first-child{width: 49px; font-size: 14px; font-weight: bold;}
.login_content .mail_info .inp_name > span > input{width: 100%; border: 0; border-bottom: 1px solid #e8e8e8; font-size: 14px; height: 37px;}
.login_content .mail_info .inp_name > span > input:focus{border-color: #f93964;}

.login_content .mail_info .inp_mail{width: 100%; margin-top: 50px;}
.login_content .mail_info .inp_mail > h4{font-size: 14px; font-weight: 700;}
.login_content .mail_info .inp_mail .inp_email{width: 100%; font-size: 0; margin-top: 21px;}
.login_content .mail_info .inp_mail .inp_email > span{display: inline-block; width: 197px; vertical-align: middle; text-align: right;}
.login_content .mail_info .inp_mail .inp_email > span:first-child{width: 262px; text-align: left;}
.login_content .mail_info .inp_mail .inp_email > span:nth-child(2){width: calc(100% - 197px - 262px); text-align: center; font-size: 14px;}
.login_content .mail_info .inp_mail .inp_email > span > input{width: 100%; border: 0; border-bottom: 1px solid #e8e8e8; font-size: 14px; height: 37px;}
.login_content .mail_info .inp_mail .inp_email > span > input:focus{border-color: #f93964;}
.login_content .mail_info .inp_mail .inp_email > span .nice-select{border: 0; height: 37px; border-radius: 0; width: 100%; padding-left: 0; line-height: 37px; font-size: 14px; color: #000; border-bottom: 1px solid #e8e8e8;  }
.login_content .mail_info .inp_mail .inp_email > span .nice-select .list{width: 100%; border-radius: 0; padding: 14px 0;}
.login_content .mail_info .inp_mail .inp_email > span .nice-select .option.selected.focus{background: none; color: #f93964;}
.login_content .mail_info .inp_mail .inp_email > span .nice-select .option:hover{background: none;}
.login_content .mail_info .inp_mail .inp_email > span .nice-select .option{min-height: auto; line-height: 17px; padding: 12px 24px;}
.login_content .mail_info .inp_mail .inp_email > span .nice-select:after{background: url(/_next/static/media/icon-arrow-small.ecafc2cf.svg) center center no-repeat; border: 0; transform-origin: 50% 50%; transform: rotate(0); height: 6px; width: 9px; margin-top: -3px; right: 10px;}
.login_content .mail_info .inp_mail .inp_email > span .nice-select.open:after{ transform: rotate(180deg); margin-top: -3px;}


.login_content .certify_area .select_g_box2{width: 100%; padding: 30px; background: #fbfbfb; border-radius: 8px; display: table; font-size: 0; margin-top: 50px;}
.login_content .certify_area .select_g_box2 > p{display: table-cell; padding-right: 40px; width: 50%; text-align: right; font-size: 16px; font-weight: 700; vertical-align: middle;}
.login_content .certify_area .select_g_box2 > em{display: table-cell; width: 50%; text-align: left; font-size: 13px; color: #666; vertical-align: middle;}
.login_content .certify_area .btn_btm_box{width: 100%; font-size: 0; margin-top: 30px; text-align: center;}
.login_content .certify_area .btn_btm_box > button{display: inline-block; width: calc(50% - 4px); margin-left: 8px; background: #f93964; height: 54px; border-radius: 8px; color: #fff; font-size: 16px; }
.login_content .certify_area .btn_btm_box > button:first-child{margin-left: 0; background: #fff; color: #000; border: 1px solid #e8e8e8;}
.login_content .certify_area .btn_btm_box2{width: 100%; font-size: 0; margin-top: 50px; text-align: center;}
.login_content .certify_area .btn_btm_box2 > button.pink{display: inline-block; width: 200px; background: #f93964; height: 54px; border-radius: 8px; color: #fff; font-size: 16px; }
.login_content .certify_area .btn_btm_box3{width: 100%; font-size: 0; margin-top: 40px; text-align: center;}
.login_content .certify_area .btn_btm_box3 > button{display: inline-block; width: 176px; margin-left: 8px; background: #f93964; height: 54px; border-radius: 8px; color: #fff; font-size: 16px; }
.login_content .certify_area .btn_btm_box3 > button:first-child{margin-left: 0; background: #fff; color: #000; border: 1px solid #e8e8e8;}



.login_content .btm_g_box{width: 720px; margin: 30px auto 0; border: 1px solid #e8e8e8; padding: 35px 60px; font-size: 0; border-radius: 16px; background: #fbfbfb;}
.login_content .btm_g_box > div{width: 290px; display: inline-block; vertical-align: middle;}
.login_content .btm_g_box > div > h5{font-size: 16px; font-weight: 600;}
.login_content .btm_g_box > div > p{font-size: 13px; line-height: 19px; color: #333333; margin-top: 6px;}
.login_content .btm_g_box > div > p > b{font-weight: 700; color: #000;}
.login_content .btm_g_box > div:first-child{width: calc(100% - 290px); display: inline-block;}
.login_content .btm_g_box > div > button.kko_certify{height: 54px; width: 100%; border-radius: 8px; background: #ffdd02;}
.login_content .btm_g_box > div > button.kko_certify > span{display: inline-block; padding-left: 62px; height: 19px; line-height: 19px; background: url(/_next/static/media/logo-kakaopay.e920145d.svg) left center no-repeat; font-size: 15px; font-weight: 700;}
.login_content .b_line_box{width: 720px; margin: 30px auto 0; border: 1px solid #000; border-radius: 16px; background: #fbfbfb; text-align: center; padding: 24px 0; font-size: 0;}
.login_content .b_line_box > span{display: inline-block; font-size: 14px; font-weight: bold; padding-right: 0px; vertical-align: middle;}
.login_content .b_line_box > span:first-child{padding-right: 40px;}
.login_content .b_line_box > span > button{width: 120px; height: 34px; background: #333; color: #fff; border-radius: 4px; font-size: 14px;}

.change_PW{width: 100%; margin-top: 50px;}
.change_PW > h4{font-size: 18px; text-align: center; font-weight: 700;}
.change_PW .chang_password{width: 100%; margin-top: 30px;}

.popup .dim .pop_inner.Dormant{width: 480px; padding: 40px 30px 30px;}
.popup .dim .pop_inner.Dormant > h2{font-size: 24px; font-weight: bold; text-align: center; margin-top: 40px;}
.popup .dim .pop_inner.Dormant > p{font-size: 13px; line-height: 18px; text-align: center; margin-top: 20px; color: #777;}
.popup .dim .pop_inner.Dormant > p > b{color: #000; font-weight: 500;}
.popup .dim .pop_inner.Dormant .bgbox_info{margin-top: 30px; padding: 28px; background: #fbfbfb; border-radius: 8px;}
.popup .dim .pop_inner.Dormant .bgbox_info > p{font-size: 14px; line-height: 20px; font-weight: 700; text-align: center;}
.popup .dim .pop_inner.Dormant .bgbox_info > p:first-child{font-weight: 400;}
.popup .dim .pop_inner.Dormant .bgbox_info > p > i{color: #f93964; font-weight: 700;}

.popup .dim .pop_inner.Dormant .btn_box{font-size: 0; margin-top: 40px;}
.popup .dim .pop_inner.Dormant .btn_box > button{width: calc(50% - 4px); height: 54px; margin-left: 8px; background: #f93964; color: #fff; font-size: 16px; border-radius: 8px; }
.popup .dim .pop_inner.Dormant .btn_box > button:first-child{width: calc(50% - 4px); height: 54px; margin-left: 0px; background: #fff; border: 1px solid #e8e8e8; color: #000; font-size: 16px; }
.popup .dim .pop_inner.Dormant .btn_box > button.full{width: 100%; height: 54px; margin-left: 0px; background: #f93964; color: #fff; font-size: 16px; border-radius: 8px; }



#login_header{width: 100%; background: #fff;}
#login_header .header_inner{width: 1280px; margin: 0 auto; padding: 60px 0 30px;}
#login_header .header_inner > a{width: 189px; display: inline-block;}
#login_header .header_inner > a > img{width: 100%;}

#login_container{width: 100%; background: #fff; padding: 30px 0 90px;}
#login_container .container_inner{width: 1280px; margin: 0 auto;}
#login_container .container_inner > h2{font-size: 28px; font-weight: 700; text-align: center;}
#login_container .container_inner .log_in_box{width: 100%; font-size: 0; text-align: center; margin-top: 70px;}
#login_container .container_inner .log_in_box .login_inp{width: 440px; display: inline-block; vertical-align: top;}
#login_container .container_inner .log_in_box .login_inp .inner_border{width: 100%; border: 1px solid #e8e8e8; border-radius: 16px; padding: 40px 40px 30px;}
#login_container .container_inner .log_in_box .ad_box{width: 440px; display: inline-block; margin-left: 30px; vertical-align: top; padding-top: 78px;}
#login_container .container_inner .log_in_box .ad_box > img{width: 100%;}

#login_container .container_inner .log_in_box .inp_box{width: 100%; text-align: left;}
#login_container .container_inner .log_in_box .inp_box .in_inp{border: 1px solid #e8e8e8; border-top-left-radius: 8px; border-top-right-radius: 8px; width: 100%; display: table;  padding: 20px 23px;}
#login_container .container_inner .log_in_box .inp_box .in_inp > span{display: table-cell; vertical-align: middle; text-align: left;}
#login_container .container_inner .log_in_box .inp_box .in_inp > span:first-child{width: 79px; font-size: 14px; font-weight: 700; line-height: 17px;}
#login_container .container_inner .log_in_box .inp_box .in_inp > span > input{width: 100%; height: auto; border: 0; background: 0; font-size: 14px; line-height: 17px; padding: 0;}
#login_container .container_inner .log_in_box .inp_box .in_inp > span > input::-moz-placeholder{color: #c8c8c8;}
#login_container .container_inner .log_in_box .inp_box .in_inp > span > input:-ms-input-placeholder{color: #c8c8c8;}
#login_container .container_inner .log_in_box .inp_box .in_inp > span > input::placeholder{color: #c8c8c8;}

#login_container .container_inner .log_in_box .inp_box .in_pw{border: 1px solid #e8e8e8; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px; width: 100%; display: table;  padding: 20px 23px; border-top: 0;}
#login_container .container_inner .log_in_box .inp_box .in_pw > span{display: table-cell; vertical-align: middle; text-align: left;}
#login_container .container_inner .log_in_box .inp_box .in_pw > span:first-child{width: 79px; font-size: 14px; font-weight: 700; line-height: 17px;}
#login_container .container_inner .log_in_box .inp_box .in_pw > span > input{width: 100%; height: auto; border: 0; background: 0; font-size: 14px; line-height: 17px;}
#login_container .container_inner .log_in_box .inp_box .in_pw > span > input::-moz-placeholder{color: #c8c8c8;}
#login_container .container_inner .log_in_box .inp_box .in_pw > span > input:-ms-input-placeholder{color: #c8c8c8;}
#login_container .container_inner .log_in_box .inp_box .in_pw > span > input::placeholder{color: #c8c8c8;}
#login_container .container_inner .log_in_box .inp_box .ch_area{margin-top: 14px;}
#login_container .container_inner .log_in_box .login_btn{padding-top: 105px; position: relative;}
#login_container .container_inner .log_in_box .login_btn .recaptcha{width: 100%; position: absolute; top: 33px; text-align: center; display: none;}
#login_container .container_inner .log_in_box .login_btn .recaptcha.on{display: block;}
#login_container .container_inner .log_in_box .login_btn .recaptcha .rct_inner{width: 226px; height: 60px; overflow: hidden; display: inline-block;}
#login_container .container_inner .log_in_box .login_btn .recaptcha .rct_inner > img{width: 100%;}
#login_container .container_inner .log_in_box .login_btn > button.login{ width: 100%; height: 54px; border-radius: 8px; background: #f93964; color: #fff; font-size: 16px;}
#login_container .container_inner .log_in_box .login_btn > button.kko_certify{width: 100%; height: 54px; border-radius: 8px; background: #ffdd02; margin-top: 12px;}
#login_container .container_inner .log_in_box .login_btn > button.kko_certify > span{display: inline-block; padding-left: 62px; height: 19px; line-height: 19px; background: url(/_next/static/media/logo-kakaopay.e920145d.svg) left center no-repeat; font-size: 15px; font-weight: 700;}
#login_container .container_inner .log_in_box .login_btn .etc_link{width: 100%; text-align: center; margin-top: 40px;}
#login_container .container_inner .log_in_box .login_btn .etc_link > li{display: inline-block; vertical-align: middle; padding: 0 14px; border-left: 1px solid #c8c8c8; }
#login_container .container_inner .log_in_box .login_btn .etc_link > li:first-child{border: 0;}
#login_container .container_inner .log_in_box .login_btn .etc_link > li > a{display: inline-block; font-size: 13px; color: #666; line-height: 16px;}
#login_container .container_inner .log_in_box .login_banner{width: 100%; margin-top: 30px; border-radius: 16px; overflow: hidden; display: block;}
#login_container .container_inner .log_in_box .login_banner > img{width: 100%;}

.check_box_xs {width: auto; position: relative; display: inline-block; cursor: pointer;}
.check_box_xs input[type="checkbox"] + label {display: inline-block; background: url(/_next/static/media/button-checkbox-btn-checkbox-large.e7d852bc.svg) no-repeat; background-size: 16px auto; background-position:left top; padding: 0px 0 0px 22px; font-size: 13px;line-height: 16px; color: #000; height: 16px;}
.check_box_xs input[type="checkbox"]:checked + label{background: url(/_next/static/media/button-checkbox-large-on.36c1af80.svg) no-repeat; background-size: 16px auto; background-position:left top; font-size: 13px; line-height: 16px;}


.top_password {width: 100%; padding-bottom: 10px;}
.top_password .now_password > P{font-size: 13px; margin-top: 10px; color: #999;}

.provision_outer{width: 100%; background: #fbfbfb; padding: 30px 10px 30px 30px;}
.provision_outer .provision{width: 100%; height: 420px; }
.provision_outer .provision > div{padding-right: 20px;}

.popup .dim .pop_inner .btn_box{font-size: 0; margin-top: 40px;}
.popup .dim .pop_inner .btn_box > button{width: calc(50% - 4px); height: 54px; margin-left: 8px; background: #f93964; color: #fff; font-size: 16px; border-radius: 8px; }
.popup .dim .pop_inner .btn_box > button:first-child{width: calc(50% - 4px); height: 54px; margin-left: 0; background: #fff; border: 1px solid #e8e8e8; color: #000; font-size: 16px; }
.popup .dim .pop_inner .btn_box > button.full{width: 100%; height: 54px; margin-left: 0px; background: #f93964; color: #fff; font-size: 16px; border-radius: 8px; }

.popup .dim .pop_inner.pop_provision .btn_box{margin-top: 30px; padding: 0 200px;}

.popup .dim .pop_inner.cash_lock{width: 412px; padding: 40px 30px 30px;}
.popup .dim .pop_inner.cash_lock .pop_header > p{margin-top: 16px; padding: 0; font-size: 14px; text-align: left;}
.popup .dim .pop_inner.cash_lock .pop_header > p > b{font-size: 14px; font-weight: 700;}

.popup .dim .pop_inner.cash_lock .lock_top{width: 100%; padding-top: 20px;}
.popup .dim .pop_inner.cash_lock .lock_top > h2{padding-top: 88px; background: url(/_next/static/media/icon-lock.d4fc7b73.svg) top center no-repeat; font-size: 18px; font-weight: 700; text-align: center;}
.popup .dim .pop_inner.cash_lock .lock_top > p{margin-top: 16px; text-align: center; font-size: 13px; line-height: 19px; color: #666;}
.popup .dim .pop_inner.cash_lock .btn_box{width: 100%; margin-top: 50px; font-size: 0;}
.popup .dim .pop_inner.cash_lock .btn_box > button{width: calc(50% - 4px); height: 54px; margin-left: 8px; background: #f93964; color: #fff; font-size: 16px; border-radius: 8px; }
.popup .dim .pop_inner.cash_lock .btn_box > button:first-child{width: calc(50% - 4px); height: 54px; margin-left: 0px; background: #fff; border: 1px solid #e8e8e8; color: #000; font-size: 16px; }
.popup .dim .pop_inner.cash_lock .btn_box > button.full{width: 100%; height: 54px; margin-left: 0px; background: #f93964; color: #fff; font-size: 16px; border-radius: 8px; }


.popup .dim .pop_inner.push_info{width: 412px; padding: 40px 30px 30px;}
.popup .dim .pop_inner.push_info .push_top{width: 100%; padding-top: 20px;}
.popup .dim .pop_inner.push_info .push_top > h2{font-size: 18px; font-weight: 700; text-align: center;}
.popup .dim .pop_inner.push_info .push_top .date{margin-top: 20px; text-align: center; font-size: 14px; color: #f93964;}
.popup .dim .pop_inner.push_info .memo_c{width: 100%; margin-top: 12px; padding: 16px 100px; background: #fbfbfb; border-radius: 8px;}
.popup .dim .pop_inner.push_info .memo_c > li{width: 100%; display: table;}
.popup .dim .pop_inner.push_info .memo_c > li > span{display: table-cell; text-align: right; font-weight: 700; font-size: 13px;}
.popup .dim .pop_inner.push_info .memo_c > li > span:first-child{text-align: left; font-weight: 400;}
.popup .dim .pop_inner.push_info .btm_info{margin-top: 50px; text-align: center; font-size: 13px; line-height: 18px; color: #777;}
.popup .dim .pop_inner.push_info .btm_info > b{font-size: 13px; color: #000; text-decoration: underline;}
.popup .dim .pop_inner.push_info .btn_box{margin-top: 16px;}
.popup .dim .pop_inner.push_info .btn_box > button{width: calc(50% - 4px); height: 54px; margin-left: 8px; background: #f93964; color: #fff; font-size: 16px; border-radius: 8px; }
.popup .dim .pop_inner.push_info .btn_box > button:first-child{width: calc(50% - 4px); height: 54px; margin-left: 0px; background: #fff; border: 1px solid #e8e8e8; color: #000; font-size: 16px; }
.popup .dim .pop_inner.push_info .btn_box > button.full{width: 100%; height: 54px; margin-left: 0px; background: #fff; border: 1px solid #e8e8e8; color: #000;  border-radius: 8px; }

.popup .dim .pop_inner.receive_Consent{width: 480px; padding: 40px 30px 30px;}
.popup .dim .pop_inner.receive_Consent .Consent_top{width: 100%; padding-top: 20px;}
.popup .dim .pop_inner.receive_Consent .Consent_top > h2{padding-top: 102px; background: url(/_next/static/media/icon_alram@2x.b4e98f52.png) top center no-repeat; background-size: 80px; font-size: 22px; line-height: 28px; font-weight: 700; text-align: center;}
.popup .dim .pop_inner.receive_Consent .Consent_top > p{ margin-top: 16px; text-align: center; font-size: 14px; line-height: 20px;}
.popup .dim .pop_inner.receive_Consent .btm_info{margin-top: 50px; text-align: center; font-size: 13px; line-height: 18px; color: #777;}
.popup .dim .pop_inner.receive_Consent .btm_info > b{font-size: 13px; color: #000; text-decoration: underline;}
.popup .dim .pop_inner.receive_Consent .btn_box{width: 100%; margin-top: 50px; font-size: 0; text-align: center;}
.popup .dim .pop_inner.receive_Consent .btn_box > button{width: calc(50% - 4px); height: 54px; margin-left: 8px; background: #f93964; color: #fff; font-size: 16px; border-radius: 8px; }
.popup .dim .pop_inner.receive_Consent .btn_box > button:first-child{width: calc(50% - 4px); height: 54px; margin-left: 0px; background: #fff; border: 1px solid #e8e8e8; color: #000; font-size: 16px; }
.popup .dim .pop_inner.receive_Consent .btn_box > button.full{width: 100%; height: 54px; margin-left: 0px; background: #f93964; color: #fff; font-size: 16px; border-radius: 8px; border: 0;}
.popup .dim .pop_inner.receive_Consent .btn_box > button.NextTime{width: auto; height: auto; display: inline-block; padding: 0; margin-top: 20px; line-height: 18px; font-size: 13px; color: #666; border-radius: 0; background: #fff;}

.popup .dim .pop_inner.KKO_pop{width: 412px; padding: 40px 30px 30px;}
.popup .dim .pop_inner.KKO_pop .kko_area{width: 100%; margin-top: 30px;}
.popup .dim .pop_inner.KKO_pop .kko_area > h2{font-size: 18px; font-weight: 700; line-height: 21px;}
.popup .dim .pop_inner.KKO_pop .kko_area > p{font-size: 14px; margin-top: 16px; line-height: 17px;}
.popup .dim .pop_inner.KKO_pop .kko_area > ul{width: 100%; margin-top: 10px;}
.popup .dim .pop_inner.KKO_pop .kko_area > ul > li{height: 68px; padding-top: 40px;}
.popup .dim .pop_inner.KKO_pop .kko_area > ul > li .inp_action2{width: 100%; }
.popup .dim .pop_inner.KKO_pop .kko_area > ul > li .inp_action2 > input{font-size: 16px; font-weight: 500;}
.popup .dim .pop_inner.KKO_pop .btn_box{margin-top: 30px;}

.popup .dim .pop_inner.KKO_center{width: 412px; padding: 40px 30px 30px;}
.popup .dim .pop_inner.KKO_center > h2{font-size: 24px; font-weight: bold; text-align: center; margin-top: 40px;}
.popup .dim .pop_inner.KKO_center > p{font-size: 13px; line-height: 18px; margin-top: 20px; color: #777; padding-left: 8px; background: url(/_next/static/media/dot_999.9597bd7a.png) left 6px no-repeat; background-size: 2px; text-align: left;}
.popup .dim .pop_inner.KKO_center > p > b{color: #000; font-weight: 500;}
.popup .dim .pop_inner.KKO_center .bgbox_info{margin-top: 30px; padding: 28px; background: #fbfbfb; border-radius: 8px;}
.popup .dim .pop_inner.KKO_center .bgbox_info > p{font-size: 13px; line-height: 19px; text-align: center;}
.popup .dim .pop_inner.KKO_center .bgbox_info > p:first-child{font-weight: 400;}
.popup .dim .pop_inner.KKO_center .bgbox_info > p > b{font-weight: 700;}
.popup .dim .pop_inner.KKO_center .bgbox_info > p > i{color: #f93964; margin-top: 10px; display: block;}

.popup .dim .pop_inner.KKO_center .btn_box{font-size: 0; margin-top: 20px;}
.popup .dim .pop_inner.KKO_center .btn_box > button{width: calc(50% - 4px); height: 54px; margin-left: 8px; background: #f93964; color: #fff; font-size: 16px; border-radius: 8px; }
.popup .dim .pop_inner.KKO_center .btn_box > button:first-child{width: calc(50% - 4px); height: 54px; margin-left: 0px; background: #fff; border: 1px solid #e8e8e8; color: #000; font-size: 16px; }
.popup .dim .pop_inner.KKO_center .btn_box > button.full{width: 100%; height: 54px; margin-left: 0px; background: #f93964; color: #fff; font-size: 16px; border-radius: 8px; }

#member_container .stap_box{width: 720px; margin: 40px auto; border: 1px solid #e8e8e8; background: #fbfbfb; text-align: center; font-size: 0; padding: 35px 0; border-radius: 16px;}
#member_container .stap_box > span{display: inline-block; margin-left: 30px; line-height: 30px; font-size:16px; color: #333;}
#member_container .stap_box > span:first-child{margin-left: 0;}
#member_container .stap_box > span > i{display: inline-block; width: 28px; height: 28px; line-height: 30px; border-radius: 14px; background: #e5e5e5; color: #fff; font-size: 16px; font-weight: 700; text-align: center; margin-right: 12px;}
#member_container .stap_box > span > em{font-size: 14px;}
#member_container .stap_box > span.on{color: #000; font-weight: 700;}
#member_container .stap_box > span.on > i{background: #000;}
#member_container .stap_box > span.on > em{color: #000; font-weight: 700;}
#member_container .w_box{width: 720px; margin: 30px auto 0; background: #fff; border-radius: 16px; text-align: center; padding: 110px 0;}
#member_container .w_box > p{font-size: 14px;}
#member_container .w_box > h5{font-size: 18px; font-weight: 700; margin-top: 6px;}
#member_container .w_box .btn_outer{margin-top: 60px;}
#member_container .w_box .btn_outer > button{width: 200px; height: 54px; border-radius: 8px; background: #f93964; font-size: 16px; color: #fff;}
#member_container .info_box{width: 720px; margin: 30px auto 0;}
#member_container .info_box > p{margin-top: 5px; font-size: 13px; background: url(/_next/static/media/dot_01.6a71e25e.png) left 7px no-repeat; background-size: 2px; padding-left: 8px; color: #666; line-height: 19px;}
#member_container .info_box > p:first-child{margin-top: 0;}
#member_container .join_top{width: 720px; margin: 30px auto 0; background: #fff; border-radius: 16px; padding: 60px 120px;}
#member_container .join_top > h3{font-size: 18px; font-weight: 700; text-align: center;}
#member_container .join_top .bg_info_box{width: 100%; background: #fbfbfb; border-radius: 8px; padding: 30px; margin-top: 30px;}
#member_container .join_top .bg_info_box > p{width: 100%; display: table; margin-top: 10px;}
#member_container .join_top .bg_info_box > p:first-child{margin-top: 0;}
#member_container .join_top .bg_info_box > p > span{display: table-cell; vertical-align: baseline; font-size: 14px; color: #666;}
#member_container .join_top .bg_info_box > p > span:first-child{width: 91px; color: #000; font-weight: 700; }
#member_container .join_top .in_info{width: 100%; margin-top: 20px;}
#member_container .join_top .in_info > p{padding-left: 8px; margin-top: 6px; background: url(/_next/static/media/dot_01.6a71e25e.png) left 6px no-repeat; background-size: 2px; color: #666; font-size: 13px; line-height: 16px; min-height: 16px;}
#member_container .join_top .in_info > p:first-child{margin-top: 0;}
#member_container .join_top .in_info > p > b{color: #000; line-height: 16px;}
#member_container .join_inp{width: 720px; margin: 30px auto 0; background: #fff; border-radius: 16px; padding: 60px 120px;}
#member_container .join_inp > ul{width: 100%;}
#member_container .join_inp > ul > li{width: 100%;}
#member_container .join_inp > ul > li > h4{font-size: 14px; font-weight: 700; margin-top: 50px;}
#member_container .join_inp > ul > li > h4.non_mgt{margin-top: 0;}
#member_container .join_inp > ul > li .inp_area{width: 100%; display: table; margin-top: 10px;}
#member_container .join_inp > ul > li .inp_area > span{display: table-cell; vertical-align: bottom;}
#member_container .join_inp > ul > li .inp_area > span > input{width: 100%; height: 37px; border: 0; border-bottom: 1px solid #e8e8e8;}
#member_container .join_inp > ul > li .inp_area > span > input:focus{border-color: #f93964;}
#member_container .join_inp > ul > li.error .inp_area > span > input{border-color: #f93964;} /* 입력갑 error시 노출 */
#member_container .join_inp > ul > li .inp_area > span:last-child{padding-left: 20px; width: 102px;}
#member_container .join_inp > ul > li .inp_area > span > button{width: 100%; height: 34px; border-radius: 4px; background: #666; font-size: 14px; color: #fff;}
#member_container .join_inp > ul > li > p{margin-top: 12px; font-size: 13px; color: #999999;}
#member_container .join_inp > ul > li > p.warning{color: #f93964; display: none;}
#member_container .join_inp > ul > li.error > p.normal{ display: none;}
#member_container .join_inp > ul > li.error > p.warning{color: #f93964; display: block;}

#member_container .join_inp > ul > li .mail_box{width: 100%; display: table; margin-top: 10px;}
#member_container .join_inp > ul > li .mail_box > span{display: table-cell; vertical-align: bottom;  width: 155px;}
#member_container .join_inp > ul > li .mail_box > span > input{width: 100%; height: 37px; border: 0; border-bottom: 1px solid #e8e8e8;}
#member_container .join_inp > ul > li .mail_box > span:first-child{width: 202px;}
#member_container .join_inp > ul > li .mail_box > span:nth-child(2){width: 21px; text-align: center; vertical-align: middle;}
#member_container .join_inp > ul > li .mail_box > span:last-child{padding-left: 20px; width: 102px;}
#member_container .join_inp > ul > li .mail_box > span .nice-select{border: 0; height: 37px; border-radius: 0; width: 100%; padding-left: 0; line-height: 37px; font-size: 14px; color: #000; border-bottom: 1px solid #e8e8e8; }
#member_container .join_inp > ul > li .mail_box > span .nice-select .list{width: 100%; border-radius: 0; padding: 14px 0;}
#member_container .join_inp > ul > li .mail_box > span .nice-select .option.selected.focus{background: none; color: #f93964;}
#member_container .join_inp > ul > li .mail_box > span .nice-select .nice-select .option:hover{background: none;}
#member_container .join_inp > ul > li .mail_box > span .nice-select .option{min-height: auto; line-height: 17px; padding: 12px 24px;}

#member_container .join_inp > ul > li .mail_box > span .nice-select:after{background: url(/_next/static/media/icon-arrow-small.ecafc2cf.svg) center center no-repeat; border: 0; transform-origin: 50% 50%; transform: rotate(0); height: 6px; width: 9px; margin-top: -3px; right: 10px;}
#member_container .join_inp > ul > li .mail_box > span .nice-select.open:after{ transform: rotate(180deg); margin-top: -3px;}
#member_container .join_inp > ul > li .mail_box > span > button{width: 100%; height: 34px; border-radius: 4px; background: #666; font-size: 14px; color: #fff;}
#member_container .join_inp > ul > li .inp_pw{margin-top: 15px;}
#member_container .join_inp > ul > li .inp_pw p.normal{margin-top: 12px; font-size: 13px; color: #999999; display: block;}
#member_container .join_inp > ul > li .inp_pw p.warning{margin-top: 12px; font-size: 13px; color: #f93964; display: none;}
#member_container .join_inp > ul > li .inp_pw.error p.normal{display: none;}
#member_container .join_inp > ul > li .inp_pw.error p.warning{display: block;}
#member_container .join_inp > ul > li .inp_pw .inp_action2{padding-top: 10px; height: 38px;}
#member_container .join_inp > ul > li .inp_pw.error .inp_action2 > input{border-color: #f93964;}
#member_container .join_inp > ul > li .inp_pw2{margin-top: 20px;}
#member_container .join_inp > ul > li .inp_pw2 .inp_action2{padding-top: 10px; height: 38px;}
#member_container .join_inp > ul > li .inp_pw2 p.normal{margin-top: 12px; font-size: 13px; color: #999999; display: block;}
#member_container .join_inp > ul > li .inp_pw2 p.warning{margin-top: 12px; font-size: 13px; color: #f93964; display: none;}
#member_container .join_inp > ul > li .inp_pw2.error p.normal{display: none;}
#member_container .join_inp > ul > li .inp_pw2.error p.warning{display: block;}
#member_container .join_inp > ul > li .inp_pw2.error .inp_action2 > input{border-color: #f93964;}

#member_container .join_agree{width: 720px; margin: 30px auto 0; background: #fff; border-radius: 16px; padding: 60px 120px;}
#member_container .join_agree .all_agree{width: 100%; border: 1px solid #f93964; border-radius: 8px; padding: 20px;}
#member_container .join_agree > ul{width: 100%; padding-top: 10px;}
#member_container .join_agree > ul > li{width: 100%; margin-top: 20px;}
#member_container .join_agree > ul > li .agree_inner{width: 100%; display: table;}
#member_container .join_agree > ul > li .agree_inner .check_box_m{display: table-cell; text-align: left;}
#member_container .join_agree > ul > li .agree_inner .substance{display: table-cell; text-align: right;}
#member_container .join_agree > ul > li .agree_inner .substance > button{width: auto; background: none; font-size: 12px; color: #999; border-bottom: 1px solid #999; line-height: 15px; height: 15px;}

.check_box_m {width: auto; position: relative; display: inline-block; cursor: pointer;}
.check_box_m input[type="checkbox"] + label {display: inline-block; background: url(/_next/static/media/checkbox-m-check-off.39920639.svg) no-repeat; background-size: 15px auto; background-position:1px top; padding: 0px 0 0px 28px; font-size: 13px;line-height: 15px; color: #000; height: 15px;}
.check_box_m input[type="checkbox"] + label > b{color: #f93964;}
.check_box_m input[type="checkbox"] + label > i{color: #999999;}
.check_box_m input[type="checkbox"]:checked + label{background: url(/_next/static/media/checkbox-m-check-on.ee7858d5.svg) no-repeat; background-size: 15px auto; background-position:1px top; font-size: 13px; line-height: 15px;}
#member_container .join_agree > ul > li > p{margin-top: 12px; padding-left: 28px; font-size: 11px; line-height: 16px; color: #333; font-weight: 600;}
#member_container .join_agree > ul > li .choise_chack{padding-left: 28px; margin-top: 17px; font-size: 0; text-align: left;}
#member_container .join_agree > ul > li .choise_chack > span{display: inline-block; margin-left: 40px;}
#member_container .join_agree > ul > li .choise_chack > span:first-child{margin-left: 0 !important;}

#member_container .join_agree .btn_outer{text-align: center; margin-top: 52px;}
#member_container .join_agree .btn_outer > button{width: 200px; height: 54px; border-radius: 8px; background: #f93964; color: #fff; font-size: 16px;}

#member_container2{width: 100%; background: #fff; padding-bottom: 90px;}
#member_container2 .container_inner{width: 1280px; margin: 0 auto; text-align: center;} 
#member_container2 .container_inner > a{width: 252px; display: inline-block; margin-top: 130px;}
#member_container2 .container_inner > a > img{width: 100%;}
#member_container2 .container_inner > h2{margin-top: 74px; text-align: center; font-size: 24px; padding-top: 255px; font-weight: 700; background: url(/_next/static/media/join_bg@2x.042ac8b1.png) center top no-repeat; background-size: 304px;}
#member_container2 .container_inner > p{font-size: 14px; line-height: 19px; margin-top: 15px; color: #666;}
#member_container2 .container_inner .start_box{width: 1024px; margin: 89px auto 0; background: #fbfbfb; border-radius: 16px; padding: 60px; font-size: 0;}
#member_container2 .container_inner .start_box > h4{font-size: 18px; text-align: center; font-weight: 700;}
#member_container2 .container_inner .start_box .helf_area{width: 444px; display: inline-block; vertical-align: top; margin-top: 24px;}
#member_container2 .container_inner .start_box .helf_area.mgl16{margin-left: 16px;}
#member_container2 .container_inner .start_box .helf_area .bg_box{width: 100%; padding: 37px 40px 32px; border: 1px solid #e8e8e8; background: #fff; border-radius: 16px; min-height: 131px; text-align: left;}
#member_container2 .container_inner .start_box .helf_area .bg_box.bg_01{background: #fff url(/_next/static/media/join_box_img_01@2x.a17099e4.png) 311px 26px no-repeat; background-size: 71px;}
#member_container2 .container_inner .start_box .helf_area .bg_box.bg_02{background: #fff url(/_next/static/media/join_box_img_02@2x.d9b1d2b5.png) 283px 25px no-repeat; background-size: 127px; margin-top: 16px;}
#member_container2 .container_inner .start_box .helf_area .bg_box.bg_03{height: 278px;}
#member_container2 .container_inner .start_box .helf_area .bg_box > h5{font-size: 16px; font-weight: 700;}
#member_container2 .container_inner .start_box .helf_area .bg_box > p{font-size: 12px; line-height: 16px; color: #666;}
#member_container2 .container_inner .start_box .helf_area .bg_box > ul{margin: 15px auto 0; width: auto; text-align: center;  }
#member_container2 .container_inner .start_box .helf_area .bg_box > ul > li{width: 112px; margin:20px 30px 0; display: inline-block;}
#member_container2 .container_inner .start_box .helf_area .bg_box > ul > li:first-child{margin-top: 0;}
#member_container2 .container_inner .start_box .helf_area .bg_box > ul > li:nth-child(2){margin-top: 0;}
#member_container2 .container_inner .start_box .helf_area .bg_box > ul > li > img{width: 100%;}
#member_container2 .container_inner .btn_btm{text-align: center; margin-top: 30px;}
#member_container2 .container_inner .btn_btm > button{width: 200px; height: 54px; border-radius: 8px; background: #f93964; color: #fff; font-size: 16px;}

.event_area{width: 100%; background: #fff;}
.event_area .event_top{width: 100%; background: #fff; padding: 56px 0 30px;}
.event_area .event_top h2{font-size: 28px; line-height: 29px; font-weight: 700;}
.event_area .event_top .event_tab{width: 100%; font-size: 0; text-align: center; padding-top: 40px;}
.event_area .event_top .event_tab > ul{width: 720px; margin: 0 auto; text-align: center;}
.event_area .event_top .event_tab > ul > li{display: inline-block; vertical-align: middle; width: auto; height: 40px; border-radius: 20px; background: #fff; border: 1px solid #e8e8e8; font-size: 16px; line-height: 40px; padding: 0 21px; margin: 0 0 0 10px; cursor: pointer;}
.event_area .event_top .event_tab > ul > li:first-child{margin-left: 0;}
.event_area .event_top .event_tab > ul > li.active{background: #000; border-color: #000; color: #fff;}
.event_area .event_sub_tab{width: 100%; border-top: 1px solid #e8e8e8; border-bottom: 1px solid #e8e8e8; padding-top: 24px; background: #fff;}
.event_area .event_sub_tab > ul{width: 1280px; margin: 0 auto; text-align: center; font-size: 0;}
.event_area .event_sub_tab > ul > li{display: inline-block; padding-bottom: 19px; vertical-align: top; margin: 0 22px; border-bottom: 3px solid #fff; cursor: pointer;}
.event_area .event_sub_tab > ul > li.active{border-bottom: 3px solid #000;}
.event_area .event_sub_tab > ul > li > span{display: inline-block; font-size: 18px; line-height: 21px; vertical-align: middle;}
.event_area .event_sub_tab > ul > li > span > i{font-size: 18px; padding: 0 4px; line-height: 21px;}
.event_area .event_sub_tab > ul > li.active > span{font-weight: 700;}
.event_area .event_sub_tab > ul > li.active > span > i{font-weight: 700;}

.event_content{width: 1280px; margin: 0 auto; padding: 60px 0 90px;}
.event_content .event_list{width: 1280px; margin: 0 auto;}
.event_content .event_list > ul{width: 100%; font-size: 0;}
.event_content .event_list > ul > li{width: 400px; display: inline-block; vertical-align: top; margin: 40px 0 0 40px; padding: 0 0 40px 0;}
.event_content .event_list > ul > li:first-child{margin-left: 0; margin-top: 0;}
.event_content .event_list > ul > li:nth-child(2){margin-top: 0;}
.event_content .event_list > ul > li:nth-child(3){margin-top: 0;}
.event_content .event_list > ul > li:nth-child(3n+1){margin-left: 0;}
.event_content .event_list > ul > li > a{display: block;}
.event_content .event_list > ul > li > a .event_banner_box{width: 100%; height: 200px; overflow: hidden; border-radius: 8px; position: relative;}
.event_content .event_list > ul > li > a .event_banner_box > img{width: 100%; height: 100%;}
.event_content .event_list > ul > li > a > span{display: block; font-size: 14px; line-height: 17px; color: #f93964; margin-top: 22px;}
.event_content .event_list > ul > li > a > h4{font-size: 18px; line-height: 24px; font-weight: 700; margin-top: 8px;}
.event_content .event_list > ul > li > a > p{font-size: 14px; color: #999; margin-top: 8px; line-height: 17px;}
.event_content .event_list > ul > li.end > a .event_banner_box > span{position: absolute; display: block; width: 100%; background: rgba(0, 0, 0, 0.7); height: 44px; line-height: 44px; color: #fff; text-align: center; font-size: 14px; font-weight: 700; bottom: 0; z-index: 2;}
.event_content .event_list > ul.none_data{padding: 180px 0;}
.event_content .event_list > ul.none_data > li{display: block; width: 100%; padding: 0; margin: 0;}
.event_content .event_list > ul.none_data > li > h5{font-size: 16px; font-weight: 700; text-align: center; line-height: 19px;}
.event_content .event_list > ul.none_data > li > p{font-size: 13px; text-align: center; line-height: 16px; margin-top: 6px;}
.event_content .event_list .button_ar{margin-top: 40px; text-align: center;}
.event_content .event_list .button_ar .more{width: 140px; height: 40px; background: #f8f8f8; border-radius: 8px; font-size: 14px;}

.event_area_wiew{width: 100%;}
.event_area_wiew .view_top{width: 1024px; margin: 0 auto; padding: 56px 0 0;}
.event_area_wiew .view_top .title{width: 100%; position: relative;}
.event_area_wiew .view_top .title > h2{font-size: 28px; line-height: 29px; font-weight: 700; text-align: center;}
.event_area_wiew .view_top .title .shere_SnS{position: absolute; display: inline-block; right: 0; bottom: -5px; font-size: 0;}
.event_area_wiew .view_top .title .shere_SnS > button{width: 40px; height: 40px; border-radius: 20px; background: #fff; border: 1px solid #e8e8e8; margin-left: 6px;}
.event_area_wiew .view_top .title .shere_SnS > button.FB{background: #fff url(/_next/static/media/SNS_FB@2x.283b3701.png) center center no-repeat; background-size: 40px;}
.event_area_wiew .view_top .title .shere_SnS > button.KKO{background: #fff url(/_next/static/media/SNS_KKO@2x.6b2c0cc0.png) center center no-repeat; background-size: 40px;}
.event_area_wiew .view_top .title .shere_SnS > button.URL{background: #fff url(/_next/static/media/SNS_URL@2x.10cb606a.png) center center no-repeat; background-size: 40px;}
.event_area_wiew .view_top > h3{margin-top: 40px; text-align: center; font-size: 24px;}
.event_area_wiew .view_top .event_info{width: 100%; font-size: 0; text-align: center; margin-top: 12px;}
.event_area_wiew .view_top .event_info > span{display: inline-block; font-size: 15px; line-height: 25px; vertical-align: middle; margin-left: 40px;}
.event_area_wiew .view_top .event_info > span:first-child{margin-left: 0;}
.event_area_wiew .view_top .event_info > span > i{font-size: 15px; color: #f93964; line-height: 25px;}
.event_area_wiew .view_top .event_info > span.end_text{padding: 0 15px; height: 25px; line-height: 25px; border-radius: 13px; background: #f93964; color: #fff; font-size: 12px; margin-left: 10px; display: none;}
.event_area_wiew .view_type_01{width: 1280px; margin: 58px auto 0; padding-bottom: 90px;}
.event_area_wiew .view_type_01 .event_full_img{width: 1024px; margin: 0 auto; border-radius: 16px; overflow: hidden;}
.event_area_wiew .view_type_01 .event_full_img > img{width: 100%;}
.event_area_wiew .view_type_01 .button_area{width: 100%; text-align: center; margin-top: 40px; display: block;}
.event_area_wiew .view_type_01 .button_area > button{width: 200px; height: 54px; border-radius: 8px; color: #fff; font-size: 16px; background: #f93964;}
.event_area_wiew.event_end .view_top .event_info > span.end_text{display: inline-block;}
.event_area_wiew.event_end .view_type_01 .button_area{display: none;}
.event_area_wiew .view_type_02{padding: 58px 0 90px;}
.event_area_wiew .view_type_02 .button_area{width: 100%; text-align: center; margin-top: 40px; display: block;}
.event_area_wiew .view_type_02 .button_area > button{width: 200px; height: 54px; border-radius: 8px; color: #fff; font-size: 16px; background: #f93964;}
.event_area_wiew.event_end .view_type_02 .button_area{display: none;}


.company_wrap{width: 100%; background: #fff;}
.company_wrap .company_top{width: 100%;}
.company_wrap .company_top > h2{padding: 60px 0 30px; width: 1024px; margin: 0 auto; font-size: 32px; font-weight: 700; line-height: 39px;}
.company_wrap .company_top .company_tab01{width: 100%; border-top: 1px solid #e8e8e8; border-bottom: 1px solid #e8e8e8;}
.company_wrap .company_top .company_tab01 > ul{width: 1024px; margin: 0 auto; font-size: 0; padding-top: 24px;}
.company_wrap .company_top .company_tab01 > ul > li{display: inline-block; padding-bottom: 19px; vertical-align: top; margin: 0 22px; border-bottom: 3px solid #fff; cursor: pointer;}
.company_wrap .company_top .company_tab01 > ul > li:first-child{margin-left: 0 !important;}
.company_wrap .company_top .company_tab01 > ul > li > span{display: inline-block; font-size: 18px; line-height: 21px; vertical-align: middle;  color: #333;}
.company_wrap .company_top .company_tab01 > ul > li.active{border-bottom: 3px solid #000; cursor: pointer;}
.company_wrap .company_top .company_tab01 > ul > li.active > span{color: #000; font-weight: 700;}
.company_wrap .introduction_content{width: 100%;}
.company_wrap .introduction_content .compny_introduce{width: 1024px; margin: 0 auto; padding-bottom: 90px;}
.company_wrap .introduction_content .compny_introduce .introduce_top{width: 100%; padding-top: 60px;}
.company_wrap .introduction_content .compny_introduce .introduce_top > p{font-size: 16px; line-height: 26px; color: #333;}
.company_wrap .introduction_content .compny_introduce .introduce_top .introduce_img{width: 984px; padding: 60px 0; margin: 0 auto;}
.company_wrap .introduction_content .compny_introduce .introduce_top .introduce_img > img{width: 100%;}
.company_wrap .introduction_content .compny_introduce .history_box{width: 100%; margin-top: 100px; background: #fbfbfb; border-radius: 16px; padding: 60px 100px;}
.company_wrap .introduction_content .compny_introduce .history_box > h4{font-size: 18px; text-align: center; font-weight: 700;}
.company_wrap .introduction_content .compny_introduce .history_box .history_inner{width: 100%; margin-top: 60px; display: table;}
.company_wrap .introduction_content .compny_introduce .history_box .history_inner.top{margin-top: 50px;}
.company_wrap .introduction_content .compny_introduce .history_box .history_inner .history_tab{width: 140px; display: table-cell; vertical-align: top;}
.company_wrap .introduction_content .compny_introduce .history_box .history_inner .history_tab > span{display: block; width: 100%; margin-top: 12px; height: 38px; font-size: 16px; line-height: 38px; background: #eee; border-radius: 19px; overflow: hidden; text-align: center;}
.company_wrap .introduction_content .compny_introduce .history_box .history_inner .history_tab > span:first-child{margin-top: 0;}
.company_wrap .introduction_content .compny_introduce .history_box .history_inner .history_tab > span.active{background: #fff; border: 1px solid #000; font-weight: 700;}
.company_wrap .introduction_content .compny_introduce .history_box .history_inner .history_content{width: calc(100% - 140px); display: table-cell; vertical-align: top; padding-left: 40px;}
.history_content > ul{width: 100%;}
.history_content > ul > li{width: 100%; font-size: 0;}
.history_content > ul > li > span{width: 46px; font-size: 14px; vertical-align: top; display: inline-block;}
.history_content > ul > li > p{padding-left: 20px; background: url(/_next/static/media/history_bul2.7bfd10d7.png) left top no-repeat; background-size: 7px; font-size: 14px; line-height: 20px; padding-bottom: 40px; display: inline-block;}
.history_content > ul > li:first-child > p{background: url(/_next/static/media/history_bul.05d39f05.png) left 6px no-repeat; background-size: 7px;}
.history_content > ul > li:last-child > p{background: url(/_next/static/media/history_bul3.5f54ccee.png) left top no-repeat; background-size: 7px; padding-bottom: 0;}

.voucher_introduce{width: 100%; background: #f8f8f8; padding: 90px 0;}
.voucher_introduce .voucher_tab{width: 840px; margin: 0 auto; font-size: 0; text-align: center;}
.voucher_introduce .voucher_tab > a{display: inline-block; width: 136px; height: 136px; border-radius: 68px; overflow: hidden; background: #fbfbfb; border: 1px solid #e8e8e8; text-align: center; margin: 0 8px; color: #000; padding-top: 47px; outline: none;}
.voucher_introduce .voucher_tab > a > h5{font-size: 20px; line-height: 24px; font-weight: 700; color: inherit;}
.voucher_introduce .voucher_tab > a > p{font-size: 14px; line-height: 17px; color: inherit; margin-top: 2px;}
.voucher_introduce .voucher_tab > a.on{color: #fff; border: 1px solid #f93964; background: #f93964;}
.voucher_introduce .voucher_content{width: 840px; margin: 90px auto 0; text-align: center;}
.voucher_introduce .voucher_content > h3{font-size: 24px; line-height: 29px; font-weight: 700;}
.voucher_introduce .voucher_content > p{font-size: 16px; line-height: 22px; margin-top: 18px;}
.voucher_introduce .voucher_content .voucher_img{width: 410px; margin: 0 auto; padding: 23px 0 13px;}
.voucher_introduce .voucher_content .voucher_img > img{width: 100%;}
.voucher_introduce .voucher_content .use_info_box{margin-top: 60px; width: 100%;}
.voucher_introduce .voucher_content .use_info_box .tit_box{display: table; height: 100px; border-radius: 16px; background: #fff; width: 100%; overflow: hidden;}
.voucher_introduce .voucher_content .use_info_box .tit_box .title{display: table-cell; width: 220px; background: #e8e8e8; text-align: center; vertical-align: middle;}
.voucher_introduce .voucher_content .use_info_box .tit_box .title > h5{font-size: 18px; font-weight: 700;}
.voucher_introduce .voucher_content .use_info_box .tit_box .title > p{font-size: 14px;}
.voucher_introduce .voucher_content .use_info_box .tit_box .use_tip{text-align: left; padding-left: 30px; display: table-cell; vertical-align: middle;}
.voucher_introduce .voucher_content .use_info_box .tit_box .use_tip > ol{width: 100%;}
.voucher_introduce .voucher_content .use_info_box .tit_box .use_tip > ol > li{width: 100%; padding-top: 10px; }
.voucher_introduce .voucher_content .use_info_box .tit_box .use_tip > ol > li:first-child{padding-top: 0;}
.voucher_introduce .voucher_content .use_info_box .tit_box .use_tip > ol > li > em{display: inline-block; width: 19px; height: 19px; border-radius: 10px; background: #000; font-size: 12px; line-height: 20px; color: #fff; text-align: center; margin-right: 6px; vertical-align: middle;}
.voucher_introduce .voucher_content .use_info_box .tit_box .use_tip > ol > li > span {font-size: 14px; line-height: 21px; font-weight: 700; vertical-align: middle;}
.voucher_introduce .voucher_content .use_info_box .tit_box .use_tip > ol > li > span > i{color: #f93964; font-weight: 700;}
.voucher_introduce .voucher_content .use_info_box .tit_box .btn_r{width: 147px; padding-right: 50px; display: table-cell; vertical-align: middle;}
.voucher_introduce .voucher_content .use_info_box .tit_box .btn_r > button{width: 100%; height: 34px; background: #333; border-radius: 4px; font-size: 14px; color: #fff;}
.voucher_introduce .voucher_content .use_info_box .info_text{margin-top: 20px; width: 100%; text-align: left;}
.voucher_introduce .voucher_content .use_info_box .info_text > p{padding-left: 8px; margin-top: 6px; background: url(/_next/static/media/dot_01.6a71e25e.png) left center no-repeat; background-size: 2px; color: #666; font-size: 13px; line-height: 16px;}
.voucher_introduce .voucher_content .use_info_box .info_text > p:first-child{margin-top: 0;}

.terms_tab{width: 100%; background: #fff; }
.terms_tab > ul{width: 1024px; margin: 0 auto; padding: 24px 0;}
.terms_tab > ul > li{font-size: 15px; line-height: 19px; color:#333; cursor: pointer; display: inline-block; margin-left: 24px;}
.terms_tab > ul > li:first-child{margin-left: 0;}
.terms_tab > ul > li.active{font-weight: 700; color: #f93964;}

.terms_area{width: 100%; background: #f8f8f8; padding: 60px 0 90px;}
.terms_area .terms_inner{width: 1024px; margin: 0 auto; border: 1px solid #e8e8e8; background: #fff; border-radius: 16px; overflow: hidden; padding: 50px 40px;}
.terms_area .terms_inner > h3{font-size: 24px; text-align: center; font-weight: 700; line-height: 29px;}
.terms_area .terms_inner .date_select{width: 100%; text-align: right; position: relative; height: 27px;}
.terms_area .terms_inner .date_select .in_select{width: 197px; display: inline-block;}
.terms_area .terms_inner .date_select .in_select .nice-select{width: 100%; height: 27px; line-height: 0; border: 0; border-radius: 0; border-bottom: 1px solid #e8e8e8; padding-left: 0;}
.terms_area .terms_inner .date_select .in_select .nice-select::after{margin-top: -7px;}
.terms_area .terms_inner .date_select .in_select .nice-select .list{width: 100%;}
.terms_area .terms_inner .in_terms{width: 100%; margin-top: 40px; font-size: 13px; line-height: 20px; color: #333;}


.CustomerCenter_wrap{width: 100%;}
.CustomerCenter_wrap .CS_top{width: 100%; background: #fff;}
.CustomerCenter_wrap .w_inner{width: 1280px !important; margin: 0 auto; text-align: center; padding: 60px 128px 30px;}
.CustomerCenter_wrap .w_inner2{width: 1280px !important; margin: 0 auto; text-align: center; padding: 60px 0 30px;}
.CustomerCenter_wrap .CS_top h2{text-align: center; font-size: 28px; font-weight: 700; line-height: 29px;}
.CustomerCenter_wrap .CS_top p{font-size: 14px; line-height: 17px; text-align: center; margin-top: 20px;}
.CustomerCenter_wrap .CS_top p > i{color: #f93964;}
.CustomerCenter_wrap .CS_top .guide_tab{width: 100%; font-size: 0; text-align: center; padding-top: 40px;}
.CustomerCenter_wrap .CS_top .guide_tab > ul{width: 1280px; margin: 0 auto; text-align: center;}
.CustomerCenter_wrap .CS_top .guide_tab > ul > li{display: inline-block; vertical-align: middle; width: auto; height: 40px; border-radius: 20px; background: #fff; border: 1px solid #e8e8e8; font-size: 16px; line-height: 40px; padding: 0 21px; margin: 0 0 0 10px; cursor: pointer;}
.CustomerCenter_wrap .CS_top .guide_tab > ul > li:first-child{margin-left: 0;}
.CustomerCenter_wrap .CS_top .guide_tab > ul > li.active{background: #000; border-color: #000; color: #fff;}

.CustomerCenter_wrap .guide_sub_tab{width: 100%; border-top: 1px solid #e8e8e8; padding-top: 24px; background: #fff; border-bottom: 1px solid #e8e8e8; }
.CustomerCenter_wrap .guide_sub_tab > ul{width: 1280px; margin: 0 auto; text-align: center; font-size: 0;}
.CustomerCenter_wrap .guide_sub_tab > ul > li{display: inline-block; padding-bottom: 19px; vertical-align: top; margin: 0 22px; border-bottom: 3px solid #fff; cursor: pointer;}
.CustomerCenter_wrap .guide_sub_tab > ul > li.active{border-bottom: 3px solid #000;}
.CustomerCenter_wrap .guide_sub_tab > ul > li > i{display: inline-block; width: 17px; height: 21px; background: url(/_next/static/media/icon-new.6cf4ec5b.svg) center 1px no-repeat; margin-right: 6px; vertical-align: middle;}
.CustomerCenter_wrap .guide_sub_tab > ul > li > span{display: inline-block; font-size: 18px; line-height: 21px; font-weight: 700; vertical-align: middle;}
.CustomerCenter_wrap .guide_sub_tab > ul > li > em{display: inline-block; margin-left: 6px; font-size: 12px; line-height: 21px; font-weight: 700; color: #f93964; vertical-align: middle;}

.CustomerCenter_wrap .CS_search_wrap{width: 100%; background: linear-gradient(96deg, #e95576 -48%, #ffa857 137%); padding: 40px 0;}
.CustomerCenter_wrap .CS_search_wrap .w_inner{padding: 0 128px;}
.CustomerCenter_wrap .CS_search_wrap .w_inner .search_box{width: 520px; margin: 0 auto; position: relative; }
.CustomerCenter_wrap .CS_search_wrap .w_inner .search_box > input{width: 100%; height: 80px; padding: 0 60px 0 30px; border-radius: 16px; box-sizing: border-box; font-size: 18px;}
.CustomerCenter_wrap .CS_search_wrap .w_inner .search_box > input::-moz-placeholder{color: #999;}
.CustomerCenter_wrap .CS_search_wrap .w_inner .search_box > input:-ms-input-placeholder{color: #999;}
.CustomerCenter_wrap .CS_search_wrap .w_inner .search_box > input::placeholder{color: #999;}
.CustomerCenter_wrap .CS_search_wrap .w_inner .search_box > button{position: absolute; right: 32px; top: 29px; width: 24px; height: 24px; background: url(/_next/static/media/icon-search_p@2x.88feaecc.png) no-repeat; background-size: 24px;}
.CustomerCenter_wrap .CS_search_wrap .w_inner .sh_keyword{padding-top: 16px; font-size: 0; display: inline-block; margin: 0 auto;}
.CustomerCenter_wrap .CS_search_wrap .w_inner .sh_keyword > h5{display: inline-block; font-size: 14px; font-weight: 700; color: #fff; padding-right: 4px; vertical-align: middle; height: 30px; line-height: 32px;}
.CustomerCenter_wrap .CS_search_wrap .w_inner .sh_keyword > ul{display: inline-block; width: auto; font-size: 0; vertical-align: middle;}
.CustomerCenter_wrap .CS_search_wrap .w_inner .sh_keyword > ul > li{margin-left: 6px; height: 30px; padding: 0 12px; border-radius: 15px; background: rgba(0, 0, 0, 0.1); line-height: 32px; font-size: 14px; color: #fff; display: inline-block; cursor: pointer;}
.CustomerCenter_wrap .CS_content{width: 100%;}
.CustomerCenter_wrap .CS_content .CS_main{width: 100%; background: #fff; padding-bottom: 90px;}
.FAQ_wrap{width: 100%;}
.FAQ_wrap .FAQ_tap{width: 100%; padding: 60px 0 0 0; border-bottom: 1px solid #e8e8e8; background: #fff;}
.FAQ_wrap .FAQ_tap.none_pd{padding: 0;}
.FAQ_wrap .FAQ_tap > h3{width: 1280px; margin: 0 auto; padding: 0 128px; font-size: 0;}
.FAQ_wrap .FAQ_tap > h3 > span{font-size: 22px; line-height: 27px; font-weight: 700; padding-right: 10px; display: inline-block; vertical-align: top;}
.FAQ_wrap .FAQ_tap > h3 > a{width: 20px; height: 22px; padding-top: 2px; vertical-align: top; background: url(/_next/static/media/cs_tite_bul.d967feba.svg) right bottom no-repeat; background-size: 20px; display: inline-block;}
.FAQ_wrap .FAQ_tap > ul{width: 1280px; margin: 0 auto ; padding: 24px 128px 0; display: table; }
.FAQ_wrap .FAQ_tap > ul > li{padding-bottom: 14px;  box-sizing: border-box; border-bottom: 3px solid #fff; font-size: 0; display: table-cell; text-align: center; width: calc(100% / 8);}
.FAQ_wrap .FAQ_tap > ul > li > span{font-size: 18px; line-height: normal; color: #333; cursor: pointer; display: inline-block;}
.FAQ_wrap .FAQ_tap > ul > li > span > i{font-size: 18px; padding: 0 4px; color: inherit; line-height: normal; font-weight: inherit;}
.FAQ_wrap .FAQ_tap > ul > li.active{border-color: #f93964;}
.FAQ_wrap .FAQ_tap > ul > li.active > span{color: #f93964; font-weight: 700;}
.FAQ_wrap .FAQ_sub_tab{width: 100%; padding: 20px 0; background: #fff;}
.FAQ_wrap .FAQ_sub_tab > ul{width: 1280px; margin: 0 auto ; padding: 0 128px; font-size: 0; text-align: center;}
.FAQ_wrap .FAQ_sub_tab > ul > li{display: inline-block; margin: 0 10px; vertical-align: middle;}
.FAQ_wrap .FAQ_sub_tab > ul > li > a{font-size: 14px; line-height: 17px;}
.FAQ_wrap .FAQ_sub_tab > ul > li.active > a{color: #f93964; font-weight: 700;}

.FAQ_wrap .fAQ_list{width: 1280px; margin: 0 auto 0; padding: 0 128px;}
.FAQ_wrap .fAQ_list > ul{width: 100%; font-size: 0; padding-top: 16px;}
.FAQ_wrap .fAQ_list > ul > li{width: 246px; display: inline-block; background: #fbfbfb; border-radius: 16px; border: 1px solid #e8e8e8; padding: 36px 20px 32px; margin-left: 13px; margin-top: 14px; cursor: pointer;}
.FAQ_wrap .fAQ_list > ul > li:first-child{margin-left: 0;}
.FAQ_wrap .fAQ_list > ul > li:nth-child(4n+1){margin-left: 0;}
.FAQ_wrap .fAQ_list > ul > li > h4{font-size: 15px; line-height: 19px; font-weight: 700;}
.FAQ_wrap .fAQ_list > ul > li > p{font-size: 13px; line-height: 18px; margin-top: 6px;}


.FAQ_wrap .fAQ_content{width: 1280px; margin: 0 auto 0; padding: 60px 128px 90px;}
.FAQ_wrap .fAQ_content .faq_list_box{width: 100%; background: #fff; padding: 60px 110px; border-radius: 16px;}

.board_type_B{width: 100%;}
.board_type_B .list_top{width: 100%; padding-bottom: 10px;}
.board_type_B .list_top > span{font-size: 14px; line-height: 17px; color: #333;}
.board_type_B > ul {border-top: 1px solid #000; width: 100%;}
.board_type_B > ul > li{width: 100%; padding: 30px 40px; border-bottom: 1px solid #e8e8e8;}
.board_type_B > ul > li > a{display: block; cursor: pointer;}
.board_type_B > ul > li > a > h5{font-size: 13px; font-weight: 700; line-height: 16px; padding-bottom: 5px;}
.board_type_B > ul > li > a > p{font-size: 14px; line-height: 20px;}
.board_type_B .button_ar{margin-top: 40px; text-align: center;}
.board_type_B .button_ar .more{width: 140px; height: 40px; background: #f8f8f8; border-radius: 8px; font-size: 14px;}
.board_type_B.none_data{padding: 0 !important;}
.board_type_B.none_data > ul > li > p{padding: 200px 0; text-align: center; font-size: 13px;}
.board_type_B.none_data .button_ar{display: none;}
.CustomerCenter_wrap .CS_content .CS_main .InquiryAUse_wrap {width: 1280px; margin: 0 auto; padding: 90px 128px 0;}
.CustomerCenter_wrap .CS_content .CS_main .InquiryAUse_wrap .inquiry_box{width: 100%; padding: 40px; font-size: 0; background: #fbfbfb; border-radius: 16px;}
.CustomerCenter_wrap .CS_content .CS_main .InquiryAUse_wrap .inquiry_box .inquiry_link{display: inline-block; width: calc(100% - 400px); padding-left: 10px; vertical-align: top;}
.CustomerCenter_wrap .CS_content .CS_main .InquiryAUse_wrap .inquiry_box .inquiry_link > h4{font-size: 22px; height: 37px; padding-top: 10px; font-weight: 700;}
.CustomerCenter_wrap .CS_content .CS_main .InquiryAUse_wrap .inquiry_box .inquiry_link .link_navi{width: 100%; padding-top: 30px;}
.CustomerCenter_wrap .CS_content .CS_main .InquiryAUse_wrap .inquiry_box .inquiry_link .link_navi h5{font-size: 16px; height: 23px; font-weight: 700; padding-bottom: 4px;}
.CustomerCenter_wrap .CS_content .CS_main .InquiryAUse_wrap .inquiry_box .inquiry_link .link_navi h5 > i{font-size: inherit; font-weight: inherit; padding: 0 3px;}

.CustomerCenter_wrap .CS_content .CS_main .InquiryAUse_wrap .inquiry_box .inquiry_link .link_navi .online{display: inline-block; width: 150px; vertical-align: top;}
.CustomerCenter_wrap .CS_content .CS_main .InquiryAUse_wrap .inquiry_box .inquiry_link .link_navi .online > p{margin-top: 12px; text-align: left;}
.CustomerCenter_wrap .CS_content .CS_main .InquiryAUse_wrap .inquiry_box .inquiry_link .link_navi .online > p > a{background: url(/_next/static/media/dot_01.6a71e25e.png) left 6.5px no-repeat; background-size: 2px; color: #333; font-size: 14px; line-height: 17px; padding-left: 8px;}
.CustomerCenter_wrap .CS_content .CS_main .InquiryAUse_wrap .inquiry_box .inquiry_link .link_navi .Voucher_cash{display: inline-block; width: calc(100% - 150px); vertical-align: top;}
.CustomerCenter_wrap .CS_content .CS_main .InquiryAUse_wrap .inquiry_box .inquiry_link .link_navi .Voucher_cash p{margin-top: 12px; text-align: left; width: 134px; display: inline-block; vertical-align: top;}
.CustomerCenter_wrap .CS_content .CS_main .InquiryAUse_wrap .inquiry_box .inquiry_link .link_navi .Voucher_cash p:nth-child(2n){width: calc(100% - 134px);}
.CustomerCenter_wrap .CS_content .CS_main .InquiryAUse_wrap .inquiry_box .inquiry_link .link_navi .Voucher_cash p > a{background: url(/_next/static/media/dot_01.6a71e25e.png) left 6.5px no-repeat; background-size: 2px; color: #333; font-size: 14px; line-height: 17px; padding-left: 8px;}
.CustomerCenter_wrap .CS_content .CS_main .InquiryAUse_wrap .inquiry_box .inquiry_link .link_navi .Voucher_cash p > a > em{display: block; padding-top: 2px; font-size: 12px; color: #666; padding-left: 8px;}
.CustomerCenter_wrap .CS_content .CS_main .InquiryAUse_wrap .inquiry_box .CS_info{display: inline-block; width: 400px; background: #fff; border-radius: 8px; vertical-align: top; padding: 30px 97px 30px 30px;}
.CustomerCenter_wrap .CS_content .CS_main .InquiryAUse_wrap .inquiry_box .CS_info > h5{font-size: 15px; font-weight: 700; height: 19px;}
.CustomerCenter_wrap .CS_content .CS_main .InquiryAUse_wrap .inquiry_box .CS_info > strong{font-size: 24px; font-weight: 700; height: 29px; margin-top: 2px;}
.CustomerCenter_wrap .CS_content .CS_main .InquiryAUse_wrap .inquiry_box .CS_info > p{font-size: 13px; height: 16px; margin-top: 4px; color: #999;}
.CustomerCenter_wrap .CS_content .CS_main .InquiryAUse_wrap .inquiry_box .CS_info > span{display: block; font-size: 13px; line-height: 18px; margin-top: 11px; padding-top: 11px; border-top: 1px solid #000;}
.CustomerCenter_wrap .CS_content .CS_main .InquiryAUse_wrap .guide_box{width: 100%; height: 130px; margin-top: 30px; border: 1px solid #e8e8e8; border-radius: 16px; overflow: hidden; text-align: right; padding: 45px 79px; font-size: 0; background: url(/_next/static/media/guide_banner_03@2x.8f9fd06b.png) 20px 15px no-repeat; background-size: 276px;}
.CustomerCenter_wrap .CS_content .CS_main .InquiryAUse_wrap .guide_box > a{font-size: 15px; line-height: 20px; background: url(/_next/static/media/guide_link.66fbb792.svg) right center no-repeat; background-size: 20px; padding-right: 44px; margin-left: 100px; display: inline-block; text-align: left; height: 40px; vertical-align: top;}
.CustomerCenter_wrap .CS_content .CS_main .InquiryAUse_wrap .guide_box > a:first-child{margin-left: 0;}
.CustomerCenter_wrap .CS_content .CS_main .InquiryAUse_wrap .guide_box > a > strong{font-size: 18px; font-weight: 700;}
.CustomerCenter_wrap .CS_content .CS_main .InquiryAUse_wrap .guide_box > a > strong > i{font-size: inherit; font-weight: inherit; padding: 0 3px; line-height: 20px;}

.CustomerCenter_wrap .CS_content .CS_main .notice_wrap{width: 100%; padding-top: 90px;}
.CustomerCenter_wrap .CS_content .CS_main .notice_wrap > h3{width: 1280px; margin: 0 auto; padding: 0 128px;}
.CustomerCenter_wrap .CS_content .CS_main .notice_wrap > h3 > span{font-size: 22px; line-height: 27px; font-weight: 700; padding-right: 10px; display: inline-block; vertical-align: top;}
.CustomerCenter_wrap .CS_content .CS_main .notice_wrap > h3 > a{width: 20px; height: 22px; padding-top: 2px; vertical-align: top; background: url(/_next/static/media/cs_tite_bul.d967feba.svg) right bottom no-repeat; background-size: 20px; display: inline-block;}

.CustomerCenter_wrap .CS_content .CS_main .notice_wrap .notice_list{width: 1280px; margin: 0 auto; padding: 30px 128px 0;}
.CustomerCenter_wrap .CS_content .CS_main .notice_wrap .notice_list > ul{width: 100%;}
.CustomerCenter_wrap .CS_content .CS_main .notice_wrap .notice_list > ul > li{width: 100%; margin-top: 25px;}
.CustomerCenter_wrap .CS_content .CS_main .notice_wrap .notice_list > ul > li > a{display: block; font-size: 0;}
.CustomerCenter_wrap .CS_content .CS_main .notice_wrap .notice_list > ul > li > a > span{font-size: 14px; color: #000; line-height: 17px; display: inline-block; vertical-align: middle; width: calc(100% - 280px); font-weight: inherit;}
.CustomerCenter_wrap .CS_content .CS_main .notice_wrap .notice_list > ul > li > a > span > em{font-size: 14px; color: #f93964; line-height: 17px; padding-right: 5px;}
.CustomerCenter_wrap .CS_content .CS_main .notice_wrap .notice_list > ul > li > a > span.date{width: 280px; text-align: right; color: #999; font-weight: 500;}
.CustomerCenter_wrap .CS_content .CS_main .notice_wrap .notice_list > ul > li.noti > a > span{font-weight: 700;}
.CustomerCenter_wrap .CS_content .CS_main .notice_wrap .notice_list > ul > li.noti > a > span > em{font-weight: 700;}
.CustomerCenter_wrap .CS_content .CS_main .notice_wrap .notice_list > ul > li.noti > a > span.date{font-weight: 500;}
.CustomerCenter_wrap .CS_content .CS_main .notice_wrap .notice_list > ul > li:first-child{margin-top: 0;}



.fAQ_content .qna_link{width: 100%; border: 1px solid #e8e8e8; background: #fbfbfb; margin-top: 30px; border-radius: 16px; display: table; padding: 25px 50px;}
.fAQ_content .qna_link .left_text{padding-left: 56px; display: table-cell; background: url(/_next/static/media/more_q_bg.9da801c6.svg) left center no-repeat; background-size: 40px; vertical-align: middle;  }
.fAQ_content .qna_link .left_text > h5{font-size: 14px; color: #666; line-height: 17px;}
.fAQ_content .qna_link .left_text > p{font-size: 13px; color: #000; line-height: 16px; margin-top: 6px;}
.fAQ_content .qna_link .right_btn{display: table-cell; text-align: right; padding-right: 30px; vertical-align: middle;}
.fAQ_content .qna_link .right_btn > button{width: 220px; height: 50px; background: #666; color: #fff; font-size: 14px; border-radius: 8px;}

.pop_inner.POP_faq{width: 820px; padding: 30px;}
.pop_inner.POP_faq .pop_header{padding: 10px 0 24px; border-bottom: 1px solid #000;}
.pop_inner.POP_faq .pop_header > h2{text-align: left; font-size: 18px; line-height: 21px; font-weight: 700;}
.pop_inner.POP_faq .pop_content.faq_detail{padding: 30px 0 0;}
.pop_inner.POP_faq .pop_content.faq_detail .scroll_box3{height: 467px;}
.pop_inner.POP_faq .pop_content.faq_detail .scroll_box3 .q_box{width: 100%; padding-right: 30px;}
.pop_inner.POP_faq .pop_content.faq_detail .scroll_box3 .q_box > h3{width: 100%; padding-bottom: 17px; font-size: 0;}
.pop_inner.POP_faq .pop_content.faq_detail .scroll_box3 .q_box > h3 > strong{font-size: 20px; line-height: 24px; font-weight: 700; vertical-align: middle;}
.pop_inner.POP_faq .pop_content.faq_detail .scroll_box3 .q_box > h3 > span{font-size: 14px; padding-left: 11px; line-height: 17px; font-weight: 700; vertical-align: middle;}
.pop_inner.POP_faq .pop_content.faq_detail .scroll_box3 .q_box > p{font-size: 14px; padding-left: 30px; line-height: 24px;}
.pop_inner.POP_faq .pop_content.faq_detail .scroll_box3 .a_box{width: 100%; margin-top: 30px; background: #fbfbfb; border-radius: 8px; padding: 30px;}
.pop_inner.POP_faq .pop_content.faq_detail .scroll_box3 .a_box > p{font-size: 14px; line-height: 24px;}


.notice_inner{width: 100%;}
.notice_inner .notice_tap{width: 100%; padding: 60px 0 0 0; border-bottom: 1px solid #e8e8e8; background: #fff;}
.notice_inner .notice_tap.none_pd{padding: 0;}
.notice_inner .notice_tap > h3{width: 1280px; margin: 0 auto; padding: 0 128px;}
.notice_inner .notice_tap > h3 > span{font-size: 22px; line-height: 27px; font-weight: 700; padding-right: 30px; background: url(/_next/static/media/cs_tite_bul.d967feba.svg) right 2px no-repeat; background-size: 20px;}
.notice_inner .notice_tap > ul{width: 1280px; margin: 0 auto ; padding: 24px 128px 0; display: table; }
.notice_inner .notice_tap > ul > li{padding-bottom: 14px;  box-sizing: border-box; border-bottom: 3px solid #fff; font-size: 0; display: table-cell; text-align: center; width: calc(100% / 6);}
.notice_inner .notice_tap > ul > li > span{font-size: 18px; line-height: normal; color: #333; cursor: pointer; display: inline-block;}
.notice_inner .notice_tap > ul > li > span > i{font-size: 18px; padding: 0 4px; color: inherit; line-height: normal; font-weight: inherit;}
.notice_inner .notice_tap > ul > li.active{border-color: #f93964;}
.notice_inner .notice_tap > ul > li.active > span{color: #f93964; font-weight: 700;}

.notice_inner .notice_content{width: 1280px; margin: 0 auto 0; padding: 60px 128px 90px;}
.notice_inner .notice_content .notice_list_box{width: 100%; background: #fff; padding: 60px 110px; border-radius: 16px;}

.board_type_C{width: 100%;}
.board_type_C .list_top{width: 100%; padding-bottom: 10px;}
.board_type_C .list_top > span{font-size: 14px; line-height: 17px; color: #333;}
.board_type_C > ul {border-top: 1px solid #000; width: 100%;}
.board_type_C > ul > li{width: 100%; padding: 30px 40px; border-bottom: 1px solid #e8e8e8; font-size: 0; }
.board_type_C > ul > li > a{display: inline-block; cursor: pointer; width: calc(100% - 100px); vertical-align: middle; padding-right: 40px;}
.board_type_C > ul > li > a > h5{font-size: 13px; font-weight: 700; line-height: 16px; padding-bottom: 5px;}
.board_type_C > ul > li > a > p{font-size: 14px; line-height: 20px;}
.board_type_C > ul > li > span{display: inline-block; width: 100px; text-align: right; vertical-align: middle; font-size: 13px; color: #999;}
.board_type_C > ul > li.notice{background: #fff8f9 !important;}
.board_type_C > ul > li.notice > a > h5{color: #f93964;}
.board_type_C > ul > li.notice > a > p{font-weight: 700;}
.board_type_C .button_ar{margin-top: 40px; text-align: center;}
.board_type_C .button_ar .more{width: 140px; height: 40px; background: #f8f8f8; border-radius: 8px; font-size: 14px;}
.board_type_C.none_data{padding: 0 !important;}
.board_type_C.none_data > ul > li > p{padding: 200px 0; text-align: center; font-size: 13px;}
.board_type_C.none_data .button_ar{display: none;}


.notice_inner .notice_content .notice_view_wrap{width: 100%; background: #fff; padding: 60px 110px; border-radius: 16px;}
.notice_view_wrap .notice_title{width: 100%; border-top: 1px solid #000; padding: 30px 0; display: table;}
.notice_view_wrap .notice_title .title{display: table-cell; text-align: left; width: calc(100% - 150px); vertical-align: middle;}
.notice_view_wrap .notice_title .title > h5{font-size: 14px; line-height: 17px; font-weight: 700;}
.notice_view_wrap .notice_title .title > p{font-size: 16px; line-height: 1.4; margin-top: 6px;}
.notice_view_wrap .notice_title .date{display: table-cell; text-align: right; width: 150px; vertical-align: middle; color: #999;}
.notice_view_wrap .view_box{width: 100%; background: #fbfbfb; border-radius: 8px; padding: 30px;}
.notice_view_wrap .view_box p{font-size: 14px; line-height: 24px;}
.notice_view_wrap .view_box a{font-size: 14px; line-height: 24px; color: #f93964;}
.notice_view_wrap .btn_box{width: 100%; text-align: right; margin-top: 30px; padding: 0;}
.notice_view_wrap .btn_box > button{width: 86px; height: 34px; background: #fff; border: 1px solid #e8e8e8; border-radius: 4px; font-size: 14px;}

.UseConfirm_wrap{width: 1280px; padding: 60px 280px 90px; margin: 0 auto;}
.UseConfirm_wrap .confirm_wrap{width: 100%; background: #fff; border-radius: 16px; padding: 60px 120px;}
.UseConfirm_wrap .confirm_wrap .voucher_flavour{width: 100%; position: relative; height: 68px; font-size: 0;}
.UseConfirm_wrap .confirm_wrap .voucher_flavour > a{width: calc(33.3% + 1px); display: inline-block; padding: 15px; border: 1px solid #e8e8e8; position: absolute; outline: none;}
.UseConfirm_wrap .confirm_wrap .voucher_flavour > a:first-child{left: 0; border-top-left-radius:8px; border-bottom-left-radius: 8px;}
.UseConfirm_wrap .confirm_wrap .voucher_flavour > a:nth-child(2){left: 33.3%; width: 33.4%;}
.UseConfirm_wrap .confirm_wrap .voucher_flavour > a:nth-child(3){right: 0; border-top-right-radius:8px; border-bottom-right-radius: 8px;}
.UseConfirm_wrap .confirm_wrap .voucher_flavour > a.active{border-color: #f93964; z-index: 2; color: #f93964;}
.UseConfirm_wrap .confirm_wrap .voucher_flavour > a > h5{text-align: center; color: inherit; font-size: 15px; font-weight: bold; line-height: 19px;}
.UseConfirm_wrap .confirm_wrap .voucher_flavour > a > p{text-align: center; color: inherit; font-size: 13px; line-height: 16px; margin-top: 1px;}
.UseConfirm_wrap .confirm_wrap .inquiry_method{width: 100%; margin-top: 50px;}
.UseConfirm_wrap .confirm_wrap .inquiry_method .method_select{width: 100%;}
.UseConfirm_wrap .confirm_wrap .inquiry_method .method_select > h4{font-size: 16px; font-weight: 700; line-height: 19px;}
.UseConfirm_wrap .confirm_wrap .inquiry_method .method_select .sel_ar{width: 100%; margin-top: 12px;}
.UseConfirm_wrap .confirm_wrap .inquiry_method .method_select .voucher_img{width: 100%; margin-top: 20px; padding: 16px 15px; background: #fbfbfb; border-radius: 8px;}
.UseConfirm_wrap .confirm_wrap .inquiry_method .method_select .voucher_img > img{width: 100%;}
.UseConfirm_wrap .confirm_wrap .inquiry_method .Voucher_inquiry{width: 100%; margin-top: 50px;}
.UseConfirm_wrap .confirm_wrap .inquiry_method .Voucher_inquiry .inquiry_box > h4{font-size: 16px; font-weight: 700; line-height: 19px;}
.UseConfirm_wrap .confirm_wrap .inquiry_method .Voucher_inquiry .inquiry_box{width: 100%;}
.UseConfirm_wrap .confirm_wrap .inquiry_method .Voucher_inquiry .inquiry_box .inp_ar{width: 100%; border: 1px solid #e8e8e8; border-radius: 8px; font-size: 0; margin-top: 12px;}
.UseConfirm_wrap .confirm_wrap .inquiry_method .Voucher_inquiry .inquiry_box .inp_ar.focus{border-color: #f93964;}
.UseConfirm_wrap .confirm_wrap .inquiry_method .Voucher_inquiry .inquiry_box .inp_ar .w_l{display: inline-block; padding: 0 20px 0; width: calc(100% - 198px); vertical-align: middle;}
.UseConfirm_wrap .confirm_wrap .inquiry_method .Voucher_inquiry .inquiry_box .inp_ar > i{display: inline-block; width: 1px; height: 28px; vertical-align: middle; background: #e8e8e8;}
.UseConfirm_wrap .confirm_wrap .inquiry_method .Voucher_inquiry .inquiry_box .inp_ar .w_s{display: inline-block; padding: 0 20px 0; width: 197px; vertical-align: middle;}
.UseConfirm_wrap .confirm_wrap .inquiry_method .Voucher_inquiry .inquiry_box .inp_ar .w_xs{display: inline-block; padding: 0 20px 0; width: 150px; vertical-align: middle;}
.UseConfirm_wrap .confirm_wrap .inquiry_method .Voucher_inquiry .inquiry_box .inp_ar .w_xl{display: inline-block; padding: 0 20px 0; width: calc(100% - 151px); vertical-align: middle;}
.UseConfirm_wrap .confirm_wrap .inquiry_method .Voucher_inquiry .inquiry_box > input{width: 100%; height: 48px; margin-top: 12px; border-bottom: 1px solid #e8e8e8; font-size: 20px; font-weight: 900;}
.UseConfirm_wrap .confirm_wrap .inquiry_method .Voucher_inquiry .inquiry_box > input::-moz-placeholder{font-weight: 400; color: #c8c8c8;}
.UseConfirm_wrap .confirm_wrap .inquiry_method .Voucher_inquiry .inquiry_box > input:-ms-input-placeholder{font-weight: 400; color: #c8c8c8;}
.UseConfirm_wrap .confirm_wrap .inquiry_method .Voucher_inquiry .inquiry_box > input::placeholder{font-weight: 400; color: #c8c8c8;}
.UseConfirm_wrap .confirm_wrap .btn_area_center{width: 100%; margin-top: 40px; text-align: center;}
.UseConfirm_wrap .confirm_wrap .btn_area_center > button{width: 200px; height: 54px; border-radius: 8px; background: #f93964; font-size: 16px; color: #fff;}

.UseConfirm_wrap2{width: 1280px; padding: 60px 128px 90px; margin: 0 auto;}
.UseConfirm_wrap2 .voucher_number{width: 100%; background: #fbfbfb; border-radius: 16px; border: 1px solid #e8e8e8; padding: 30px 194px 30px 220px; font-size: 0; display: table;}
.UseConfirm_wrap2 .voucher_number > span{display: table-cell; text-align: right; font-size: 20px; line-height: 24px; vertical-align: middle; font-weight: 700; }
.UseConfirm_wrap2 .voucher_number > span:first-child{font-size: 0; width: 143px; vertical-align: middle; margin-left: 0;}
.UseConfirm_wrap2 .voucher_number > span:first-child > h4{font-size: 16px; line-height: 19px; font-weight: 700; text-align: center;}
.UseConfirm_wrap2 .voucher_number > span:first-child > p{font-size: 16px; line-height: 19px; color: #666; font-weight: 700; text-align: center; margin-top: 4px;}
.UseConfirm_wrap2 .resuit_wrap{width: 100%; margin-top: 30px; background: #fff; border-radius: 16px; padding: 60px 152px;}
.UseConfirm_wrap2 .resuit_wrap .top_resuit{width: 100%; background: #fbfbfb; border-radius: 16px; padding: 30px 180px;}
.UseConfirm_wrap2 .resuit_wrap .top_resuit > ul{width: 100%;}
.UseConfirm_wrap2 .resuit_wrap .top_resuit > ul > li{width: 100%; display: table; padding-top: 10px;}
.UseConfirm_wrap2 .resuit_wrap .top_resuit > ul > li > span{display: table-cell; vertical-align: middle; text-align: right; font-size: 14px; line-height: 17px;}
.UseConfirm_wrap2 .resuit_wrap .top_resuit > ul > li > span:first-child{text-align: left;}
.UseConfirm_wrap2 .resuit_wrap .top_resuit > ul > li:first-child{padding-top: 0;}
.UseConfirm_wrap2 .resuit_wrap .top_resuit > ul > li:last-child > span{font-weight: 700;}
.UseConfirm_wrap2 .resuit_wrap .top_resuit > ul > li:last-child > span:last-child{color: #f93964;}
.UseConfirm_wrap2 .resuit_wrap .resuit_list_wrap{width: 100%; margin-top: 50px;}
.UseConfirm_wrap2 .resuit_wrap .resuit_list_wrap > h5{font-size: 14px; font-weight: 700; line-height: 17px; padding-bottom: 12px;}
.UseConfirm_wrap2 .resuit_wrap .resuit_list_wrap > table{width: 100%; border-top: 1px solid #000;}
.UseConfirm_wrap2 .resuit_wrap .resuit_list_wrap > table th{padding: 15px 24px 10px; border-bottom: 1px solid #e8e8e8; font-size: 13px; text-align: center; color: #666;}
.UseConfirm_wrap2 .resuit_wrap .resuit_list_wrap > table td{padding: 13px 24px 12px; border-bottom: 1px solid #e8e8e8; font-size: 13px; text-align: center; color: #000;}
.UseConfirm_wrap2 .resuit_wrap .resuit_list_wrap > table td:first-child{color: #999;}
.UseConfirm_wrap2 .resuit_wrap .resuit_list_wrap > table td.tar{text-align: right;}
.UseConfirm_wrap2 .resuit_wrap .resuit_list_wrap .paging_area{padding-bottom: 0;}

.resend_wrap{width: 1280px; padding: 60px 128px 90px; margin: 0 auto;}
.resend_wrap .resend_box{width: 100%; height: 540px; background: #fff; border-radius: 16px; padding-top: 155px;}
.resend_wrap .resend_box .resend_certify{width: 100%; text-align: center;}
.resend_wrap .resend_box .resend_certify > p{padding-top: 70px; font-size: 14px; line-height: 17px; background: url(/_next/static/media/icon-lock-line2.b685267e.svg)center top no-repeat; background-size: 34px; color: #666; text-align: center;}
.resend_wrap .resend_box .resend_certify > button{width: 236px; margin-top: 20px; height: 54px; border-radius: 8px; background: #f93964; color: #fff; font-size: 16px;}

.main_pop{width: 480px; overflow: hidden;}
.main_pop .pop_content{padding: 0 !important; margin: 0; width: 100%;}
.main_pop .pop_content .swiper-slide{width: 100%; height: 480px;}
.main_pop .pop_content .swiper-slide > a{display: block;}
.main_pop .pop_content .swiper-slide > a > img{width: 100%;}
.main_pop .pop_content .swiper-button-next{width: 34px; height: 34px; right: 0; overflow: hidden;}
.main_pop .pop_content .swiper-button-next > img{width: 46px; position: absolute; top: -6px; left: -6px;}
.main_pop .pop_content .swiper-button-next::after{display: none;}
.main_pop .pop_content .swiper-button-prev{width: 34px; height: 34px; left: 0; overflow: hidden;}
.main_pop .pop_content .swiper-button-prev > img{width: 46px; position: absolute; top: -6px; left: -6px;}
.main_pop .pop_content .swiper-button-prev::after{display: none;}
.main_pop .pop_content .swiper-pagination{padding: 2px 10px; border-radius: 11px; background: rgba(0, 0, 0, 0.3); top: 24px; right: 16px; font-size: 12px; color: #fff; bottom: auto; left: auto; width: auto; display: inline-block;}
.main_pop .pop_content .swiper-pagination-total{color: #fff;}
.main_pop .pop_content .swiper-pagination-current{color: #fff;}
.main_pop .btn_area{width: 100%; padding: 18px 30px 17px 20px !important; display: table;}
.main_pop .btn_area .left_check{display: table-cell; vertical-align: middle;}
.main_pop .btn_area .btn_r{display: table-cell; vertical-align: middle; text-align: right;}
.main_pop .btn_area .btn_r > button{padding: 0; margin: 0; font-size: 14px; color: #000;}
.main_pop .btn_area .check_box_p {width: auto; position: relative; display: inline-block; cursor: pointer;}
.main_pop .btn_area .check_box_p input[type="checkbox"] + label {display: inline-block; background: url(/_next/static/media/button-checkbox_p-check-off@2x.028111fc.png) no-repeat; background-size: 15px auto; background-position:left 1px; padding: 0 0 0 21px; font-size: 14px; line-height: 17px; color: #c8c8c8; height: 17px;}
.main_pop .btn_area .check_box_p input[type="checkbox"] + label > b{color: #f93964;}
.main_pop .btn_area .check_box_p input[type="checkbox"] + label > i{color: #999999;}
.main_pop .btn_area .check_box_p input[type="checkbox"]:checked + label{background: url(/_next/static/media/button-checkbox_p-check-on@2x.54cad4a0.png) no-repeat; background-size: 15px auto; background-position:left 1px; font-size: 14px; line-height: 17px; color: #000;}

.UserGuide_content{width: 100%; padding: 60px 0 90px; background: #fff;}
.UserGuide_content .UserGuide_wrap{width: 1280px; margin: 0 auto; padding: 0 128px;}
.UserGuide_content .UserGuide_wrap .guide_top{width: 100%; padding: 25px 233px; display: table; border: 1px solid #ffe3c6; background: #fff8f0; border-radius: 16px;}
.UserGuide_content .UserGuide_wrap .guide_top > span{display: table-cell; vertical-align: middle; text-align: right;}
.UserGuide_content .UserGuide_wrap .guide_top > span:first-child{text-align: left; font-size: 14px; line-height: 20px;}
.UserGuide_content .UserGuide_wrap .guide_top > span > i{color: #f93964; font-weight: 700;}
.UserGuide_content .UserGuide_wrap .guide_top > span > button{width: 100px; height: 34px; background: #666; border-radius: 4px; font-size: 14px; color: #fff;}
.UserGuide_content .UserGuide_wrap .guide_top > p{font-size: 14px; line-height: 20px; text-align: center;}
.UserGuide_content .UserGuide_wrap .guide_top > p > b{color: #f93964; font-weight: 700;}
.guide_wrap{width: 100%;}
.guide_wrap .in_container{width: 100%;}
.guide_wrap .in_container.con01_01{margin-top: 60px;}
.guide_wrap .in_container.con01_02{margin-top: 90px;}
.guide_wrap .in_container.con02_01{margin-top: 0px;}
.guide_wrap .in_container > h2{width: 100%; padding: 11px 20px 10px; font-size: 16px; line-height: 19px; background: #ffa341; color: #fff; font-weight: 700;}
.guide_wrap .in_container > h3{width: 100%; padding: 11px 20px 10px; text-align: center; font-size: 16px; line-height: 19px; background: #f9f9f9; color: #000; margin-top: 30px;}
.guide_wrap .in_container > h3 > b{font-weight: 700;}
.guide_wrap .in_container .in_content{width: 100%; padding: 60px 60px 0 60px;}
.guide_wrap .in_container .in_content > h3{padding-left: 24px; background: url(/_next/static/media/bul_chack@2x.2ba6b6a3.png)left top no-repeat; background-size: 18px; font-size: 16px; font-weight: 700; line-height: 19px;}
.guide_wrap .in_container .in_content .box_type_01{width: 100%; font-size: 0; margin-top: 20px;}
.guide_wrap .in_container .in_content .box_type_01 .g_img_box{display: inline-block; width: 560px; vertical-align: top;}
.guide_wrap .in_container .in_content .box_type_01 img{width: 100%;}
.guide_wrap .in_container .in_content .box_type_01 .s_img_box_01{display: inline-block; width: 230px; vertical-align: top;}
.guide_wrap .in_container .in_content .box_type_01 .s_img_box_02{display: inline-block; width: 230px; vertical-align: top; margin-left: 20px;}
.guide_wrap .in_container .in_content .box_type_01.use .s_img_box_01{display: inline-block; width: 378px; vertical-align: top;}
.guide_wrap .in_container .in_content .box_type_01.use .s_img_box_02{display: inline-block; width: 140px; vertical-align: top; margin-left: 20px;}
.guide_wrap .in_container .in_content .box_type_01.use .list_txt{display: inline-block; width: calc(100% - 538px); padding: 20px 0 0 30px; vertical-align: top;}
.guide_wrap .in_container .in_content .box_type_01 .list_txt{display: inline-block; width: calc(100% - 560px); padding: 20px 0 0 30px; vertical-align: top; letter-spacing: -0.7px;}
.guide_wrap .in_container .in_content .box_type_01 .list_txt.smw{width: calc(100% - 480px);}
.guide_wrap .in_container .in_content .box_type_01 .list_txt > p{width: 100%; margin-top: 20px; font-size: 0;}
.guide_wrap .in_container .in_content .box_type_01 .list_txt > p:first-child{margin-top: 0;}
.guide_wrap .in_container .in_content .box_type_01 .list_txt > p > i{display: inline-block; width: 18px; height: 18px; line-height: 18px; font-size: 13px; color: #fff; font-weight: 700; text-align: center; background: #f93964; border-radius: 4px; margin-top: 1px;}
.guide_wrap .in_container .in_content .box_type_01 .list_txt > p > span{font-size: 14px; line-height: 20px; display:inline-block; vertical-align: top; margin-left: 8px; width: calc(100% - 26px); word-break: keep-all;}
.guide_wrap .in_container .in_content .box_type_01 .list_txt > p > span > strong{font-size: 13px; line-height: 16px; padding-top: 3px; color: #f93964; display: block;}
.guide_wrap .in_container .in_content .box_type_02{width: 100%; margin-top: 50px; background: #fbfbfb; font-size: 0; padding-bottom: 40px;}
.guide_wrap .in_container .in_content .box_type_02 > h4{padding: 19px 30px 18px; width: 100%; background: #f4f4f4; font-size: 16px; line-height: 19px; font-weight: 700; color: #333;}
.guide_wrap .in_container .in_content .box_type_02 .in_box{display: inline-block; vertical-align: top;}
.guide_wrap .in_container .in_content .box_type_02 .in_box > h5{font-size: 14px; font-weight: 700; line-height: 17px;}
.guide_wrap .in_container .in_content .box_type_02 .in_box > p{font-size: 12px; margin-top: 10px; line-height: 18px;}
.guide_wrap .in_container .in_content .box_type_02 .in_box > p > b{font-weight: 700;}
.guide_wrap .in_container .in_content .box_type_02 .in_box > span{display: block; font-size: 11px; line-height: 15px; margin-top: 4px; color: #f93964;}
.guide_wrap .in_container .in_content .box_type_02 .in_box .img_size{margin-top: 20px;}
.guide_wrap .in_container .in_content .box_type_02 .in_box .img_size > img{width: 100%;}
.guide_wrap .in_container .in_content .box_type_02 .in_box.box_3in1{width: 160px; margin: 40px 0 0 80px; }
.guide_wrap .in_container .in_content .box_type_02 .in_box.box_3in1 .img_size{width: 112px;}
.guide_wrap .in_container .in_content .box_type_02 .in_box.box_3in2{width: 170px; margin: 40px 0 0 112px; }
.guide_wrap .in_container .in_content .box_type_02 .in_box.box_3in2 .img_size{width: 150px;}
.guide_wrap .in_container .in_content .box_type_02 .in_box.box_3in3{width: 217px; margin: 40px 0 0 97px; }
.guide_wrap .in_container .in_content .box_type_02 .in_box.box_3in3 .img_size{width: 217px;}
.guide_wrap .in_container .in_content .box_type_03{width: 100%; font-size: 0; margin-top: 20px;}
.guide_wrap .in_container .in_content .box_type_03 .g_img_box{width: 442px; display: inline-block; vertical-align: top;} 
.guide_wrap .in_container .in_content .box_type_03 .g_img_box > img{width: 100%;}
.guide_wrap .in_container .in_content .box_type_03 .list_txt{display: inline-block; letter-spacing: -0.7px; width: calc(100% - 442px); padding: 20px 0 0 30px; vertical-align: top;}
.guide_wrap .in_container .in_content .box_type_03 .list_txt > p{width: 100%; margin-top: 20px; font-size: 0;}
.guide_wrap .in_container .in_content .box_type_03 .list_txt > p:first-child{margin-top: 0;}
.guide_wrap .in_container .in_content .box_type_03 .list_txt > p > i{display: inline-block; width: 18px; height: 18px; line-height: 18px; font-size: 13px; color: #fff; font-weight: 700; text-align: center; background: #f93964; border-radius: 4px; margin-top: 1px;}
.guide_wrap .in_container .in_content .box_type_03 .list_txt > p > span{font-size: 14px; line-height: 20px; display:inline-block; vertical-align: top; margin-left: 8px; width: calc(100% - 26px); word-break: keep-all;}
.guide_wrap .in_container .in_content .box_type_03 .list_txt > p > span > strong{font-size: 13px; line-height: 16px; padding-top: 4px; color: #f93964;}
.guide_wrap .in_container .in_content .box_type_03 .list_txt > h5{font-size: 14px; line-height: 20px; color: #666;}
.guide_wrap .in_container .in_content .box_type_04{width: 100%; font-size: 0;}
.guide_wrap .in_container .in_content .box_type_04 > ul{width: 100%; font-size: 0;}
.guide_wrap .in_container .in_content .box_type_04 > ul > li{width: 430px; display: inline-block; vertical-align: top;}
.guide_wrap .in_container .in_content .box_type_04 > ul > li .img_l_box{width: 174px; display: inline-block;}
.guide_wrap .in_container .in_content .box_type_04 > ul > li .img_l_box > img{width: 100%;}
.guide_wrap .in_container .in_content .box_type_04 > ul > li:nth-child(2n){margin-left: 44px;}
.guide_wrap .in_container .in_content .box_type_04 > ul > li:nth-child(3),
.guide_wrap .in_container .in_content .box_type_04 > ul > li:nth-child(4){margin-top: 60px;}
.guide_wrap .in_container .in_content .box_type_04 .list_txt{display: inline-block; letter-spacing: -0.7px; width: calc(100% - 174px); padding: 20px 0 0 30px; vertical-align: top;}
.guide_wrap .in_container .in_content .box_type_04 .list_txt > p{width: 100%; margin-top: 20px; font-size: 0;}
.guide_wrap .in_container .in_content .box_type_04 .list_txt > p:first-child{margin-top: 0;}
.guide_wrap .in_container .in_content .box_type_04 .list_txt > p > i{display: inline-block; width: 18px; height: 18px; line-height: 18px; font-size: 13px; color: #fff; font-weight: 700; text-align: center; background: #f93964; border-radius: 4px; margin-top: 1px;}
.guide_wrap .in_container .in_content .box_type_04 .list_txt > p > span{font-size: 14px; line-height: 20px; display:inline-block; vertical-align: top; margin-left: 8px; width: calc(100% - 26px); word-break: keep-all;}
.guide_wrap .in_container .in_content .box_type_05{width: 100%; margin-top: 20px; border: 1px solid rgba(0, 0, 0, 0.06); display: table; padding: 16px 60px 16px 42px;}
.guide_wrap .in_container .in_content .box_type_05 .txt_inner{display: table-cell; text-align: center; vertical-align: middle;}
.guide_wrap .in_container .in_content .box_type_05 .txt_inner > h5{font-size: 14px; line-height: 18px;}
.guide_wrap .in_container .in_content .box_type_05 .txt_inner > h5 > b{font-weight: 700;}
.guide_wrap .in_container .in_content .box_type_05 .txt_inner > span{font-size: 12px; line-height: 15px; color: #f93964; margin-top: 4px;}
.guide_wrap .in_container .in_content .box_type_05 .g_img_box{display: table-cell; width: 382px; padding-left: 19px; vertical-align: middle;}
.guide_wrap .in_container .in_content .box_type_05 .g_img_box > img{width: 100%;}
.guide_wrap .in_container .in_content .box_type_06{width: 100%; font-size: 0; margin-top: 20px;}
.guide_wrap .in_container .in_content .box_type_06 .g_img_box{display: inline-block; width: 450px; vertical-align: top;}
.guide_wrap .in_container .in_content .box_type_06 img{width: 100%;}
.guide_wrap .in_container .in_content .box_type_06 .list_txt{display: inline-block;  letter-spacing: -0.7px; width: calc(100% - 450px); padding: 20px 0 0 30px; vertical-align: top;}
.guide_wrap .in_container .in_content .box_type_06 .list_txt > p{width: 100%; margin-top: 20px; font-size: 0;}
.guide_wrap .in_container .in_content .box_type_06 .list_txt > p:first-child{margin-top: 0;}
.guide_wrap .in_container .in_content .box_type_06 .list_txt > p > i{display: inline-block; width: 18px; height: 18px; line-height: 18px; font-size: 13px; color: #fff; font-weight: 700; text-align: center; background: #f93964; border-radius: 4px; margin-top: 1px;}
.guide_wrap .in_container .in_content .box_type_06 .list_txt > p > span{font-size: 14px; line-height: 20px; display:inline-block; vertical-align: top; margin-left: 8px; width: calc(100% - 26px); word-break: keep-all;}
.guide_wrap .in_container .in_content .box_type_06 .list_txt > p.sub{padding-left: 26px; margin-top: 16px;}
.guide_wrap .in_container .in_content .box_type_06 .list_txt > p.sub > strong{display: block; font-size: 13px; color: #f93964;}
.guide_wrap .in_container .in_content .box_type_06 .list_txt > p.sub > a{display: inline-block; margin: 4px 0 12px; width: 100px; height: 34px; background: #666; border-radius: 4px; line-height: 34px; font-size: 14px; color: #fff; text-align: center;}
.guide_wrap .in_container .in_content .box_type_06.sm_box .g_img_box{display: inline-block; width: 300px; vertical-align: top;}
.guide_wrap .in_container .in_content .box_type_06.sm_box .list_txt{display: inline-block; width: calc(100% - 300px); padding: 0px 0 0 30px; vertical-align: top;}
.guide_wrap .in_container .in_content .box_type_06.sm_box .list_txt .icon_box{width: 70px; margin-top: 20px;}

.etc_header{width: 100%; height: auto !important; padding: 60px 0 0 !important; box-shadow: none !important;}
.etc_header > h1{width: 1280px; margin: 0 auto; text-align: left;}
.etc_header > h1 > a{width: 189px; display: inline-block;}
.etc_header > h1 > a > img{width: 100%;}
.etc_container{padding-top: 108px !important;}
.etc_container .error_504{width: 1280px; margin: 0 auto; padding: 250px 0 369px; text-align: center;}
.etc_container .error_504 > h3{font-size: 20px; line-height: 25px; font-weight: 700;}
.etc_container .error_504 > p{font-size: 14px; margin-top: 16px; line-height: 17px; color: #666;}
.etc_container .error_504 > button{margin-top: 73px; width: 200px; height: 54px; border-radius: 8px; background: #f93964; font-size: 16px; color: #fff;}
.etc_container > h1{text-align: center; width: 1280px; margin: 0 auto; padding-top: 92px;}
.etc_container > h1 > a{width: 189px; display: inline-block;}
.etc_container > h1 > a > img{width: 100%;}
.etc_container .routine_inspection{width: 1280px; margin: 0 auto; padding: 120px 0; text-align: center;}
.etc_container .routine_inspection > h3{font-size: 20px; line-height: 25px; font-weight: 700;}
.etc_container .routine_inspection > p{font-size: 14px; margin-top: 16px; line-height: 17px; color: #666;}
.etc_container .routine_inspection .g_box{width: 480px; display: inline-block; margin-top: 40px; background: #fbfbfb; border-radius: 16px; padding: 30px 70px;}
.etc_container .routine_inspection .g_box > ul{width: 100%;}
.etc_container .routine_inspection .g_box > ul > li{width: 100%; margin-top: 15px; display: table;}
.etc_container .routine_inspection .g_box > ul > li:first-child{margin-top: 0;}
.etc_container .routine_inspection .g_box > ul > li > span{text-align: left; vertical-align: top; font-size: 14px; line-height: 20px; display: table-cell;}
.etc_container .routine_inspection .g_box > ul > li > span:first-child{font-weight: 700; padding-right: 30px; width: 79px;}


.alert{display: none; width: 100%; height: 100vh; position: fixed; z-index: 999999; background: rgba(0, 0, 0, 0.6); top: 0; left: 0;}
.alert.on{display: table;}
.alert .alert_wrap{width: 100%; display: table-cell; vertical-align: middle; text-align: center}
.alert .alert_wrap .alert_inner{width: 360px; display: inline-block; background: #fff; border-radius: 16px; overflow: hidden;}
.alert .alert_wrap .alert_inner .alert_content{width: 100%; padding: 40px 15px; text-align: center;}
.alert .alert_wrap .alert_inner .alert_btn{width: 100%; display: table; border-top: 1px solid #e8e8e8;}
.alert .alert_wrap .alert_inner .alert_btn > span{display: table-cell; background: #fbfbfb; border-left: 1px solid #e8e8e8; width: 50%;}
.alert .alert_wrap .alert_inner .alert_btn > span:first-child{border-left: none;}
.alert .alert_wrap .alert_inner .alert_btn > span > button{width: 100%; height: 54px; font-size: 16px; font-weight: 700;}
.alert .alert_wrap .alert_inner .alert_btn > span.cancel > button{color: #999;}

.alert_test{width: 1280px; margin: 60px auto; text-align: center;}
.alert_test > button{margin: 10px; padding: 15px; border-radius: 8px; border: 1px solid #e8e8e8; background: #fff; font-size: 16px; font-weight: 700;}

/*Loader*/

.Loader{width: 100%; height: 100vh; background: rgba(255, 255, 255, 0.6); position: fixed; z-index: 10000000; display: table;}
.Loader .Loader_inner{display: table-cell; width: 100%; vertical-align: middle; text-align: center; padding-bottom: 30px;}

.leap-frog {
  --uib-size: 40px;
  --uib-speed: 2.5s;
  --uib-color: black;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: var(--uib-size);
  height: var(--uib-size);
}

.leap-frog__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.leap-frog__dot::before {
  content: '';
  display: block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #f93964;
  will-change: transform;
}

.leap-frog__dot:nth-child(1) {
  animation: leapFrog var(--uib-speed) ease infinite;
}

.leap-frog__dot:nth-child(2) {
  transform: translateX(calc(var(--uib-size) * 0.4));
  animation: leapFrog var(--uib-speed) ease calc(var(--uib-speed) / -1.5)
    infinite;
}

.leap-frog__dot:nth-child(3) {
  transform: translateX(calc(var(--uib-size) * 0.8)) rotate(0deg);
  animation: leapFrog var(--uib-speed) ease calc(var(--uib-speed) / -3)
    infinite;
}

@keyframes leapFrog {
  0% {
    transform: translateX(0) rotate(0deg);
  }

  33.333% {
    transform: translateX(0) rotate(180deg);
  }

  66.666% {
    transform: translateX(calc(var(--uib-size) * -0.4)) rotate(180deg);
  }

  99.999% {
    transform: translateX(calc(var(--uib-size) * -0.8)) rotate(180deg);
  }

  100% {
    transform: translateX(0) rotate(0deg);
  }
}

.pop_inner .pop_header.t_center > h4{font-size: 20px; font-weight: bold; text-align: center; line-height: 26px;}
.pop_inner .pop_header.t_center > p{font-size: 20px !important; text-align: center; margin-top: 0 !important; line-height: 26px !important;}
.pop_content .chack_text{width: 100%; border-top: 1px solid #000; margin-top: 24px;}
.pop_content .chack_text > ul{width: 100%;}
.pop_content .chack_text > ul > li{width: 100%; display: table; padding: 16px; border-bottom: 1px solid #e8e8e8;}
.pop_content .chack_text > ul > li > span{display: table-cell; font-size: 14px; color: #222020; text-align: right;}
.pop_content .chack_text > ul > li > span:first-child{text-align: left;}
.pop_content .chack_text > ul > li > div{display: table; width: 100%;}
.pop_content .chack_text > ul > li > div:first-child{padding-bottom: 12px;}
.pop_content .chack_text > ul > li > div > span{display: table-cell; text-align: right;  font-size: 14px; color: #222020;}
.pop_content .chack_text > ul > li > div > span > i{font-size: 12px; color: #f93964;}
.pop_content .chack_text > ul > li > div > span:first-child{text-align: left;}
.pop_content .chack_text > ul > li > div:last-child > span{font-weight: 700;}
.pop_content .chack_info{margin-top: 40px;}
.pop_content .chack_info > strong{display: block; font-size: 14px; color: #f93964; text-align: center; line-height: 17px;}
.pop_content .chack_info > p{display: block; font-size: 14px; color: #000; text-align: center; line-height: 17px;}
.pop_content .chack_info > p > b{font-weight: 700;}
.pop_content .chack_info > span{display: block; width: 100%; font-size: 13px; margin-top: 8px; color: #666; text-align: center;}

/* 20230222 띠배너 추가 */
.line_banner{width: 100%; display: none;}
.line_banner.active{display: block;}
.line_banner .banner_inner{width: 1280px; margin: 0 auto; text-align: center; position: relative; overflow: hidden;}
.line_banner .banner_inner img{width: 100%;}
.line_banner .banner_inner .close_box{position: absolute; right: 0; top: 6px; text-align: right;}
.line_banner .banner_inner .close_box > button.close_banner{width: 30px; height: 30px; background: url(/_next/static/media/icon-popup-close2.95762238.svg) no-repeat; background-size: 30px;}
.line_banner .banner_inner .close_box .check_box_today {width: auto; position: relative; display: block; cursor: pointer; margin-top: 31px;}
.line_banner .banner_inner .close_box .check_box_today input[type="checkbox"] + label {display: inline-block; background: url(/_next/static/media/button-checkbox_p-check-off@2x.028111fc.png) no-repeat; background-size: 15px auto; background-position:left 1px; padding: 0 0 0 21px; font-size: 14px; line-height: 17px; color: #000; height: 17px;}
.line_banner .banner_inner .close_box .check_box_today input[type="checkbox"]:checked + label{background: url(/_next/static/media/button-checkbox_p-check-on@2x.54cad4a0.png) no-repeat; background-size: 15px auto; background-position:left 1px; font-size: 14px; line-height: 17px; color: #000;}

@font-face {
  font-family: 'AppleSDGothicNeo';
  font-style: normal;
  font-weight: 100;
  src: local('AppleSDGothicNeo Thin'), 
  url(/_next/static/media/AppleSDGothicNeoT.a249a535.eot) format('embedded-opentype'),
  url(/_next/static/media/AppleSDGothicNeoT.58fabc11.woff) format('woff'),
  url(/_next/static/media/AppleSDGothicNeoT.96c89be7.woff2) format('woff2'),
  url(/_next/static/media/AppleSDGothicNeoT.6f7bb628.ttf) format('truetype');
}
@font-face {
  font-family: 'AppleSDGothicNeo';
  font-style: normal;
  font-weight: 200;
  src: local('AppleSDGothicNeo UltraLihht'),
  url(/_next/static/media/AppleSDGothicNeoUL.1508ca6f.eot) format('embedded-opentype'),
  url(/_next/static/media/AppleSDGothicNeoUL.817336f1.woff) format('woff'),
  url(/_next/static/media/AppleSDGothicNeoUL.d968944c.woff2) format('woff2'),
  url(/_next/static/media/AppleSDGothicNeoUL.3997f657.ttf) format('truetype');
}
@font-face {
  font-family: 'AppleSDGothicNeo';
  font-style: normal;
  font-weight: 300;
  src: local('AppleSDGothicNeo Light'),
  url(/_next/static/media/AppleSDGothicNeoL.6e44cb49.eot) format('embedded-opentype'),
  url(/_next/static/media/AppleSDGothicNeoL.b090ffc2.woff) format('woff'),
  url(/_next/static/media/AppleSDGothicNeoL.5aebe91c.woff2) format('woff2'),
  url(/_next/static/media/AppleSDGothicNeoL.4b47aecf.ttf) format('truetype');
}
@font-face {
  font-family: 'AppleSDGothicNeo';
  font-style: normal;
  font-weight: 400;
  src: local('AppleSDGothicNeo Medium'),  
  url(/_next/static/media/AppleSDGothicNeoM.9a00f981.eot) format('embedded-opentype'),
  url(/_next/static/media/AppleSDGothicNeoM.1c451352.woff) format('woff'),
  url(/_next/static/media/AppleSDGothicNeoM.e10945c5.woff2) format('woff2'),
  url(/_next/static/media/AppleSDGothicNeoM.763f8c37.ttf) format('truetype');
}
@font-face {
  font-family: 'AppleSDGothicNeo';
  font-style: normal;
  font-weight: 500;
  src: local('AppleSDGothicNeo Regular'),  
  url(/_next/static/media/AppleSDGothicNeoR.2d17a7e5.eot) format('embedded-opentype'),
  url(/_next/static/media/AppleSDGothicNeoR.6b29b080.woff) format('woff'),
  url(/_next/static/media/AppleSDGothicNeoR.2c90e04e.woff2) format('woff2'),
  url(/_next/static/media/AppleSDGothicNeoR.c2b87f30.ttf) format('truetype');
}
@font-face {
  font-family: 'AppleSDGothicNeo';
  font-style: normal;
  font-weight: 600;
  src: local('AppleSDGothicNeo SemiBold'),
  url(/_next/static/media/AppleSDGothicNeoSB.48fe59ed.eot) format('embedded-opentype'),
  url(/_next/static/media/AppleSDGothicNeoSB.a9a9d989.woff) format('woff'),
  url(/_next/static/media/AppleSDGothicNeoSB.60e567d9.woff2) format('woff2'),
  url(/_next/static/media/AppleSDGothicNeoSB.a415c79f.ttf) format('truetype');
}
@font-face {
  font-family: 'AppleSDGothicNeo';
  font-style: normal;
  font-weight: 700;
  src: local('AppleSDGothicNeo Bold'), 
  url(/_next/static/media/AppleSDGothicNeoB.a229de98.eot) format('embedded-opentype'),
  url(/_next/static/media/AppleSDGothicNeoB.5f993ed9.woff) format('woff'),
  url(/_next/static/media/AppleSDGothicNeoB.2f49c8f4.woff2) format('woff2'),
  url(/_next/static/media/AppleSDGothicNeoB.840d2408.ttf) format('truetype');
}
@font-face {
  font-family: 'AppleSDGothicNeo';
  font-style: normal;
  font-weight: 800;
  src: local('AppleSDGothicNeo ExtraBold'), 
  url(/_next/static/media/AppleSDGothicNeoEB.27c460ad.eot) format('embedded-opentype'),
  url(/_next/static/media/AppleSDGothicNeoEB.b47c85bf.woff) format('woff'),
  url(/_next/static/media/AppleSDGothicNeoEB.f02b8f0f.woff2) format('woff2'),
  url(/_next/static/media/AppleSDGothicNeoEB.aeb4ceda.ttf) format('truetype');
}
@font-face {
  font-family: 'AppleSDGothicNeo';
  font-style: normal;
  font-weight: 900;
  src: local('AppleSDGothicNeo Heavy'),
  url(/_next/static/media/AppleSDGothicNeoH.8ea2a7ce.eot) format('embedded-opentype'),
  url(/_next/static/media/AppleSDGothicNeoH.a4a3f96e.woff) format('woff'),
  url(/_next/static/media/AppleSDGothicNeoH.31b4ca5c.woff2) format('woff2'),
  url(/_next/static/media/AppleSDGothicNeoH.6c7415cf.ttf) format('truetype');
}

@font-face {
  font-family: 'pass';
  font-style: normal;
  font-weight: 400;
  src: url(/_next/static/media/pass.ecc53cbc.woff) format('woff');
}




.Footer_footer__9roTy{width: 100%; height: 315px; background: #fff; border-top: 1px solid #e8e8e8; position: relative;}
.Footer_footer_top__I_NFL{width: 100%; border-bottom: 1px solid #e8e8e8; text-align: center;}
.Footer_footer_top__I_NFL .Footer_f_inner__X38oP{width: 1200px; display: inline-table; height: 57px;}
.Footer_footer_top__I_NFL .Footer_f_inner__X38oP .Footer_trems__6xK5T{width: 50%; display: table-cell;  vertical-align: middle; text-align: left; font-size: 0;}
.Footer_footer_top__I_NFL .Footer_f_inner__X38oP .Footer_trems__6xK5T > a{display: inline-block; font-size: 14px; color: #555; margin-left: 28px;}
.Footer_footer_top__I_NFL .Footer_f_inner__X38oP .Footer_trems__6xK5T > a:first-child{margin-left: 0;}
.Footer_footer_top__I_NFL .Footer_f_inner__X38oP .Footer_trems__6xK5T > a > b{font-weight: 700; color: #000;}
.Footer_footer_top__I_NFL .Footer_f_inner__X38oP .Footer_sns_ar__i3tGE{width: 50%; display: table-cell; vertical-align: middle; text-align: right; font-size: 0;}
.Footer_footer_top__I_NFL .Footer_f_inner__X38oP .Footer_sns_ar__i3tGE > a{display: inline-block; width: 24px; height: 24px; margin-left: 20px; line-height: 24px; vertical-align: middle;}
.Footer_footer_top__I_NFL .Footer_f_inner__X38oP .Footer_sns_ar__i3tGE > a > img{width: 100%;}
.Footer_footer_top__I_NFL .Footer_f_inner__X38oP .Footer_sns_ar__i3tGE > a.Footer_ut__sPWRo{padding-top: 3px;}

.Footer_foot_content__qcP7B{width: 100%;}
.Footer_foot_content__qcP7B .Footer_f_inner__X38oP{width: 1200px; margin: 0 auto; font-size: 0; padding-top: 40px;}
.Footer_foot_content__qcP7B .Footer_f_inner__X38oP > h1{display: inline-block; vertical-align: middle; width: 170px; margin-top: 42px;}
.Footer_foot_content__qcP7B .Footer_f_inner__X38oP > h1 > img{width: 100%;}
.Footer_foot_content__qcP7B .Footer_f_inner__X38oP .Footer_comp_info__aRjll{display: inline-block; vertical-align: top; margin-left: 43px;}
.Footer_foot_content__qcP7B .Footer_f_inner__X38oP .Footer_comp_info__aRjll > h4{font-size: 16px; height: 19px; font-weight: 700; color: #555;}
.Footer_foot_content__qcP7B .Footer_f_inner__X38oP .Footer_comp_info__aRjll > p{margin-top: 4px; font-size: 13px; line-height: 22px; color: #7a7a7a;}
.Footer_foot_content__qcP7B .Footer_f_inner__X38oP .Footer_comp_info__aRjll > p.Footer_copy__0iB1R{margin-top: 13px;}
.Footer_foot_content__qcP7B .Footer_f_inner__X38oP .Footer_cs_info__Dl4fl{display: inline-block; vertical-align: top; margin-left: 70px; letter-spacing: -0.2px;}
.Footer_foot_content__qcP7B .Footer_f_inner__X38oP .Footer_cs_info__Dl4fl > h4{font-size: 14px; font-weight: 700; height: 17px;}
.Footer_foot_content__qcP7B .Footer_f_inner__X38oP .Footer_cs_info__Dl4fl .Footer_tel__N4Hd1{height: 28px; width: 100%; display: block; margin-top: 1px; font-size: 0;}
.Footer_foot_content__qcP7B .Footer_f_inner__X38oP .Footer_cs_info__Dl4fl .Footer_tel__N4Hd1 > p{display: inline-block; font-size: 24px; font-weight: bold; line-height: 30px; vertical-align: middle; height: 28px;}
.Footer_foot_content__qcP7B .Footer_f_inner__X38oP .Footer_cs_info__Dl4fl .Footer_tel__N4Hd1 > button{width: 96px; height: 28px; border: 1px solid #e8e8e8; vertical-align: middle;  margin-left: 11px;}
.Footer_foot_content__qcP7B .Footer_f_inner__X38oP .Footer_cs_info__Dl4fl .Footer_tel__N4Hd1 > button > span{display: inline-block; padding-right: 17px; font-size: 13px; line-height: 14px; background: url(/_next/static/media/icon_more.379bec9c.svg) right center no-repeat;}
.Footer_foot_content__qcP7B .Footer_f_inner__X38oP .Footer_cs_info__Dl4fl .Footer_info__bFgIS{margin-top: 8px; font-size: 0;}
.Footer_foot_content__qcP7B .Footer_f_inner__X38oP .Footer_cs_info__Dl4fl .Footer_info__bFgIS .Footer_time__q3pKl{display: inline-block; font-size: 13px; color: #7a7a7a; height: 16px; vertical-align: middle;}
.Footer_foot_content__qcP7B .Footer_f_inner__X38oP .Footer_cs_info__Dl4fl .Footer_info__bFgIS .Footer_fax__mIcC5{display: inline-block; font-size: 13px; color: #000; height: 16px; vertical-align: middle; margin-left: 12px;}
.Footer_foot_content__qcP7B .Footer_f_inner__X38oP .Footer_cs_info__Dl4fl .Footer_info__bFgIS .Footer_mail__nY8Ud{display: block; font-size: 13px; color: #000; height: 16px; margin-top: 8px;}
.Footer_foot_content__qcP7B .Footer_f_inner__X38oP .Footer_cs_info__Dl4fl .Footer_info__bFgIS .Footer_mail__nY8Ud > em{font-size: 13px; color: #000; margin-left: 8px;}
.Footer_foot_content__qcP7B .Footer_f_inner__X38oP .Footer_cs_info__Dl4fl .Footer_info__bFgIS .Footer_mail__nY8Ud > em:first-child {margin-left: 0; font-weight: 700;}
.Footer_foot_content__qcP7B .Footer_f_inner__X38oP .Footer_cs_info__Dl4fl .Footer_info__bFgIS .Footer_mail__nY8Ud > em > i{padding: 0.2rem; color: inherit; font-weight: inherit;}

.Footer_quick_link__G0oYC{width: auto; margin-top: 15px;}
.Footer_quick_link__G0oYC .Footer_quick_link_ar__Kwwc_{display:inline-block; vertical-align: middle;}
.Footer_quick_link__G0oYC .Footer_btn_site__QWiJN{height: 35px;width: 232px;display:inline-block;border-bottom:1px solid rgba(0,0,0,0.20);line-height: 35px; font-size: 16px;letter-spacing: -0.05em;/* padding-left: 15px; */ position: relative; overflow: hidden;}
.Footer_quick_link__G0oYC .Footer_btn_site__QWiJN::before {content: ""; position: absolute; width: 13px; height: 13px; top: 11px; left: auto; right: 0; z-index: 1; background: url(/_next/static/media/icon-open.f7a4f05c.svg) center center no-repeat; transition: all ease 0.1s;}
.Footer_quick_link__G0oYC .Footer_btn_site__QWiJN.Footer_on__49X1u::before {content: ""; position: absolute;  width: 13px;  height: 13px;  top: 11px;  left: auto;  right: 0;  z-index: 1;  background: url(/_next/static/media/icon-open.f7a4f05c.svg) center center no-repeat;  transform: rotate(-45deg);}
.Footer_quick_link__G0oYC .Footer_link_box__7dMCb{width: 232px;box-sizing: border-box;position: absolute;background: #fff;border: 1px solid #e8e8e8;padding: 25px;font-size: 14px;letter-spacing: -0.05em;/* margin-top: -180px; */display: none; bottom: 108px;}
.Footer_quick_link__G0oYC .Footer_link_box__7dMCb > a{display: block; margin-top: 14px;  color: #000;}
.Footer_quick_link__G0oYC .Footer_link_box__7dMCb > a:first-child{margin-top: 0;}

.Footer_quick_link__G0oYC .Footer_btn_site2__HYI_l{height: 35px;width: 232px;display:inline-block;border-bottom:1px solid rgba(0,0,0,0.20);line-height: 35px;font-size: 16px;letter-spacing: -0.05em;margin-top: 0;margin-left: 24px;/* padding-left: 15px; */ position: relative;}
.Footer_quick_link__G0oYC .Footer_btn_site2__HYI_l::before {content: ""; position: absolute; width: 13px; height: 13px; top: 11px; left: auto; right: 0; z-index: 1; background: url(/_next/static/media/icon-open.f7a4f05c.svg) center center no-repeat; transition: all ease 0.1s;}
.Footer_quick_link__G0oYC .Footer_btn_site2__HYI_l.Footer_on__49X1u::before {content: ""; position: absolute; width: 13px; height: 13px; top: 11px; left: auto; right: 0; z-index: 1; background: url(/_next/static/media/icon-open.f7a4f05c.svg) center center no-repeat; transform: rotate(-45deg);}
.Footer_quick_link__G0oYC .Footer_link_box2__qNnpj{width: 232px;box-sizing: border-box; position: absolute; background: #fff;border: 1px solid #e8e8e8;padding: 25px;font-size: 14px;letter-spacing: -0.05em; bottom: 108px; display: none; margin-left: 24px;}
.Footer_quick_link__G0oYC .Footer_link_box2__qNnpj > a{display: block; margin-top: 14px;  color: #000;}
.Footer_quick_link__G0oYC .Footer_link_box2__qNnpj > a:first-child{margin-top: 0;}
.LeftBanner_banner_box_w__isVsf{display:inline-block; width: 800px; vertical-align: top;}
.LeftBanner_banner_box_w__isVsf .LeftBanner_swiper__GJxav { margin-left: auto; margin-right: auto; position: relative; overflow: hidden; list-style: none; padding: 0; z-index: 1; }
.LeftBanner_banner_box_w__isVsf .LeftBanner_mainSwiper_w__Bg_nT{padding-bottom: 48px;}
.LeftBanner_banner_box_w__isVsf .LeftBanner_mainSwiper_w__Bg_nT.LeftBanner_wide__pbF8S .LeftBanner_swiper_slide__XzYUV{width: 800px !important; }
.LeftBanner_banner_box_w__isVsf .LeftBanner_swiper_slide__XzYUV{width: 390px; height: 350px; display: inline-block; border-radius: 16px; overflow: hidden;}
.LeftBanner_banner_box_w__isVsf .LeftBanner_swiper_slide__XzYUV > img{width: 100%;}
.LeftBanner_banner_box_w__isVsf .LeftBanner_swiper_btn_wrap__W3ZLb{position: absolute; bottom: 0; width: 100%; height: 20px; z-index: 99; text-align: center; font-size: 13px;} 
.LeftBanner_banner_box_w__isVsf .LeftBanner_swiper_btn__yp5La{position: relative; height: 20px; display: inline-block;}
.LeftBanner_banner_box_w__isVsf .LeftBanner_swiper_btn__yp5La .LeftBanner_prev__Uuwua{background: url(/_next/static/media/icon_prev.2cfe275b.svg) center no-repeat; width: 20px; height: 20px; margin-left: 9px; display: inline-block; position: relative; vertical-align: middle;  }
.LeftBanner_banner_box_w__isVsf .LeftBanner_swiper_btn__yp5La .LeftBanner_next__F0MmP{background: url(/_next/static/media/icon_next.3e75eb5d.svg) center no-repeat; width: 20px; height: 20px; margin-right: 9px; display: inline-block; position: relative; vertical-align: middle;}
.LeftBanner_banner_box_w__isVsf .LeftBanner_swiper_btn_wrap__W3ZLb .LeftBanner_start_stop__VpwzI{width:20px; height:20px; display: inline-block; margin-left: 10px; vertical-align: middle;}
.LeftBanner_banner_box_w__isVsf .LeftBanner_swiper_btn_wrap__W3ZLb .LeftBanner_start_stop__VpwzI > button{width:20px; height:20px; vertical-align: middle; border: 0; padding: 0;}
.LeftBanner_banner_box_w__isVsf .LeftBanner_swiper_btn_wrap__W3ZLb .LeftBanner_start_stop__VpwzI > button.LeftBanner_start__OZisE{background: url(/_next/static/media/icon_play.5c200d11.svg) center no-repeat;}
.LeftBanner_banner_box_w__isVsf .LeftBanner_swiper_btn_wrap__W3ZLb .LeftBanner_start_stop__VpwzI > button.LeftBanner_stop__EBwnW{background: url(/_next/static/media/icon_stop.b568c033.svg) center no-repeat;}
.LeftBanner_banner_box_w__isVsf .LeftBanner_swiper_btn_wrap__W3ZLb .LeftBanner_start_stop__VpwzI > button.LeftBanner_on__IsdFI {display: block;}
.LeftBanner_banner_box_w__isVsf .LeftBanner_swiper_btn_wrap__W3ZLb .LeftBanner_start_stop__VpwzI > button.LeftBanner_off__khVXX {display: none;}
.LeftBanner_banner_box_w__isVsf .LeftBanner_swiper-pagination__7PHcl { position: absolute; text-align: center; transition: .3s opacity; transform: translate3d(0,0,0); z-index: 10; }
.LeftBanner_banner_box_w__isVsf .LeftBanner_swiper_pagination_fraction__goZhM{bottom: auto; font-weight: 500; font-size: 13px; color: #999999; width: auto; display: inline-block; position: relative; vertical-align: middle;}
.LeftBanner_banner_box_w__isVsf .LeftBanner_swiper_pagination_current__Athnm{ color: #000;  font-weight: 500; display: inline-block; min-width: 8px;}
.LeftBanner_banner_box_w__isVsf .LeftBanner_swiper_pagination_total__7BRIV{color: #999;  font-weight: 500;}
.CustomArrow_swiper_btn_wrap__hexkL{position: absolute; bottom: 0; width: 100%; height: 20px; z-index: 99; text-align: center; font-size: 13px;} 
.CustomArrow_swiper_btn__0cudQ{position: relative; height: 20px; display: inline-block;}
.CustomArrow_swiper_btn__0cudQ .CustomArrow_prev__hlCWC{background: url(/_next/static/media/icon_prev.2cfe275b.svg) center no-repeat; width: 20px; height: 20px; margin-left: 9px; display: inline-block; position: relative; vertical-align: middle; }
.CustomArrow_swiper_btn__0cudQ .CustomArrow_next__tn3gm{background: url(/_next/static/media/icon_next.3e75eb5d.svg) center no-repeat; width: 20px; height: 20px; margin-right: 9px; display: inline-block; position: relative; vertical-align: middle;}
.CustomArrow_swiper_pagination_bullets__6HDQN.CustomArrow_swiper_pagination_horizontal__NlzTk{bottom: auto; font-weight: 500; font-size: 13px; color: #999999; width: auto; display: inline-block; position: relative; vertical-align: top;}
.CustomArrow_swiper_pagination_bullet__F6z_P{width: 6px; height: 6px; background: #e5e5e5; margin: 0 4px; opacity: 1; display: inline-block; border-radius: 50%;}
.CustomArrow_swiper_pagination_bullet_active__XisF3{background: #000;}
.RightBanner_banner_box_s___JcDU{display:inline-block; width: 380px; vertical-align: top; margin-left: 20px; overflow: hidden;}
.RightBanner_banner_box_s___JcDU .RightBanner_swiper__KgZD4 { margin-left: auto; margin-right: auto; position: relative; overflow: hidden; list-style: none; padding: 0; z-index: 1; }
.RightBanner_banner_box_s___JcDU .RightBanner_mainSwiper_s__SWYS_{padding-bottom: 48px;}
.RightBanner_banner_box_s___JcDU .RightBanner_swiper_slide__ZwIWc{width: 380px; height: 350px; display: inline-block; border-radius: 16px; overflow: hidden;}
.RightBanner_banner_box_s___JcDU .RightBanner_swiper_slide__ZwIWc .RightBanner_banner_box__yexSr{width: 100%; position: relative; height: 350px;}
.RightBanner_banner_box_s___JcDU .RightBanner_swiper_slide__ZwIWc .RightBanner_banner_box__yexSr > img{width: 100%;}
.RightBanner_banner_box_s___JcDU .RightBanner_swiper_slide__ZwIWc .RightBanner_banner_box__yexSr > h5{font-size: 24px; font-weight: 700; line-height: 28px; text-align: center; width: 100%; position: absolute; bottom: 0; left: 0;}
.Banner_banner_area__DhUcQ{width: 100%; padding-top: 70px;}
.Main_main_content__SSCvD{width: 100%;}
.Main_main_content__SSCvD > section .Main_inner__Qi36k{width: 1200px; margin: 0 auto; font-size: 0;}
.HeaderGnb_gnb__Sr2iD{width: 100%; margin-top: 22px;}
.HeaderGnb_gnb__Sr2iD:after {display:block; content:"";clear:both;}
.HeaderGnb_gnb__Sr2iD .HeaderGnb_gnb_wrap__BCI2e{width:100%; padding-top: 16px;}
.HeaderGnb_gnb__Sr2iD .HeaderGnb_gnb_wrap__BCI2e:after {display:block; content:"";clear:both;}
.HeaderGnb_gnb__Sr2iD .HeaderGnb_gnb_wrap__BCI2e>ul{width:100%; text-align: right; font-size: 0;}
.HeaderGnb_gnb__Sr2iD .HeaderGnb_gnb_wrap__BCI2e>ul:after {display:block; content:"";clear:both;}
.HeaderGnb_gnb__Sr2iD .HeaderGnb_gnb_wrap__BCI2e>ul>li{text-align:left; margin-left: 48px; font-size: 20px; display: inline-block;}
.HeaderGnb_gnb__Sr2iD .HeaderGnb_gnb_wrap__BCI2e>ul>li:first-child{margin-left: 0;}
.HeaderGnb_gnb__Sr2iD .HeaderGnb_gnb_wrap__BCI2e>ul>li a.HeaderGnb_dep01__rdpEa{color:#000; font-size:20px; font-weight:500; }
.HeaderGnb_gnb__Sr2iD .HeaderGnb_gnb_wrap__BCI2e>ul>li.HeaderGnb_slc__JchBw a.HeaderGnb_dep01__rdpEa{color:#febd17;}
.HeaderGnb_gnb__Sr2iD .HeaderGnb_gnb_wrap__BCI2e>ul>li.HeaderGnb_ov__QHoKZ a.HeaderGnb_dep01__rdpEa{color:#000;}
.HeaderGnb_gnb__Sr2iD .HeaderGnb_gnb_wrap__BCI2e>ul>li.HeaderGnb_on__WS5x_ a.HeaderGnb_dep01__rdpEa{color:#f93964;}
.HeaderGnb_gnb__Sr2iD .HeaderGnb_gnb_wrap__BCI2e>ul>li.HeaderGnb_active__f433A a.HeaderGnb_dep01__rdpEa{color:#f93964;}
.HeaderGnb_gnb__Sr2iD .HeaderGnb_gnb_wrap__BCI2e>ul>li a > i{padding: 0 3px; color: inherit;}
.HeaderGnb_gnb__Sr2iD .HeaderGnb_gnb_wrap__BCI2e>ul>li div.HeaderGnb_dep02__rraaN{background:#fff;width:100vw; border-top: 1px solid #e5e5e5; position: absolute; left: 0; margin-top: 25px;  z-index: 101; color:#fff; line-height:1.5; text-align:left;display:none; box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);} /*20230222 수정 */
.HeaderGnb_gnb__Sr2iD .HeaderGnb_gnb_wrap__BCI2e>ul>li div.HeaderGnb_dep02__rraaN .HeaderGnb_sub_nav__5p145{width:1200px;margin:0 auto; box-sizing: border-box; height: 327px;}
.HeaderGnb_gnb__Sr2iD .HeaderGnb_gnb_wrap__BCI2e>ul>li div.HeaderGnb_dep02__rraaN .HeaderGnb_sub_nav__5p145 .HeaderGnb_sub_inner__yE5XD{padding: 60px 0 15px;}
.HeaderPrivate_top_menu__ZDmnC{width: 100%; font-size: 0; position: relative;}
.HeaderPrivate_top_menu__ZDmnC .HeaderPrivate_my_cash__m2_Qf{display: inline-block; height: 36px; padding: 6px; background: #f8f8f8; border-radius: 18px; vertical-align: middle;}
.HeaderPrivate_top_menu__ZDmnC .HeaderPrivate_my_cash__m2_Qf > h5{height: 24px; display: inline-block; padding: 3px; width: 52px; vertical-align: top;}
.HeaderPrivate_top_menu__ZDmnC .HeaderPrivate_my_cash__m2_Qf > h5 > i{display: inline-block; vertical-align: top; height: 18px; width: 100%; background: #000;  color: #fff; font-size: 11px; line-height: 19px; text-align: center; border-radius: 9px;}
.HeaderPrivate_top_menu__ZDmnC .HeaderPrivate_my_cash__m2_Qf > span{display: inline-block; vertical-align: top; margin-left: 5px; font-size: 14px; color: #000; line-height: 24px;}
.HeaderPrivate_top_menu__ZDmnC .HeaderPrivate_my_cash__m2_Qf .HeaderPrivate_lock_toggle__qjmL3{display: inline-block; vertical-align: top; margin-left: 24px; width: 78px; height: 24px; position: relative;}
.HeaderPrivate_top_menu__ZDmnC > a{line-height: 36px; display: inline-block; vertical-align: middle; margin-left: 24px; font-size: 14px; color: #666;}
.HeaderPrivate_top_menu__ZDmnC > a.HeaderPrivate_corp_mall__GhlX_{color: #000;}

.HeaderPrivate_mypage_link__P6ZPm{width: 130px; border: 1px solid #e5e5e5; padding: 20px 0; position:absolute; background: #fff;  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.1); right: 65px; border-radius: 8px; display: none; z-index: 9990;}
.HeaderPrivate_mypage_link__P6ZPm > ul{width: 100%;}
.HeaderPrivate_mypage_link__P6ZPm > ul > li{width: 100%; padding: 0 24px; margin-top: 12px; text-align: left;}
.HeaderPrivate_mypage_link__P6ZPm > ul > li:first-child{margin-top: 0;}
.HeaderPrivate_mypage_link__P6ZPm > ul > li > a{font-size: 14px; line-height: 17px;}

.HeaderPrivate_checkbox_switch__7OWWJ {cursor: pointer; display: inline-block; overflow: hidden; position: relative; text-align: left; width: 100%; height: 24px; border-radius: 12px; font-size: 14px; vertical-align: top;}
.HeaderPrivate_checkbox_switch__7OWWJ input.HeaderPrivate_input_checkbox__9fZmV {position: absolute; left: 0; top: 0; width: 100%; height: 24px; padding: 0; margin: 0; opacity: 0; z-index: 2; cursor: pointer;}
.HeaderPrivate_checkbox_switch__7OWWJ .HeaderPrivate_checkbox_animate__kQje_ {position: relative; width: 100%; height: 24px; background-color: #95a5a6; transition: background 0.25s ease-out 0s;}
.HeaderPrivate_checkbox_switch__7OWWJ .HeaderPrivate_checkbox_animate__kQje_:before {content: ""; display: block; position: absolute; width: 20px; height: 20px; border-radius: 10px; -webkit-border-radius: 10px; background-color: #fff; top: 2px; left: 2px; transition: left 0.3s ease-out 0s; z-index: 10;}
.HeaderPrivate_checkbox_switch__7OWWJ input.HeaderPrivate_input_checkbox__9fZmV:checked + .HeaderPrivate_checkbox_animate__kQje_ {background: linear-gradient(90deg, rgba(255,168,87,1) 0%, rgba(233,85,118,1) 100%)#2ecc71;}
.HeaderPrivate_checkbox_switch__7OWWJ input.HeaderPrivate_input_checkbox__9fZmV:checked + .HeaderPrivate_checkbox_animate__kQje_:before {left: auto; right: 2px;background: #fff;}
.HeaderPrivate_checkbox_switch__7OWWJ .HeaderPrivate_checkbox_off__gI9_1, .HeaderPrivate_checkbox_switch__7OWWJ .HeaderPrivate_checkbox_on__nENeI { float: left; color: #fff; font-weight: 500; padding-top: 5px; transition: all 0.3s ease-out 0s; font-size: 12px; width: 100%;}
.HeaderPrivate_checkbox_switch__7OWWJ .HeaderPrivate_checkbox_off__gI9_1 {padding-left: 22px; padding-right: 4px; display: block; opacity: 1; text-align: center;}
.HeaderPrivate_checkbox_switch__7OWWJ .HeaderPrivate_checkbox_on__nENeI {display: none; padding-left: 4px; padding-right: 22px; text-align: center; opacity: 0;}
.HeaderPrivate_checkbox_switch__7OWWJ input.HeaderPrivate_input_checkbox__9fZmV:checked + .HeaderPrivate_checkbox_animate__kQje_ .HeaderPrivate_checkbox_off__gI9_1 {display: none; opacity: 0;}
.HeaderPrivate_checkbox_switch__7OWWJ input.HeaderPrivate_input_checkbox__9fZmV:checked + .HeaderPrivate_checkbox_animate__kQje_ .HeaderPrivate_checkbox_on__nENeI {display: block; opacity: 1;}
.HeaderView_header__KsCwG{width: 100%; position: fixed; background: #fff; z-index: 100; border-bottom: 1px solid #e5e5e5; box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);}
.HeaderView_header__KsCwG .HeaderView_header_top__k1PrA {width: 100%; height: 140px; background: #fff;}
.HeaderView_header__KsCwG .HeaderView_header_top__k1PrA .HeaderView_h_inner__AfTEd{width: 1200px; margin: 0 auto; font-size: 0; height: auto; padding: 12px 0 0; }
.HeaderView_header__KsCwG .HeaderView_header_top__k1PrA .HeaderView_h_inner__AfTEd > h1{display: inline-block; width: 386px; vertical-align: bottom; padding-bottom: 31px; padding-top: 48px; position: absolute;} /* 20230222 수정 */
.HeaderView_header__KsCwG .HeaderView_header_top__k1PrA .HeaderView_h_inner__AfTEd > h1 > a{display: inline-block; width: 189px;}
.HeaderView_header__KsCwG .HeaderView_header_top__k1PrA .HeaderView_h_inner__AfTEd > h1 > a > img{width: 100%;}
.HeaderView_header__KsCwG .HeaderView_header_top__k1PrA .HeaderView_h_inner__AfTEd .HeaderView_right_ar__XM0M0{ display: inline-block; width: 100%; padding-left: 386px; text-align: right; vertical-align: bottom;}

.HeaderView_in_banner__I0rvF {width: 100%; background: linear-gradient(90deg, rgba(233,85,118,1) 0%, rgba(255,168,87,1) 100%)#2ecc71; height: 50px; display: none;}
.HeaderView_in_banner__I0rvF.HeaderView_on__9A0MG{display: block;}
.HeaderView_in_banner__I0rvF .HeaderView_text_inner__w1VvJ{width: 1200px; margin: 0 auto; font-size: 0; padding: 13px 0;}
.HeaderView_in_banner__I0rvF .HeaderView_text_inner__w1VvJ > i{display: inline-block; width: 40px; height: 24px; border: 1px solid #fff; border-radius: 4px; font-size: 12px; font-weight: 500; line-height: 24px; text-align: center; vertical-align: middle; color: #fff;} 
.HeaderView_in_banner__I0rvF .HeaderView_text_inner__w1VvJ > span{display: inline-block; margin-left: 12px; vertical-align: middle; font-size: 16px; font-weight: 700; color: #fff; padding-top: 4px; line-height: 1; }

.HeaderView_line_banner__SkzrX{width: 100%; display: none;}
.HeaderView_line_banner__SkzrX.HeaderView_active__JV86r{display: block;}
.HeaderView_line_banner__SkzrX .HeaderView_banner_inner__cbBkF{width: 1200px; margin: 0 auto; text-align: center; position: relative; overflow: hidden;}
.HeaderView_line_banner__SkzrX .HeaderView_banner_inner__cbBkF img{width: 100%;}
.HeaderView_line_banner__SkzrX .HeaderView_banner_inner__cbBkF .HeaderView_close_box__oyNVK{position: absolute; right: 0; top: 6px; text-align: right;}
.HeaderView_line_banner__SkzrX .HeaderView_banner_inner__cbBkF .HeaderView_close_box__oyNVK > button.HeaderView_close_banner__EExaO{width: 30px; height: 30px; background: url(/_next/static/media/icon-popup-close2.95762238.svg) no-repeat; background-size: 30px;}
.HeaderView_line_banner__SkzrX .HeaderView_banner_inner__cbBkF .HeaderView_close_box__oyNVK .HeaderView_check_box_today__HtXUM {width: auto; position: relative; display: block; cursor: pointer; margin-top: 31px;}
.HeaderView_line_banner__SkzrX .HeaderView_banner_inner__cbBkF .HeaderView_close_box__oyNVK .HeaderView_check_box_today__HtXUM input[type="checkbox"] + label {display: inline-block; background: url(/_next/static/media/button-checkbox_p-check-off@2x.028111fc.png) no-repeat; background-size: 15px auto; background-position:left 1px; padding: 0 0 0 21px; font-size: 14px; line-height: 17px; color: #000; height: 17px;}
.HeaderView_line_banner__SkzrX .HeaderView_banner_inner__cbBkF .HeaderView_close_box__oyNVK .HeaderView_check_box_today__HtXUM input[type="checkbox"]:checked + label{background: url(/_next/static/media/button-checkbox_p-check-on@2x.54cad4a0.png) no-repeat; background-size: 15px auto; background-position:left 1px; font-size: 14px; line-height: 17px; color: #000;}

/* 레이아웃 */
.Layout_wrapper__PwD36{width: 100%;}
.Layout_wrapper__PwD36.Layout_off__oLzT6{height: 100vh; overflow: hidden;}
.Layout_container__S4aNf {position: relative; width:100%; min-height: calc(100vh - 315px); margin: 0 auto; background-color: #fff; padding-top: 140px;}
.Layout_inbanner__5UCiB{padding-top: 190px;}
.Layout_linebanner__s1J_L{padding-top: 240px;}
.Layout_allbanner__L0zMO{padding-top: 290px !important;}
.Layout_sub___MFK5{background: #f8f8f8;}
.Layout_w_inner__KnEhp{width: 1200px; margin: 0 auto; text-align: center;}


/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list
{
    background: #fff url(/_next/static/media/ajax-loader.0b80f665.gif) center center no-repeat;
}

/* Icons */
@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    src: url(/_next/static/media/slick.25572f22.eot);
    src: url(/_next/static/media/slick.25572f22.eot?#iefix) format('embedded-opentype'), url(/_next/static/media/slick.653a4cbb.woff) format('woff'), url(/_next/static/media/slick.6aa1ee46.ttf) format('truetype'), url(/_next/static/media/slick.f895cfdf.svg#slick) format('svg');
}
/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -25px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '←';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: -25px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '→';
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}

.Loading_Loader__tUKJa{width: 100%; height: 100vh; background: rgba(255, 255, 255, 0.6); position: fixed; z-index: 10000000; display: table;}
.Loading_Loader__tUKJa .Loading_Loader_inner__ZByA9{display: table-cell; width: 100%; vertical-align: middle; text-align: center; padding-bottom: 30px;}

.Loading_leap_frog__oIlPk {
  --uib-size: 40px;
  --uib-speed: 2.5s;
  --uib-color: black;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: var(--uib-size);
  height: var(--uib-size);
}

.Loading_leap_frog__dot__TBnQo {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.Loading_leap_frog__dot__TBnQo::before {
  content: '';
  display: block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #f93964;
  will-change: transform;
}

.Loading_leap_frog__dot__TBnQo:nth-child(1) {
  animation: Loading_leapFrog__DDiip var(--uib-speed) ease infinite;
}

.Loading_leap_frog__dot__TBnQo:nth-child(2) {
  transform: translateX(calc(var(--uib-size) * 0.4));
  animation: Loading_leapFrog__DDiip var(--uib-speed) ease calc(var(--uib-speed) / -1.5)
    infinite;
}

.Loading_leap_frog__dot__TBnQo:nth-child(3) {
  transform: translateX(calc(var(--uib-size) * 0.8)) rotate(0deg);
  animation: Loading_leapFrog__DDiip var(--uib-speed) ease calc(var(--uib-speed) / -3)
    infinite;
}

@keyframes Loading_leapFrog__DDiip {
  0% {
    transform: translateX(0) rotate(0deg);
  }

  33.333% {
    transform: translateX(0) rotate(180deg);
  }

  66.666% {
    transform: translateX(calc(var(--uib-size) * -0.4)) rotate(180deg);
  }

  99.999% {
    transform: translateX(calc(var(--uib-size) * -0.8)) rotate(180deg);
  }

  100% {
    transform: translateX(0) rotate(0deg);
  }
}
