.header{width: 100%; position: fixed; background: #fff; z-index: 100; border-bottom: 1px solid #e5e5e5; box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);}
.header .header_top {width: 100%; height: 140px; background: #fff;}
.header .header_top .h_inner{width: 1200px; margin: 0 auto; font-size: 0; height: auto; padding: 12px 0 0; }
.header .header_top .h_inner > h1{display: inline-block; width: 386px; vertical-align: bottom; padding-bottom: 31px; padding-top: 48px; position: absolute;} /* 20230222 수정 */
.header .header_top .h_inner > h1 > a{display: inline-block; width: 189px;}
.header .header_top .h_inner > h1 > a > img{width: 100%;}
.header .header_top .h_inner .right_ar{ display: inline-block; width: 100%; padding-left: 386px; text-align: right; vertical-align: bottom;}

.in_banner {width: 100%; background: linear-gradient(90deg, rgba(233,85,118,1) 0%, rgba(255,168,87,1) 100%)#2ecc71; height: 50px; display: none;}
.in_banner.on{display: block;}
.in_banner .text_inner{width: 1200px; margin: 0 auto; font-size: 0; padding: 13px 0;}
.in_banner .text_inner > i{display: inline-block; width: 40px; height: 24px; border: 1px solid #fff; border-radius: 4px; font-size: 12px; font-weight: 500; line-height: 24px; text-align: center; vertical-align: middle; color: #fff;} 
.in_banner .text_inner > span{display: inline-block; margin-left: 12px; vertical-align: middle; font-size: 16px; font-weight: 700; color: #fff; padding-top: 4px; line-height: 1; }

.line_banner{width: 100%; display: none;}
.line_banner.active{display: block;}
.line_banner .banner_inner{width: 1200px; margin: 0 auto; text-align: center; position: relative; overflow: hidden;}
.line_banner .banner_inner img{width: 100%;}
.line_banner .banner_inner .close_box{position: absolute; right: 0; top: 6px; text-align: right;}
.line_banner .banner_inner .close_box > button.close_banner{width: 30px; height: 30px; background: url(../../../../public/assets/images//icon-popup-close2.svg) no-repeat; background-size: 30px;}
.line_banner .banner_inner .close_box .check_box_today {width: auto; position: relative; display: block; cursor: pointer; margin-top: 31px;}
.line_banner .banner_inner .close_box .check_box_today input[type="checkbox"] + label {display: inline-block; background: url("../../../../public/assets/images//button-checkbox_p-check-off@2x.png") no-repeat; background-size: 15px auto; background-position:left 1px; padding: 0 0 0 21px; font-size: 14px; line-height: 17px; color: #000; height: 17px;}
.line_banner .banner_inner .close_box .check_box_today input[type="checkbox"]:checked + label{background: url("../../../../public/assets/images//button-checkbox_p-check-on@2x.png") no-repeat; background-size: 15px auto; background-position:left 1px; font-size: 14px; line-height: 17px; color: #000;}