.footer{width: 100%; height: 315px; background: #fff; border-top: 1px solid #e8e8e8; position: relative;}
.footer_top{width: 100%; border-bottom: 1px solid #e8e8e8; text-align: center;}
.footer_top .f_inner{width: 1200px; display: inline-table; height: 57px;}
.footer_top .f_inner .trems{width: 50%; display: table-cell;  vertical-align: middle; text-align: left; font-size: 0;}
.footer_top .f_inner .trems > a{display: inline-block; font-size: 14px; color: #555; margin-left: 28px;}
.footer_top .f_inner .trems > a:first-child{margin-left: 0;}
.footer_top .f_inner .trems > a > b{font-weight: 700; color: #000;}
.footer_top .f_inner .sns_ar{width: 50%; display: table-cell; vertical-align: middle; text-align: right; font-size: 0;}
.footer_top .f_inner .sns_ar > a{display: inline-block; width: 24px; height: 24px; margin-left: 20px; line-height: 24px; vertical-align: middle;}
.footer_top .f_inner .sns_ar > a > img{width: 100%;}
.footer_top .f_inner .sns_ar > a.ut{padding-top: 3px;}

.foot_content{width: 100%;}
.foot_content .f_inner{width: 1200px; margin: 0 auto; font-size: 0; padding-top: 40px;}
.foot_content .f_inner > h1{display: inline-block; vertical-align: middle; width: 170px; margin-top: 42px;}
.foot_content .f_inner > h1 > img{width: 100%;}
.foot_content .f_inner .comp_info{display: inline-block; vertical-align: top; margin-left: 43px;}
.foot_content .f_inner .comp_info > h4{font-size: 16px; height: 19px; font-weight: 700; color: #555;}
.foot_content .f_inner .comp_info > p{margin-top: 4px; font-size: 13px; line-height: 22px; color: #7a7a7a;}
.foot_content .f_inner .comp_info > p.copy{margin-top: 13px;}
.foot_content .f_inner .cs_info{display: inline-block; vertical-align: top; margin-left: 70px; letter-spacing: -0.2px;}
.foot_content .f_inner .cs_info > h4{font-size: 14px; font-weight: 700; height: 17px;}
.foot_content .f_inner .cs_info .tel{height: 28px; width: 100%; display: block; margin-top: 1px; font-size: 0;}
.foot_content .f_inner .cs_info .tel > p{display: inline-block; font-size: 24px; font-weight: bold; line-height: 30px; vertical-align: middle; height: 28px;}
.foot_content .f_inner .cs_info .tel > button{width: 96px; height: 28px; border: 1px solid #e8e8e8; vertical-align: middle;  margin-left: 11px;}
.foot_content .f_inner .cs_info .tel > button > span{display: inline-block; padding-right: 17px; font-size: 13px; line-height: 14px; background: url(../../../../public/assets/images/icon_more.svg) right center no-repeat;}
.foot_content .f_inner .cs_info .info{margin-top: 8px; font-size: 0;}
.foot_content .f_inner .cs_info .info .time{display: inline-block; font-size: 13px; color: #7a7a7a; height: 16px; vertical-align: middle;}
.foot_content .f_inner .cs_info .info .fax{display: inline-block; font-size: 13px; color: #000; height: 16px; vertical-align: middle; margin-left: 12px;}
.foot_content .f_inner .cs_info .info .mail{display: block; font-size: 13px; color: #000; height: 16px; margin-top: 8px;}
.foot_content .f_inner .cs_info .info .mail > em{font-size: 13px; color: #000; margin-left: 8px;}
.foot_content .f_inner .cs_info .info .mail > em:first-child {margin-left: 0; font-weight: 700;}
.foot_content .f_inner .cs_info .info .mail > em > i{padding: 0.2rem; color: inherit; font-weight: inherit;}

.quick_link{width: auto; margin-top: 15px;}
.quick_link .quick_link_ar{display:inline-block; vertical-align: middle;}
.quick_link .btn_site{height: 35px;width: 232px;display:inline-block;border-bottom:1px solid rgba(0,0,0,0.20);line-height: 35px; font-size: 16px;letter-spacing: -0.05em;/* padding-left: 15px; */ position: relative; overflow: hidden;}
.quick_link .btn_site::before {content: ""; position: absolute; width: 13px; height: 13px; top: 11px; left: auto; right: 0; z-index: 1; background: url("../../../../public/assets/images/icon-open.svg") center center no-repeat; transition: all ease 0.1s;}
.quick_link .btn_site.on::before {content: ""; position: absolute;  width: 13px;  height: 13px;  top: 11px;  left: auto;  right: 0;  z-index: 1;  background: url("../../../../public/assets/images/icon-open.svg") center center no-repeat;  transform: rotate(-45deg);}
.quick_link .link_box{width: 232px;box-sizing: border-box;position: absolute;background: #fff;border: 1px solid #e8e8e8;padding: 25px;font-size: 14px;letter-spacing: -0.05em;/* margin-top: -180px; */display: none; bottom: 108px;}
.quick_link .link_box > a{display: block; margin-top: 14px;  color: #000;}
.quick_link .link_box > a:first-child{margin-top: 0;}

.quick_link .btn_site2{height: 35px;width: 232px;display:inline-block;border-bottom:1px solid rgba(0,0,0,0.20);line-height: 35px;font-size: 16px;letter-spacing: -0.05em;margin-top: 0;margin-left: 24px;/* padding-left: 15px; */ position: relative;}
.quick_link .btn_site2::before {content: ""; position: absolute; width: 13px; height: 13px; top: 11px; left: auto; right: 0; z-index: 1; background: url("../../../../public/assets/images/icon-open.svg") center center no-repeat; transition: all ease 0.1s;}
.quick_link .btn_site2.on::before {content: ""; position: absolute; width: 13px; height: 13px; top: 11px; left: auto; right: 0; z-index: 1; background: url("../../../../public/assets/images/icon-open.svg") center center no-repeat; transform: rotate(-45deg);}
.quick_link .link_box2{width: 232px;box-sizing: border-box; position: absolute; background: #fff;border: 1px solid #e8e8e8;padding: 25px;font-size: 14px;letter-spacing: -0.05em; bottom: 108px; display: none; margin-left: 24px;}
.quick_link .link_box2 > a{display: block; margin-top: 14px;  color: #000;}
.quick_link .link_box2 > a:first-child{margin-top: 0;}