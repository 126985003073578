.top_menu{width: 100%; font-size: 0; position: relative;}
.top_menu .my_cash{display: inline-block; height: 36px; padding: 6px; background: #f8f8f8; border-radius: 18px; vertical-align: middle;}
.top_menu .my_cash > h5{height: 24px; display: inline-block; padding: 3px; width: 52px; vertical-align: top;}
.top_menu .my_cash > h5 > i{display: inline-block; vertical-align: top; height: 18px; width: 100%; background: #000;  color: #fff; font-size: 11px; line-height: 19px; text-align: center; border-radius: 9px;}
.top_menu .my_cash > span{display: inline-block; vertical-align: top; margin-left: 5px; font-size: 14px; color: #000; line-height: 24px;}
.top_menu .my_cash .lock_toggle{display: inline-block; vertical-align: top; margin-left: 24px; width: 78px; height: 24px; position: relative;}
.top_menu > a{line-height: 36px; display: inline-block; vertical-align: middle; margin-left: 24px; font-size: 14px; color: #666;}
.top_menu > a.corp_mall{color: #000;}

.mypage_link{width: 130px; border: 1px solid #e5e5e5; padding: 20px 0; position:absolute; background: #fff;  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.1); right: 65px; border-radius: 8px; display: none; z-index: 9990;}
.mypage_link > ul{width: 100%;}
.mypage_link > ul > li{width: 100%; padding: 0 24px; margin-top: 12px; text-align: left;}
.mypage_link > ul > li:first-child{margin-top: 0;}
.mypage_link > ul > li > a{font-size: 14px; line-height: 17px;}

.checkbox_switch {cursor: pointer; display: inline-block; overflow: hidden; position: relative; text-align: left; width: 100%; height: 24px; -webkit-border-radius: 12px; border-radius: 12px; font-size: 14px; vertical-align: top;}
.checkbox_switch input.input_checkbox {position: absolute; left: 0; top: 0; width: 100%; height: 24px; padding: 0; margin: 0; opacity: 0; z-index: 2; cursor: pointer;}
.checkbox_switch .checkbox_animate {position: relative; width: 100%; height: 24px; background-color: #95a5a6; -webkit-transition: background 0.25s ease-out 0s; transition: background 0.25s ease-out 0s;}
.checkbox_switch .checkbox_animate:before {content: ""; display: block; position: absolute; width: 20px; height: 20px; border-radius: 10px; -webkit-border-radius: 10px; background-color: #fff; top: 2px; left: 2px; -webkit-transition: left 0.3s ease-out 0s; transition: left 0.3s ease-out 0s; z-index: 10;}
.checkbox_switch input.input_checkbox:checked + .checkbox_animate {background: linear-gradient(90deg, rgba(255,168,87,1) 0%, rgba(233,85,118,1) 100%)#2ecc71;}
.checkbox_switch input.input_checkbox:checked + .checkbox_animate:before {left: auto; right: 2px;background: #fff;}
.checkbox_switch .checkbox_off, .checkbox_switch .checkbox_on { float: left; color: #fff; font-weight: 500; padding-top: 5px; -webkit-transition: all 0.3s ease-out 0s; transition: all 0.3s ease-out 0s; font-size: 12px; width: 100%;}
.checkbox_switch .checkbox_off {padding-left: 22px; padding-right: 4px; display: block; opacity: 1; text-align: center;}
.checkbox_switch .checkbox_on {display: none; padding-left: 4px; padding-right: 22px; text-align: center; opacity: 0;}
.checkbox_switch input.input_checkbox:checked + .checkbox_animate .checkbox_off {display: none; opacity: 0;}
.checkbox_switch input.input_checkbox:checked + .checkbox_animate .checkbox_on {display: block; opacity: 1;}