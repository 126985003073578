.Loader{width: 100%; height: 100vh; background: rgba(255, 255, 255, 0.6); position: fixed; z-index: 10000000; display: table;}
.Loader .Loader_inner{display: table-cell; width: 100%; vertical-align: middle; text-align: center; padding-bottom: 30px;}

.leap_frog {
  --uib-size: 40px;
  --uib-speed: 2.5s;
  --uib-color: black;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: var(--uib-size);
  height: var(--uib-size);
}

.leap_frog__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.leap_frog__dot::before {
  content: '';
  display: block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #f93964;
  will-change: transform;
}

.leap_frog__dot:nth-child(1) {
  animation: leapFrog var(--uib-speed) ease infinite;
}

.leap_frog__dot:nth-child(2) {
  transform: translateX(calc(var(--uib-size) * 0.4));
  animation: leapFrog var(--uib-speed) ease calc(var(--uib-speed) / -1.5)
    infinite;
}

.leap_frog__dot:nth-child(3) {
  transform: translateX(calc(var(--uib-size) * 0.8)) rotate(0deg);
  animation: leapFrog var(--uib-speed) ease calc(var(--uib-speed) / -3)
    infinite;
}

@keyframes leapFrog {
  0% {
    transform: translateX(0) rotate(0deg);
  }

  33.333% {
    transform: translateX(0) rotate(180deg);
  }

  66.666% {
    transform: translateX(calc(var(--uib-size) * -0.4)) rotate(180deg);
  }

  99.999% {
    transform: translateX(calc(var(--uib-size) * -0.8)) rotate(180deg);
  }

  100% {
    transform: translateX(0) rotate(0deg);
  }
}