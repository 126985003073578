
@font-face {
  font-family: 'AppleSDGothicNeo';
  font-style: normal;
  font-weight: 100;
  src: local('AppleSDGothicNeo Thin'), 
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoT.eot") format('embedded-opentype'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoT.woff") format('woff'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoT.woff2") format('woff2'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoT.ttf") format('truetype');
}
@font-face {
  font-family: 'AppleSDGothicNeo';
  font-style: normal;
  font-weight: 200;
  src: local('AppleSDGothicNeo UltraLihht'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoUL.eot") format('embedded-opentype'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoUL.woff") format('woff'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoUL.woff2") format('woff2'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoUL.ttf") format('truetype');
}
@font-face {
  font-family: 'AppleSDGothicNeo';
  font-style: normal;
  font-weight: 300;
  src: local('AppleSDGothicNeo Light'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoL.eot") format('embedded-opentype'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoL.woff") format('woff'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoL.woff2") format('woff2'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoL.ttf") format('truetype');
}
@font-face {
  font-family: 'AppleSDGothicNeo';
  font-style: normal;
  font-weight: 400;
  src: local('AppleSDGothicNeo Medium'),  
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoM.eot") format('embedded-opentype'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoM.woff") format('woff'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoM.woff2") format('woff2'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoM.ttf") format('truetype');
}
@font-face {
  font-family: 'AppleSDGothicNeo';
  font-style: normal;
  font-weight: 500;
  src: local('AppleSDGothicNeo Regular'),  
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoR.eot") format('embedded-opentype'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoR.woff") format('woff'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoR.woff2") format('woff2'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoR.ttf") format('truetype');
}
@font-face {
  font-family: 'AppleSDGothicNeo';
  font-style: normal;
  font-weight: 600;
  src: local('AppleSDGothicNeo SemiBold'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoSB.eot") format('embedded-opentype'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoSB.woff") format('woff'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoSB.woff2") format('woff2'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoSB.ttf") format('truetype');
}
@font-face {
  font-family: 'AppleSDGothicNeo';
  font-style: normal;
  font-weight: 700;
  src: local('AppleSDGothicNeo Bold'), 
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoB.eot") format('embedded-opentype'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoB.woff") format('woff'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoB.woff2") format('woff2'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoB.ttf") format('truetype');
}
@font-face {
  font-family: 'AppleSDGothicNeo';
  font-style: normal;
  font-weight: 800;
  src: local('AppleSDGothicNeo ExtraBold'), 
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoEB.eot") format('embedded-opentype'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoEB.woff") format('woff'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoEB.woff2") format('woff2'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoEB.ttf") format('truetype');
}
@font-face {
  font-family: 'AppleSDGothicNeo';
  font-style: normal;
  font-weight: 900;
  src: local('AppleSDGothicNeo Heavy'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoH.eot") format('embedded-opentype'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoH.woff") format('woff'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoH.woff2") format('woff2'),
  url("../public/assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoH.ttf") format('truetype');
}

@font-face {
  font-family: 'pass';
  font-style: normal;
  font-weight: 400;
  src: url("../public/assets/fonts/pass.woff") format('woff');
}



